import * as S from "./style";
import { FormEvent, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCheckoutData } from "checkout/context/CheckoutData";
import loopLogo from "assets/img/logos/loop-crypto-long-white.svg";
import neynarLogo from "assets/img/logos/neynar.svg";
import heliusLogo from "assets/img/logos/helius-logo.svg";
import privyLogo from "assets/img/logos/privy.svg";
import Button from "components/Button";
import Lock from "components/icons/Lock";
import Route from "components/icons/Route";
import InputInvoiceId from "checkout/components/SearchByInvoice/InputInvoiceId";
import InputInvoiceAmount from "checkout/components/SearchByInvoice/InputInvoiceAmount";
import Anchor from "components/Anchor";
import { toCents } from "utils/financial";
import Notification, { NotificationType } from "components/Notification";

const SearchByInvoice = () => {
    const { queryParams, formErrorDisplay } = useCheckoutData();
    const [, setQueryParams] = useSearchParams();
    const invoiceIdRef = useRef<InvoiceIdFieldRef>(null);
    const invoiceAmtRef = useRef<InvoiceAmountFieldRef>(null);
    const [canSubmit, setCanSubmit] = useState(false);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            !invoiceIdRef?.current?.validate() ||
            !invoiceAmtRef?.current?.validate()
        )
            return;

        setQueryParams((params) => {
            if (invoiceIdRef?.current?.value())
                params.set(`invoiceId`, invoiceIdRef.current.value());
            if (invoiceAmtRef?.current?.value())
                params.set(
                    `invoiceAmount`,
                    toCents(invoiceAmtRef.current.value()).toString()
                );
            return params;
        });
    };

    const handleInputChange = () => {
        // If the inputs match the search query params, disable the submit button
        setCanSubmit(
            invoiceIdRef?.current?.value() === queryParams.invoiceId &&
                invoiceAmtRef?.current?.value() ===
                    (queryParams.invoiceAmount
                        ? (queryParams.invoiceAmount / 100).toFixed(2)
                        : ``)
                ? false
                : true
        );
    };

    return (
        <S.SearchByInvoice>
            <S.Marketing>
                <header>
                    <h1>
                        <S.Logo src={loopLogo} alt="Loop Crypto logo" />
                    </h1>
                </header>
                <S.Content>
                    <S.Slogan>
                        The easiest way to pay with crypto. Safe, simple and
                        secure.
                    </S.Slogan>
                    <S.Features>
                        <li>
                            <S.Icon as={Lock} />
                            <span>
                                Secured by{" "}
                                <Anchor
                                    href={import.meta.env.VITE_ERC20_STANDARD}
                                    inheritColor={true}
                                >
                                    the ERC-20 standard
                                </Anchor>{" "}
                                and{" "}
                                <Anchor
                                    href={import.meta.env.VITE_SPL_STANDARD}
                                    inheritColor={true}
                                >
                                    the SPL standard.{" "}
                                </Anchor>
                                Trusted by crypto natives.
                            </span>
                        </li>
                        <li>
                            <S.Icon as={Route} />
                            <span>Pay with tokens on different networks.</span>
                        </li>
                    </S.Features>
                </S.Content>

                <S.TrustedBy>
                    <h2>Trusted by</h2>
                    <S.Partners>
                        <li>
                            <img src={neynarLogo} alt="ENS logo" />
                        </li>
                        <li>
                            <img src={heliusLogo} alt="Satsuma logo" />
                        </li>
                        <li>
                            <img src={privyLogo} alt="Privy logo" />
                        </li>
                    </S.Partners>
                </S.TrustedBy>
            </S.Marketing>
            <S.InvoiceSearch>
                <S.Form forwardedAs="form" onSubmit={handleSubmit}>
                    <header>
                        <h1>Pay with Loop</h1>
                        <p>
                            Enter your invoice number and payment amount and we
                            will direct you to your payment.{" "}
                        </p>
                    </header>
                    {formErrorDisplay && (
                        <Notification
                            key={"msg.id"}
                            message={{
                                id: "formErrorDisplay",
                                msg: (
                                    <>
                                        <strong>{formErrorDisplay}</strong>
                                    </>
                                ),
                                type: NotificationType.ERROR,
                                expires: false,
                            }}
                            removeNotification={false}
                        />
                    )}
                    <section>
                        <InputInvoiceId
                            ref={invoiceIdRef}
                            onChange={handleInputChange}
                            defaultValue={queryParams.invoiceId || ``}
                        />
                        <InputInvoiceAmount
                            ref={invoiceAmtRef}
                            onChange={handleInputChange}
                            defaultValue={
                                queryParams.invoiceAmount
                                    ? (queryParams.invoiceAmount / 100).toFixed(
                                          2
                                      )
                                    : ``
                            }
                        />
                    </section>
                    <footer>
                        {/* <S.Help
                            href={import.meta.env.VITE_GITBOOK_FAQ}
                            underlined={false}
                            block
                        >
                            <small>Where do I find the invoice ID?</small>
                        </S.Help> */}
                        <Button type="submit" disabled={!canSubmit} full>
                            Continue
                        </Button>
                    </footer>
                </S.Form>
            </S.InvoiceSearch>
        </S.SearchByInvoice>
    );
};

export default SearchByInvoice;
