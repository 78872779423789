import { HTMLAttributes } from "react";
import { Spacing } from "theme/spacing";
import Anchor from "components/Anchor";
import ErrorMessage from "components/ErrorMessage";
import Section from "components/Section";
import { useCustomer } from "customer/context/Customer";
import { Navigate } from "react-router-dom";
import { useWalletConnected } from "context/Wallet/WalletConnected";

interface HomeProps extends HTMLAttributes<HTMLDivElement> {}

const Home = ({ ...props }: HomeProps) => {
    const { merchantMenuItems, isLoading } = useCustomer();
    const { networkConnected } = useWalletConnected();

    if (isLoading) return <></>;

    if (merchantMenuItems.length !== 0)
        return <Navigate to={merchantMenuItems[0].path} />;

    return (
        <Section spacing={[Spacing.lg]} {...props}>
            <ErrorMessage
                msg={`This wallet has no purchases on ${networkConnected?.label}. Try changing the network or wallet.`}
            >
                Need help?{" "}
                <Anchor href={`mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`}>
                    Email us
                </Anchor>{" "}
                or{" "}
                <Anchor href={import.meta.env.VITE_LOOP_TELEGRAM}>
                    message us on Telegram
                </Anchor>
                .
            </ErrorMessage>
        </Section>
    );
};

export default Home;
