import { useEffect, useRef, useCallback } from "react";

const DEFAULT_EXPIRY_TIME = 600000; // 10 minutes
const DEFAULT_INACTIVE_THRESHOLD = 30000; // 30 seconds
const DEFAULT_CALLBACK = () => window.location.reload();
const events = ["visibilitychange", "mousemove", "keydown"];

export const useTimedRefresh = ({
    expiryTime = DEFAULT_EXPIRY_TIME,
    callback = DEFAULT_CALLBACK,
    inactiveThreshold = DEFAULT_INACTIVE_THRESHOLD,
}: {
    expiryTime?: number;
    callback?: () => void;
    inactiveThreshold?: number;
}) => {
    const lastActivityRef = useRef<number>(Date.now());
    const inactivityCheckRef = useRef<NodeJS.Timeout | null>(null);
    const expiryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const lastVisibleTimeRef = useRef<number>(Date.now());

    // Convert startCheckInactivity and manageEventListeners to a ref to break the dependency cycle
    const startCheckInactivity = useRef<() => void>();
    const manageEventListeners = useRef<(shouldAdd: boolean) => void>();

    const callbackWithNextCycle = useCallback(() => {
        callback();

        // Remove event listeners
        if (manageEventListeners.current) {
            manageEventListeners.current(false);
        }

        // Reset expiry timeout
        if (expiryTimeoutRef.current) {
            clearTimeout(expiryTimeoutRef.current);
        }
        // Reset inactivity check
        if (inactivityCheckRef.current) {
            clearInterval(inactivityCheckRef.current);
        }
        lastActivityRef.current = Date.now();
        lastVisibleTimeRef.current = Date.now();

        // Set up next cycle
        expiryTimeoutRef.current = setTimeout(() => {
            if (startCheckInactivity.current) {
                startCheckInactivity.current();
            }
        }, expiryTime);
    }, [callback, expiryTime]);

    // Create visibility change handler once
    const visibilityHandler = useCallback(() => {
        if (document.visibilityState === "visible") {
            const timeAway = Date.now() - lastVisibleTimeRef.current;

            // If user returns after expiry time, call callback immediately
            if (timeAway >= expiryTime) {
                callbackWithNextCycle();
            }
        }
    }, [expiryTime, callbackWithNextCycle]);

    // Simple activity handler
    const handleActivity = useCallback(() => {
        lastActivityRef.current = Date.now();
    }, []);

    // Initialize the event listener manager function
    useEffect(() => {
        manageEventListeners.current = (shouldAdd: boolean) => {
            const method = shouldAdd
                ? "addEventListener"
                : "removeEventListener";

            events.forEach((event) => {
                document[method](
                    event,
                    event === "visibilitychange"
                        ? visibilityHandler
                        : handleActivity
                );
            });
        };
    }, [handleActivity, visibilityHandler]);

    useEffect(() => {
        //start the inactivity check
        startCheckInactivity.current = () => {
            if (inactivityCheckRef.current) {
                clearInterval(inactivityCheckRef.current);
            }

            // Add event listeners
            if (manageEventListeners.current) {
                manageEventListeners.current(true);
            }

            inactivityCheckRef.current = setInterval(() => {
                const timeSinceLastActivity =
                    Date.now() - lastActivityRef.current;

                if (timeSinceLastActivity >= inactiveThreshold) {
                    callbackWithNextCycle();
                }
            }, inactiveThreshold);
        };
    }, [expiryTime, inactiveThreshold, callbackWithNextCycle]);

    useEffect(() => {
        // Set up the initial expiry timeout
        expiryTimeoutRef.current = setTimeout(() => {
            if (startCheckInactivity.current) {
                startCheckInactivity.current();
            }
        }, expiryTime);

        return () => {
            // Clean up on unmount
            if (expiryTimeoutRef.current) {
                clearTimeout(expiryTimeoutRef.current);
            }
            if (inactivityCheckRef.current) {
                clearInterval(inactivityCheckRef.current);
            }

            // Remove event listeners
            if (manageEventListeners.current) {
                manageEventListeners.current(false);
            }
        };
    }, [expiryTime, manageEventListeners]);
};
