import { useCallback, useEffect, useState, useMemo } from "react";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useWallet } from "context/Wallet";
import { postWalletEmail } from "api";

const useCheckoutEmail = () => {
    const {
        walletConnected,
        networkConnected,
        getWalletEmail,
        setWalletEmail,
    } = useWallet();
    const { queryParams } = useCheckoutData();
    const {
        authToken,
        contract,
        confirmContact,
        setEmail,
        setEmailBeingEdited,
        email: checkoutEmail, // From the checkout data (stripe, etc)
    } = useCheckoutForm();

    const defaultEmail = useMemo(() => {
        return queryParams.email || checkoutEmail || getWalletEmail() || ``;
    }, [queryParams.email, checkoutEmail, getWalletEmail]);

    const [inputEmail, setInputEmail] = useState<string>(defaultEmail);
    const [isPostingEmail, setIsPostingEmail] = useState<boolean>(false);
    const isEdited = inputEmail !== defaultEmail;

    const isSetFromExternalSource = !!queryParams.email || !!checkoutEmail;

    const handleSaveAndOrContinue = useCallback(async () => {
        if (
            !walletConnected?.address ||
            !contract?.address ||
            !networkConnected?.id
        ) {
            return;
        }

        setIsPostingEmail(true);

        // Go store the new email address
        try {
            await postWalletEmail(
                walletConnected.address,
                {
                    email: inputEmail,
                    contractAddress: contract?.address || ``,
                    network: networkConnected?.id,
                },
                {
                    "Content-Type": "application/json",
                    Authorization: authToken,
                    address: walletConnected.address,
                }
            );
        } catch (error) {
            console.error(error);
        }

        // Update the wallet email associated with the wallet address
        setWalletEmail(inputEmail);

        // Even if the post fails, use the email for the form and continue
        setEmail(inputEmail);

        // Confirm the contact info so we can move to the next state
        confirmContact();

        // Reset the email being edited state (dictates checkout state)
        setEmailBeingEdited(false);

        // Reset the posting state
        setIsPostingEmail(false);
    }, [
        walletConnected?.address,
        contract?.address,
        networkConnected?.id,
        inputEmail,
        authToken,
        confirmContact,
        setEmail,
        setEmailBeingEdited,
        setWalletEmail,
    ]);

    useEffect(() => {
        // Update the wallet email associated with the wallet address
        (async () => {
            setWalletEmail(defaultEmail);
        })();
    }, [defaultEmail, setWalletEmail]);

    return {
        inputEmail,
        setInputEmail,
        isPostingEmail,
        isEdited,
        isSetFromExternalSource,
        handleSaveAndOrContinue,
    };
};

export { useCheckoutEmail };
