import { ComponentType, useEffect, useState, useMemo } from "react";
import {
    DynamicContextProps,
    DynamicContextProvider,
    AuthModeType,
} from "@dynamic-labs/sdk-react-core";
import { SdkViewType, SdkViewSectionType } from "@dynamic-labs/sdk-api-core";
// import { WalletProviderProps } from "../index";

const withDynamicContext = <
    T extends object & {
        requiresDynamicLogin?: boolean;
        environmentId?: string;
    },
>(
    ContextProvider: ComponentType<T>,
    settingsFactory: (props: T) => DynamicContextProps["settings"]
) => {
    return (props: T) => {
        const baseSettings = useMemo(
            () => settingsFactory(props),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [props.environmentId] // re-render when/if the environmentId changes
        );

        const [settings, setSettings] = useState<
            DynamicContextProps["settings"]
        >({
            ...baseSettings,
        });

        useEffect(() => {
            if (baseSettings.environmentId) {
                setSettings(baseSettings);
            }
        }, [baseSettings]);

        const dynamicSettings = useMemo(() => {
            if (!props.requiresDynamicLogin) {
                // CONNECT-ONLY SETTINGS
                return {
                    ...settings,
                    initialAuthenticationMode: "connect-only" as AuthModeType,
                    cssOverrides: `
                        ${settings.cssOverrides}
                        /* Hide email when connecting with wallet only */
                        .login-with-email-form {
                            display: none;
                        }
                        .login-view__scroll__section:has(.login-with-email-form) + .login-view__scroll__section--separator {
                            display: none;
                        }
                            
                        /* Change "Login or Signup" */
                        [copykey="dyn_wallet_list.title.connect"]::after, [copykey="dyn_login.title.all"]::after {
                            content: "Connect a wallet";
                        }
                    `,
                };
            }
            // CONNECT-AND-SIGN SETTINGS
            return {
                ...settings,
                initialAuthenticationMode: "connect-and-sign" as AuthModeType,
                cssOverrides: `
                        ${settings.cssOverrides}
                        *:has(.loop-crypto-header) + .modal-header {
                            padding-top: 0;
                        }
                        [copykey="dyn_wallet_list.title.connect"], [copykey="dyn_login.title.all"] {
                            display: none;
                        }
                    `,
                overrides: {
                    ...settings.overrides,
                    views: [
                        ...(settings.overrides?.views || []),
                        {
                            type: SdkViewType.Login,
                            sections: [
                                {
                                    type: SdkViewSectionType.Email,
                                },
                                {
                                    type: SdkViewSectionType.Separator,
                                    label: "OR",
                                },
                                {
                                    type: SdkViewSectionType.Wallet,
                                    numOfItemsToDisplay: 2,
                                },
                            ],
                        },
                    ],
                },
            };
        }, [settings, props.requiresDynamicLogin]);

        try {
            return (
                <DynamicContextProvider settings={dynamicSettings}>
                    <ContextProvider {...props} />
                </DynamicContextProvider>
            );
        } catch (error) {
            return <ContextProvider {...props} />;
        }
    };
};

export default withDynamicContext;
