import Section from "components/Section";
import styled from "styled-components";
import spacing from "theme/spacing";

export const ErrorWrapper = styled(Section)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
    padding: ${spacing.md};
`;
