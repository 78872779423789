import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AdminApp from "admin/App";
import CustomerApp from "customer/App";
import RecurringApp from "recurring/App";
import CheckoutApp from "checkout/App";
import CompanyApp from "company/App";
import PayApp from "pay/App";
import InvoiceApp from "invoice/App";
import { reactAppBuildType } from "default-variables";
import reportWebVitals from "reportWebVitals";
import { booleanFromString } from "utils/booleans";
import * as Sentry from "@sentry/react";

// Hide console messages when not in "development" environment
if (reactAppBuildType === `production`) {
    console.log = () => {};
    console.table = () => {};
    console.debug = () => {};
    console.group = () => {};
}

Sentry.init({
    dsn: "https://26304e0bf3a2132a272b3178b234d43b@o4508372523155456.ingest.us.sentry.io/4508372534165504",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            networkDetailAllowUrls: [
                /^https?:\/\/([a-zA-Z0-9-]+\.)?api\.loopcrypto\.xyz(\/.*)?$/,
            ],
            networkRequestHeaders: [
                "X-Custom-Header",
                "entity-id",
                "address",
                "Authorization",
            ],
            networkResponseHeaders: ["X-Custom-Header"],
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: false,
            stickySession: true,
        }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost"],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

Sentry.setTag("Application", import.meta.env.VITE_BUILD_TARGET);
Sentry.setTag("Server", reactAppBuildType);

const renderApp = () => {
    switch (import.meta.env.VITE_BUILD_TARGET) {
        case "admin":
            return <AdminApp />;
        case "customer":
            return <CustomerApp />;
        case "recurring":
            return <RecurringApp />;
        case "checkout":
            return <CheckoutApp />;
        case "company":
            return <CompanyApp />;
        case "pay":
            return <PayApp />;
        case "invoice":
            return <InvoiceApp />;
        default:
            return <div>Invalid build target</div>;
    }
};

createRoot(document.getElementById("root")!).render(
    booleanFromString(import.meta.env.VITE_STRICT) === false ? (
        renderApp()
    ) : (
        <StrictMode>{renderApp()}</StrictMode>
    )
);

const style = `color: #fff; background-color: #424ff2; padding: 0.25rem; border-radius: 0.25rem;`;
if (booleanFromString(import.meta.env.VITE_STRICT) === false) {
    console.log(
        `%cReact <StrictMode> is disabled.`,
        `${style} padding: 0.5rem 0.75rem; font-weight: 900;`,
        `\n  Read more about StrictMode here:`,
        `\n  https://react.dev/reference/react/StrictMode`
    );
}

console.group(`Build settings`);
console.log(`%cMode:`, style, import.meta.env.MODE);
console.log(`%cServer:`, style, reactAppBuildType);
console.log(`%cApplication:`, style, import.meta.env.VITE_BUILD_TARGET);
console.log(`%cAPI location:`, style, import.meta.env.VITE_API_URL);
console.groupEnd();

reportWebVitals();

if (import.meta.hot) {
    import.meta.hot.accept();
}
