type ApiRequestError = {
    code?: string;
    message?: string;
};

const errorMessageMap: { [key: string]: string } = {
    "504": `The server was slow to respond, please try again`,
    "404": `The requested page could not be found`,
    not_verified_user: `You are not a verified user`, // [ ] Remove when `verified` column is removed from DB
    not_active_user: `This user account is not active`,
    invalid_user_id: `User account could not be found`,
    invalid_login_token: `Your login credentials are invalid`, // Credentials are not linked to a Dynamic user id
};

export const defaultErrorMessage = `An unexpected error occurred`;

export const getErrorMessage = (error: unknown): string => {
    if (typeof error !== `object` || error === null) return defaultErrorMessage;

    const { message = ``, code = `` } = error as ApiRequestError;
    const mappedErrorMessage =
        errorMessageMap[message] ?? errorMessageMap[code];

    return mappedErrorMessage ?? defaultErrorMessage;
};

export enum ErrorCode {
    SOL_ALLOWANCE_TIMEDOUT_AND_TOO_LOW = `SOL_ALLOWANCE_TIMEDOUT_AND_TOO_LOW`,
}

export class CodedError extends Error {
    code: string;

    constructor(message: string, code: string) {
        super(message);
        this.code = code;
        Object.setPrototypeOf(this, new.target.prototype);
    }
    hasCode(code: ErrorCode): boolean {
        return this.code === code;
    }
}

export const isErrorCodedAs = (error: unknown, code: ErrorCode): boolean => {
    return error instanceof CodedError && error.hasCode(code);
};

export type CompanyApiError = {
    code: number;
    message: ApiErrorType;
};

export enum ApiErrorType {
    // Confirmation code
    ConfirmationCodeInvalid = "confirmation_code_invalid",
    ConfirmationCodeExpired = "confirmation_code_expired",
    ConfirmationCodeInvalidEmail = "confirmation_code_invalid_email",

    // Entities
    EntityCreateFailed = "entity_create_failed",

    // Items
    ItemsCreateFailed = "items_create_failed",
    ItemsMissingPayload = "items_missing_payload",

    // User
    UserAlreadyExist = "user_already_exist",
    UserCreateError = "user_create_error",

    // Checkout
    CheckoutTryAgainLater = "checkout_try_again_later",
    CheckoutTryAfterCoolDown = "checkout_try_after_cool_down",

    // Misc
    SmartContractDeployFailed = "smart_contract_deploy_failed",
    InboundTreasuryAddressesInvalid = "inbound_treasury_addresses_invalid",
    UnknownError = "unknown_error",
}

const apiErrorTypesForDisplay = {
    [ApiErrorType.ConfirmationCodeInvalid]: "Confirmation code is invalid",
    [ApiErrorType.ConfirmationCodeExpired]: "Confirmation code is expired",
    [ApiErrorType.ConfirmationCodeInvalidEmail]: "Not a valid email",
    [ApiErrorType.EntityCreateFailed]: "Failed to create the entity",
    [ApiErrorType.ItemsCreateFailed]: "Failed to create the item",
    [ApiErrorType.ItemsMissingPayload]: "Missing item details",
    [ApiErrorType.UserAlreadyExist]: "This user already exists",
    [ApiErrorType.UserCreateError]: "Filed to create the user",
    [ApiErrorType.SmartContractDeployFailed]:
        "Failed to deploy the smart contract",
    [ApiErrorType.InboundTreasuryAddressesInvalid]:
        "The receiving address is invalid",
    [ApiErrorType.UnknownError]: "Something went wrong",
    [ApiErrorType.CheckoutTryAgainLater]:
        "A previous checkout attempt is still in progress. Please wait a minute then refresh to try again.",
    [ApiErrorType.CheckoutTryAfterCoolDown]:
        "Please wait 15 minutes between subscription purchases with this wallet",
};

export function getErrorForDisplay(
    error: any,
    fallbackErrorMsg: string = apiErrorTypesForDisplay[
        ApiErrorType.UnknownError
    ]
) {
    // Default
    let errorForDisplay = fallbackErrorMsg;

    if (error && typeof error === "object" && "message" in error) {
        const specificError = error as { message: ApiErrorType };
        let potentialErrorForDisplay =
            apiErrorTypesForDisplay[specificError.message];
        if (potentialErrorForDisplay) {
            errorForDisplay = potentialErrorForDisplay;
        }
    }

    return errorForDisplay;
}
