import * as S from "./style";
import { toDollar } from "utils/financial";
import { firstToUpper } from "utils/strings";
import { useCheckoutData } from "checkout/context/CheckoutData";
import TotalPriceForDisplay from "./TotalPriceForDisplay";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import ItemTokenPriceForDisplay from "./ItemTokenPriceForDisplay";
import { isNullish } from "utils/numbers";
import Title from "components/Title";
import { Spacing } from "theme/spacing";

const MainItemSummary = () => {
    const {
        items,
        mainItem,
        usdTotalDueToday,
        isAnyPriceVarious,
        isInvoicedCheckout,
        isExternalSubscription,
        dueDateForDisplay,
    } = useCheckoutData();

    const { selectedNetwork, selectedToken } = useNetworkAndToken();

    const itemTokenPriceMatchesSelected =
        selectedNetwork &&
        selectedToken &&
        mainItem?.prices.find(
            (price) =>
                price.network === selectedNetwork.id &&
                price.tokenAddress === selectedToken.address
        );

    const priceForDisplay = itemTokenPriceMatchesSelected ? (
        <ItemTokenPriceForDisplay
            itemTokenPrice={itemTokenPriceMatchesSelected}
            amountType="amountAfterDiscount"
            includeUsd={false}
        />
    ) : (
        mainItem?.amountAfterDiscountForDisplay
    );

    const hasOneItem = items.length === 1;

    const usdPrice =
        isAnyPriceVarious && !isExternalSubscription ? (
            <>Price varies</>
        ) : !isNullish(usdTotalDueToday) ? (
            toDollar(usdTotalDueToday)
        ) : (
            false
        );

    return isInvoicedCheckout || isExternalSubscription ? (
        <section>
            {mainItem?.name && (
                <Title level="h2" spacing={[Spacing.sm, Spacing.lg]}>
                    {mainItem.name}
                </Title>
            )}
            {dueDateForDisplay && (
                <S.InvoiceRow>
                    <span>
                        {isExternalSubscription ? `Next payment` : `Due date`}
                    </span>
                    <b>{dueDateForDisplay}</b>
                </S.InvoiceRow>
            )}
            <S.InvoiceRow>
                <span>Amount</span>
                <b>{usdPrice}</b>
            </S.InvoiceRow>
        </section>
    ) : mainItem ? (
        <section>
            <header>
                <S.PayNow>
                    <TotalPriceForDisplay />
                </S.PayNow>
            </header>
            {hasOneItem && !mainItem.isOneTimePayment && (
                <S.Recurring>
                    {mainItem.hasInitialOffset ? (
                        <>
                            Then {priceForDisplay}{" "}
                            {mainItem.frequencyForDisplay.toLowerCase()}
                        </>
                    ) : (
                        <>{firstToUpper(mainItem.frequencyForDisplay)}</>
                    )}
                </S.Recurring>
            )}
        </section>
    ) : (
        <></>
    );
};

export default MainItemSummary;
