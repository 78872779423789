import { useEffect } from "react";
import { AvailableNetwork } from "default-variables";
import { PrimaryWalletAccount } from "./useWalletManagement";
import { WalletChangeEvent, NetworkChangeEvent } from "connect/src/types";

const useWalletLoopConnect = ({
    wallet,
    network,
    onWalletChange,
    onNetworkChange,
}: {
    wallet: PrimaryWalletAccount | null;
    network: AvailableNetwork | null;
    onWalletChange?: (detail: WalletChangeEvent) => void;
    onNetworkChange?: (detail: NetworkChangeEvent) => void;
}) => {
    useEffect(() => {
        if (!wallet?.address || !onWalletChange) return;

        onWalletChange({
            address: wallet.proxyFor ?? wallet.address,
            ens: wallet.ens ?? undefined,
        });
    }, [wallet?.address, wallet?.proxyFor, wallet?.ens, onWalletChange]);

    useEffect(() => {
        if (!network?.id || !onNetworkChange) return;

        onNetworkChange({
            id: network.id,
            name: network.label,
            chain: network.chain,
        });
    }, [network?.id, network?.label, network?.chain, onNetworkChange]);
};

export default useWalletLoopConnect;
