export enum TransactionType {
    CONFIRMED = "confirmed",
    UPCOMING = "upcoming",
    UNCOLLECTIBLE = "uncollectible",
    DUE = "due",
    CANCELED = "canceled",
}

export const defaultTransactionRoute = TransactionType.CONFIRMED;

export interface TransactionFilters {
    date?: number;
    search?: string;
    amount?: string;
    entities?: string;
    networks?: string;
}
