import { GeneralTokenDetailsResponse } from "api";
import {
    CustomerAgreement,
    CustomerItem,
    CustomerTransactionDetail,
} from "api/types/customer";
import { formatUnits } from "ethers";
import { ReactNode } from "react";
import { Density, ItemCategoryType, TransferStatus } from "types/common-enums";
import {
    describeFrequency,
    getDateInSeconds,
    getReadableDate,
} from "utils/dates";
import { toDollar, toCoin } from "utils/financial";
import { isNullish } from "utils/numbers";
import { isTransactionUpcomingOrDue } from "utils/transactions";
import Badge from "components/Badge";
import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";
import Anchor from "components/Anchor";

export const getSubscriptionAmount = (
    items: Pick<CustomerItem, "amount" | "type">[]
) => {
    // The "amount" is the sum of subscription items, `null` if any of those is priced in token
    return items.reduce<bigint | null>((sum, { amount, type }) => {
        if (type !== ItemCategoryType.Subscription) return sum;
        if (sum === null || amount === undefined) return null;

        return sum + BigInt(amount);
    }, BigInt(0));
};

export const getSubscriptionNextTransaction = (
    nextScheduledTx:
        | Pick<
              CustomerTransactionDetail,
              "amount" | "status" | "usd" | "billDate"
          >
        | undefined,
    token: Pick<GeneralTokenDetailsResponse, "decimals" | "symbol">,
    isLastPayment: boolean = false
) => {
    const isPending = nextScheduledTx?.status === TransferStatus.Pending;

    const isPastDue =
        (!isPending &&
            nextScheduledTx &&
            nextScheduledTx?.billDate < getDateInSeconds()) ||
        false;

    const isLateDraft =
        nextScheduledTx?.status === TransferStatus.Draft && isPastDue;

    return nextScheduledTx
        ? `Your ${isLastPayment ? `last` : isLateDraft ? `pending draft` : isPastDue ? `late` : isPending ? `pending` : `next`} payment${
              Number(nextScheduledTx.amount) === 0 &&
              nextScheduledTx.status === TransferStatus.Draft
                  ? ``
                  : ` of ${
                        nextScheduledTx.usd
                            ? toDollar(nextScheduledTx.amount)
                            : `${toCoin(
                                  Number(
                                      formatUnits(
                                          nextScheduledTx.amount,
                                          token?.decimals ?? 18
                                      )
                                  )
                              )} ${token.symbol}`
                    }`
          } ${
              isPending
                  ? `will be processed shortly.`
                  : `${isPastDue ? `was` : `is`} due on ${getReadableDate(nextScheduledTx.billDate)}.${isLateDraft ? ` This payment will not be processed until it's finalized.` : ``}`
          }`
        : `No payments are scheduled.`;
};

export const getSubscriptionCancelMessage = (
    agreement: Pick<CustomerAgreement, "endDate" | "cancellationEffectiveDate">
) => {
    return agreement.endDate
        ? `Canceled on ${getReadableDate(agreement.endDate)}.`
        : agreement.cancellationEffectiveDate
          ? `Scheduled for cancelation on ${getReadableDate(
                agreement.cancellationEffectiveDate
            )}.`
          : ``;
};

export const getNextTransactionStatusMessage = (
    agreement: Pick<CustomerAgreement, "endDate" | "cancellationEffectiveDate">,
    nextScheduledTx:
        | Pick<
              CustomerTransactionDetail,
              "amount" | "status" | "usd" | "billDate"
          >
        | undefined,
    token: Pick<GeneralTokenDetailsResponse, "decimals" | "symbol">
) => {
    const txWillBeCancelledButIsNotInDraft =
        agreement.cancellationEffectiveDate &&
        nextScheduledTx?.status !== TransferStatus.Draft &&
        isTransactionUpcomingOrDue(nextScheduledTx?.status);

    const txIsNotCancelled =
        !agreement.endDate && !agreement.cancellationEffectiveDate;

    return txWillBeCancelledButIsNotInDraft || txIsNotCancelled
        ? getSubscriptionNextTransaction(
              nextScheduledTx,
              token,
              !!agreement.cancellationEffectiveDate
          )
        : getSubscriptionCancelMessage(agreement);
};

export const hasSufficientAmountForNextTransaction = ({
    nextPayment,
    amountUsd,
    amountCoin,
}: {
    nextPayment: Pick<CustomerTransactionDetail, "amount" | "usd"> | undefined;
    amountUsd: string | null;
    amountCoin: string | null;
}) => {
    if (!nextPayment) return false;
    return nextPayment.usd
        ? !isNullish(amountUsd) &&
              BigInt(amountUsd) >= BigInt(nextPayment.amount)
        : !isNullish(amountCoin) &&
              BigInt(amountCoin) >= BigInt(nextPayment.amount);
};

export const getSubscriptionFrequency = (
    items: Pick<CustomerItem, "frequency" | "type">[]
): string => {
    const subscriptions = items.filter(
        (item) => item.frequency && item.type === ItemCategoryType.Subscription
    );

    if (!subscriptions.length) return "";

    const [first] = subscriptions;
    if (subscriptions.length === 1) return describeFrequency(first.frequency!);

    const hasMatchingFrequencies = subscriptions.every(
        (sub) =>
            sub.frequency?.type === first.frequency?.type &&
            sub.frequency?.value === first.frequency?.value
    );

    return hasMatchingFrequencies
        ? describeFrequency(first.frequency!)
        : "across multiple payment intervals";
};

export const getSubscriptionAmountAndFrequency = (
    amount: bigint | null,
    frequency: string
): ReactNode => {
    return amount === null
        ? `Priced in token`
        : amount <= BigInt(0)
          ? `Price varies`
          : `${toDollar(amount)} ${frequency}`;
};

export const getSubscriptionStatusBadge = (
    agreement: Pick<CustomerAgreement, "endDate" | "cancellationEffectiveDate">,
    nextScheduledTx:
        | Pick<CustomerTransactionDetail, "status" | "billDate">
        | undefined
) => {
    const isPastDue =
        !!nextScheduledTx && nextScheduledTx?.billDate < getDateInSeconds();
    const isDraft = nextScheduledTx?.status === TransferStatus.Draft;
    const isPending = nextScheduledTx?.status === TransferStatus.Pending;

    return agreement.endDate ? (
        <Badge density={Density.Default} variant="gray">
            Canceled
        </Badge>
    ) : agreement.cancellationEffectiveDate ? (
        <Badge density={Density.Default} variant="gray">
            Cancel scheduled for{" "}
            {getReadableDate(agreement.cancellationEffectiveDate)}
        </Badge>
    ) : isDraft && isPastDue ? (
        <Tooltip
            title={
                <>
                    Your next payment is still in draft status. This typically
                    happens when there's an issue finalizing the invoice. We're
                    working to resolve it. Your payment will only be processed
                    once the invoice is finalized. If you have any questions,
                    please reach out to{" "}
                    <Anchor
                        href={`mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`}
                    >
                        {import.meta.env.VITE_EMAIL_SUPPORT}
                    </Anchor>
                    .
                </>
            }
        >
            <Badge
                density={Density.Default}
                variant="orange"
                style={{ lineHeight: `0.825` }}
            >
                Draft pending{" "}
                <Info width="1em" height="1em" fill="currentColor" />
            </Badge>
        </Tooltip>
    ) : isPending ? (
        <Tooltip
            title={`Your transaction has been sent onchain and is waiting for confirmation`}
        >
            <Badge
                density={Density.Default}
                variant="green"
                style={{ lineHeight: `0.825` }}
            >
                Pending <Info width="1em" height="1em" fill="currentColor" />
            </Badge>
        </Tooltip>
    ) : isPastDue ? (
        <Badge density={Density.Default} variant="red">
            Past due
        </Badge>
    ) : null;
};

export const getUpcomingPaymentStatusBadge = (
    nextScheduledTx:
        | Pick<CustomerTransactionDetail, "status" | "billDate">
        | undefined
) => {
    const isPastDue =
        !!nextScheduledTx && nextScheduledTx?.billDate < getDateInSeconds();
    const isDraft = nextScheduledTx?.status === TransferStatus.Draft;
    const isPending = nextScheduledTx?.status === TransferStatus.Pending;
    const isScheduled = nextScheduledTx?.status === TransferStatus.Scheduled;

    return isDraft && isPastDue ? (
        <Tooltip
            title={
                <>
                    Your next payment is still in draft status. This typically
                    happens when there's an issue finalizing the invoice. We're
                    working to resolve it. Your payment will only be processed
                    once the invoice is finalized. If you have any questions,
                    please reach out to{" "}
                    <Anchor
                        href={`mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`}
                    >
                        {import.meta.env.VITE_EMAIL_SUPPORT}
                    </Anchor>
                    .
                </>
            }
        >
            <Badge
                density={Density.Default}
                variant="orange"
                style={{ lineHeight: `0.825` }}
            >
                Draft pending{" "}
                <Info width="1em" height="1em" fill="currentColor" />
            </Badge>
        </Tooltip>
    ) : isDraft ? (
        <Badge density={Density.Default} variant="yellow">
            Draft
        </Badge>
    ) : isPending ? (
        <Tooltip
            title={`Your transaction has been sent onchain and is waiting for confirmation`}
        >
            <Badge
                density={Density.Default}
                variant="green"
                style={{ lineHeight: `0.825` }}
            >
                Pending <Info width="1em" height="1em" fill="currentColor" />
            </Badge>
        </Tooltip>
    ) : isPastDue ? (
        <Badge density={Density.Default} variant="red">
            Past due
        </Badge>
    ) : isScheduled ? (
        <Badge density={Density.Default} variant="blue">
            Scheduled
        </Badge>
    ) : null;
};
