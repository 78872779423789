import EnvironmentTag from "components/EnvironmentTag";
import styled from "styled-components";
import spacing from "theme/spacing";

export const LoginDynamic = styled.div<{ environment: string | undefined }>`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    min-height: 100vh;
    padding-block: ${spacing.xl};
    ${({ environment }) =>
        environment === `demo`
            ? `background-color: #99CBFF;`
            : `background-image: linear-gradient(180deg, #3545e8 0%, #6f7bf9 124.22%);`}
`;

export const Container = styled.main`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    place-self: center;
`;

export const EnvBadge = styled(EnvironmentTag)`
    margin-block: ${spacing.sm} ${spacing.none};
    display: block;
`;

export const LongLogo = styled.img`
    width: 100%;
    margin-block: 0.5rem;
    display: block;
`;

export const LoginTopper = styled.div`
    width: 100%;
    max-width: 210px;
    margin: 0 auto;
    padding: ${spacing.sm};
`;
