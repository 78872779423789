import * as S from "./styles";
import { HTMLAttributes, useMemo } from "react";

export enum SelectSizes {
    Small = "small",
    Medium = "medium",
    Large = "large",
}

const isOptionGroup = <T,>(
    option: SelectOption<T> | SelectOptionGroup<T>
): option is SelectOptionGroup<T> => {
    return (option as SelectOptionGroup<T>).options !== undefined;
};

const Placeholder = ({
    placeholder,
}: {
    placeholder: string | SelectOption | undefined;
}) => {
    const label =
        typeof placeholder === "string" ? placeholder : placeholder?.label;

    if (!label) return <></>;

    return (
        <option value="" disabled={true}>
            {label}
        </option>
    );
};

export interface SelectOption<T = string | number> {
    label: string | number;
    value: T;
    disabled?: boolean;
    group?: string;
}

export interface SelectOptionGroup<T = string | number> {
    label: string;
    options: SelectOption<T>[];
}

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
    options?: (SelectOption | SelectOptionGroup)[];
    value: string | number;
    name?: string;
    placeholder?: string | Omit<SelectOption, "disabled">;
    onBlur?: React.FocusEventHandler<HTMLSelectElement>;
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    onFocus?: React.FocusEventHandler<HTMLSelectElement>;
    disabled?: boolean;
    required?: boolean;
    icon?: React.ReactNode;
    bold?: boolean;
    hollow?: boolean;
    innerRef?: React.RefObject<HTMLSelectElement>;
    size?: SelectSizes;
}

export const Select: React.FunctionComponent<SelectProps> = ({
    options,
    value,
    name,
    placeholder,
    onBlur,
    onChange,
    onFocus,
    disabled = false,
    required,
    bold = false,
    hollow = false,
    innerRef,
    size = SelectSizes.Medium,
    ...props
}) => {
    const groupedOptions = useMemo(
        () =>
            options?.reduce<(SelectOption | SelectOptionGroup)[]>(
                (acc, option) => {
                    if ("group" in option && option.group) {
                        const group = acc.find(
                            (item): item is SelectOptionGroup =>
                                isOptionGroup(item) &&
                                item.label === option.group
                        );

                        if (group) {
                            group.options.push(option);
                        } else {
                            acc.push({
                                label: option.group,
                                options: [option],
                            });
                        }
                    } else {
                        acc.push(option);
                    }
                    return acc;
                },
                []
            ) ?? [],

        [options]
    );

    const handleOnBlur: React.FocusEventHandler<HTMLSelectElement> = (
        event: React.FocusEvent<HTMLSelectElement>
    ) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange: React.ChangeEventHandler<HTMLSelectElement> = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnFocus: React.FocusEventHandler<HTMLSelectElement> = (
        event: React.FocusEvent<HTMLSelectElement>
    ) => {
        if (onFocus) {
            onFocus(event);
        }
    };

    return (
        <S.Select
            {...props}
            name={name}
            id={name}
            value={value ?? ""}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            disabled={disabled}
            bold={bold}
            hollow={hollow}
            required={required}
            ref={innerRef}
            $size={size}
        >
            <Placeholder placeholder={placeholder} />
            {groupedOptions.map((option) => {
                if (isOptionGroup(option)) {
                    return (
                        <optgroup label={option.label} key={option.label}>
                            {option.options.map((opt) => (
                                <option
                                    key={opt.value}
                                    value={opt.value}
                                    disabled={opt.disabled}
                                >
                                    {opt.label}
                                </option>
                            ))}
                        </optgroup>
                    );
                } else {
                    return (
                        <option
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </option>
                    );
                }
            })}
        </S.Select>
    );
};

export default Select;
