import { useEffect, useState } from "react";
import { SmartContractType } from "types/common-enums";
import { Box, Button, TextField } from "@mui/material";
import { useNotificationQueue } from "context/NotificationQueue";
import { apiServerUrl } from "default-variables";
import { useUser } from "context/User";
import Select from "components/Select";
import Label from "components/Label";
import { NotificationType } from "components/Notification";

interface ContractEntityFormProps {
    networkId: number;
    contractAddress: string; // Address
    inboundTreasuryAddress?: string | null; // Address
    outboundTreasuryAddress?: string | null; // Address
    contractFactoryId: string;
    onSubmit: any;
    closePopover: any;
}

const ContractEntityForm = ({
    networkId,
    contractAddress,
    inboundTreasuryAddress,
    outboundTreasuryAddress,
    contractFactoryId,
    onSubmit,
    closePopover,
}: ContractEntityFormProps) => {
    const { getSessionToken } = useUser();
    const { addNotification } = useNotificationQueue();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // TODO type state
    const [formState, setFormState] = useState<any>({
        companyName: null,
        logoUrl: null,
        websiteUrl: null,
        backgroundStyle: null,
        collectEmails: 1,
        signByLoop: 1,
        sendCompanyEmails: 1,
    });

    useEffect(() => {
        if (submitted) {
            closePopover();
        }
    }, [submitted]);

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        setSubmitting(true);
        const {
            companyName,
            logoUrl,
            websiteUrl,
            backgroundStyle,
            collectEmails,
            signByLoop,
            sendCompanyEmails,
        } = formState;
        try {
            const newContractAndEntity = {
                contractAddress: contractAddress,
                contractTypeId: SmartContractType.VariableRate,
                networkId: networkId,
                contractFactoryId: contractFactoryId,
                companyName: companyName,
                logoUrl: logoUrl || "-",
                websiteUrl: websiteUrl || "",
                backgroundStyle: backgroundStyle,
                collectEmails: collectEmails,
                signByLoop: signByLoop,
                sendCompanyEmails: sendCompanyEmails,
                inboundTreasury: inboundTreasuryAddress,
                outboundTreasury: outboundTreasuryAddress,
            };
            const response = await fetch(
                `${apiServerUrl}/api/v1/manage/contracts`,
                {
                    method: `POST`,
                    headers: {
                        Authorization: getSessionToken(),
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(newContractAndEntity),
                }
            );
            if (response.status === 200) {
                onSubmit({
                    response: await response.json(),
                    form: formState,
                });
                setSubmitted(true);
            } else {
                addNotification({
                    msg: "Something went wrong",
                    type: NotificationType.ERROR,
                });
                setSubmitting(false);
            }
        } catch (err) {
            console.error(err);
            addNotification({
                msg: "Something went wrong",
                type: NotificationType.ERROR,
            });
            setSubmitting(false);
        }
    };

    const handleChangeFactory = (field: string) => {
        return (evt: any) => {
            // validate
            setFormState({ ...formState, [field]: evt.target.value });
        };
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Box mb={4} mt={2} minWidth="450px">
                <Box mb={2} display="flex" flexDirection="column" gap={2}>
                    <TextField
                        label="Company Name"
                        value={formState.companyName}
                        onChange={handleChangeFactory(`companyName`)}
                        fullWidth
                    />
                    <TextField
                        label="Logo URL"
                        value={formState.outboundTreasury}
                        onChange={handleChangeFactory(`logoUrl`)}
                        fullWidth
                    />
                    <TextField
                        label="Website URL"
                        value={formState.signer}
                        onChange={handleChangeFactory(`websiteUrl`)}
                        fullWidth
                    />
                    <TextField
                        label="Background Style"
                        value={formState.governor}
                        onChange={handleChangeFactory(`backgroundStyle`)}
                        fullWidth
                    />
                    <Label htmlFor="collectEmails">Collect Email</Label>
                    <Select
                        name="collectEmails"
                        value={formState.collectEmails}
                        onChange={handleChangeFactory(`collectEmails`)}
                        options={[
                            { value: 1, label: `Yes` },
                            { value: 0, label: `No` },
                        ]}
                    />
                    <Label htmlFor="signByLoop">Sign By Loop</Label>
                    <Select
                        name="signByLoop"
                        value={formState.signByLoop}
                        onChange={handleChangeFactory(`signByLoop`)}
                        options={[
                            { value: 1, label: `Yes` },
                            { value: 0, label: `No` },
                        ]}
                    />
                    <Label htmlFor="sendCompanyEmails">Opt in to emails</Label>
                    <Select
                        name="sendCompanyEmails"
                        value={formState.sendCompanyEmails}
                        onChange={handleChangeFactory(`sendCompanyEmails`)}
                        options={[
                            { value: 1, label: `Yes` },
                            { value: 0, label: `No` },
                        ]}
                    />
                </Box>
            </Box>
            <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                fullWidth
            >
                {submitting ? "Creating Entity..." : "Create Entity"}
            </Button>
        </Box>
    );
};

export default ContractEntityForm;
