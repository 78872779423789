import { css } from "styled-components";
import spacing, { SpacingBox, SpacingTrack } from "theme/spacing";

export const getSpacing = (sides: SpacingTrack | SpacingBox) =>
    sides.map((s) => spacing[s]).join(" ");

export const withSpacing = (spacing?: SpacingTrack) => css`
    ${spacing && `margin-block: ${getSpacing(spacing)} !important;`}
`;

export const getComputedVariableValue = (
    container: ShadowRoot | null,
    cssVarName: string
): string | undefined => {
    if (!container) return;
    return getComputedStyle(container.host).getPropertyValue(cssVarName).trim();
};
