export enum QueryKey {
    CompanyTransfers = `getCompanyTransfers`,
    CompanyAgreements = `getCompanyAgreements`,
    CompanyConfig = `getCompanyConfig`,
    CompanyItems = `getCompanyItems`,
    Networks = `getNetworks`,
    Tokens = `getTokensMetadata`,
}

// [ ] Update useTableData to use the "Adjusted" version of this next time those tables are being updated
// The number params should be pre-converted to strings in the `useGet_` hook for the endpoint, rather than in the api method
export interface PagingQueryParams<TSortableColId = string> {
    page?: number;
    limit?: number;
    sortBy?: TSortableColId;
    sortDir?: "asc" | "desc";
}

export interface PagingQueryParamsAdjusted<TSortableColId = string> {
    page?: string;
    limit?: string;
    sortBy?: TSortableColId;
    sortDir?: "asc" | "desc";
}
