import { reactAppBuildType } from "default-variables";

const envIdentifier = `${
    import.meta.env.VITE_BUILD_TARGET
}.${reactAppBuildType}.`;

interface Cookie {
    key: string;
    value: string;
    expiration: Date;
}

export const setCookie = (cookies: Cookie[]) => {
    cookies.forEach(({ key, value, expiration }: Cookie) => {
        document.cookie = `${envIdentifier}${key}=${encodeURIComponent(
            value || ``
        )}; expires=${expiration.toUTCString()}; path=/`;
    });
};

export const getCookie = (key: Cookie[`key`]) => {
    return decodeURIComponent(
        document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${envIdentifier}${key}`))
            ?.split("=")[1] || ``
    );
};

interface Storage<T> {
    key: string;
    value: T;
    storageType?: "session" | "local";
}

export const setStorageItem = <T,>({
    key,
    value,
    storageType = "local",
}: Storage<T>) => {
    try {
        const storage =
            storageType === "session"
                ? window.sessionStorage
                : window.localStorage;
        storage.setItem(`${envIdentifier}${key}`, JSON.stringify(value));
    } catch (error) {
        console.error(
            `Error setting ${storageType}Storage item ${key} for environment ${envIdentifier}`,
            error
        );
    }
};

export const getStorageItem = <T,>({
    key,
    storageType = "local",
}: Omit<Storage<T>, "value">): T | null => {
    try {
        const storage =
            storageType === "session"
                ? window.sessionStorage
                : window.localStorage;
        const storedItem = storage.getItem(`${envIdentifier}${key}`);
        return storedItem === null ? null : (JSON.parse(storedItem) as T);
    } catch (error) {
        console.error(
            `Error getting ${storageType}Storage item ${key} for environment ${envIdentifier}`,
            error
        );
        return null;
    }
};

export const removeStorageItem = <T,>({
    key,
    storageType = "local",
}: Omit<Storage<T>, "value">) => {
    try {
        const storage =
            storageType === "session"
                ? window.sessionStorage
                : window.localStorage;
        storage.removeItem(`${envIdentifier}${key}`);
    } catch (error) {
        console.error(
            `Error removing ${storageType}Storage item ${key} for environment ${envIdentifier}`,
            error
        );
    }
};
