import { useCallback, useEffect, useRef, useState } from "react";
import {
    useDynamicContext,
    useDynamicEvents,
    useSwitchWallet,
    useUserWallets,
    useWalletConnectorEvent,
    type Wallet as DynamicWallet,
} from "@dynamic-labs/sdk-react-core";
import { getSigner, getWeb3Provider } from "@dynamic-labs/ethers-v6";
import { isSolanaWallet } from "@dynamic-labs/solana";
import { BlockchainNetwork } from "default-variables";
import {
    EvmWalletSigner,
    EvmProvider,
    SolProvider,
    SolWalletSigner,
} from "types/common";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";

export interface WalletApp {
    walletId: string;
    icon: string | null;
    label: string;
}

interface PrimaryWalletAccountBase extends WalletApp {
    id: string;
    address: string;
    ens: string | null;
    proxyFor: string | null;
    safe: Promise<SafeWallet | null>;
    connect: (() => Promise<void>) | undefined;
}

export type SolWallet = {
    chain: "SOL";
    isConnected: boolean;
    signer: SolWalletSigner | null;
    provider: SolProvider | null;
};

export type EvmWallet = {
    chain: "EVM";
    isConnected: boolean;
    signer: EvmWalletSigner | null;
    provider: EvmProvider | null;
};

export type PrimaryWalletAccount = PrimaryWalletAccountBase &
    (SolWallet | EvmWallet);

export interface ConnectedWallet extends WalletApp {
    addresses: {
        id: string;
        address: string;
        chain: BlockchainNetwork;
        isConnected: boolean;
    }[];
}

export const mapDynamicUserWalletsToConnectedWallets = async (
    wallets: DynamicWallet[]
): Promise<ConnectedWallet[]> => {
    return await wallets.reduce<Promise<ConnectedWallet[]>>(
        async (accPromise, wallet) => {
            const acc = await accPromise;

            const isConnected = await wallet.isConnected();
            const newAddress = {
                id: wallet.id,
                address: wallet.address,
                chain: wallet.connector.connectedChain as BlockchainNetwork,
                isConnected,
            };

            const existingWallet = acc.find((w) => w.walletId === wallet.key);

            if (existingWallet) {
                return acc.map((w) =>
                    w.walletId === wallet.key
                        ? { ...w, addresses: [...w.addresses, newAddress] }
                        : w
                );
            } else {
                const walletObj =
                    wallet.connector.constructorProps.walletBook.wallets[
                        wallet.key
                    ];

                return [
                    ...acc,
                    {
                        addresses: [newAddress],
                        icon: walletObj?.brand.spriteId
                            ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${walletObj.brand.spriteId}`
                            : ``,
                        label: wallet.connector.name,
                        walletId: wallet.key,
                    },
                ];
            }
        },
        Promise.resolve([])
    );
};

const mapDynamicPrimaryWalletToConnectedWallet = async (
    wallet: DynamicWallet | null
): Promise<PrimaryWalletAccount | null> => {
    if (!wallet) return null;

    const isConnected = await wallet.isConnected();

    // [ ] Provider should be moved to the network object
    const walletByChain = isSolanaWallet(wallet)
        ? {
              chain: "SOL" as const,
              isConnected: isConnected,
              signer: isConnected
                  ? await wallet.getSigner().catch(() => null)
                  : null,
              provider: isConnected
                  ? await wallet.getConnection().catch(() => null)
                  : null,
          }
        : {
              chain: "EVM" as const,
              isConnected: isConnected,
              signer: isConnected
                  ? await getSigner(wallet).catch(() => null)
                  : null,
              provider: isConnected
                  ? await getWeb3Provider(wallet).catch(() => null)
                  : null,
          };

    const walletObj =
        wallet.connector.constructorProps.walletBook.wallets[wallet.key];

    const areAccountsConnected =
        await wallet.connector.getConnectedAccounts?.();

    const newWallet: PrimaryWalletAccountBase = {
        id: wallet.id,
        address: wallet.address,
        walletId: wallet.key,
        icon: walletObj?.brand.spriteId
            ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${walletObj.brand.spriteId}`
            : ``,
        label: walletObj?.name ?? ``,
        ens: null,
        proxyFor: null,
        safe: Promise.resolve(null),
        connect: !areAccountsConnected.length
            ? async () => await wallet.connector.connect?.(wallet.address)
            : undefined,
    };

    return {
        ...newWallet,
        ...walletByChain,
    };
};

const useWalletManagement = (requiresDynamicLogin: boolean) => {
    const [isWalletConnecting, setIsWalletConnecting] = useState(false);
    const [wallet, setWallet] = useState<PrimaryWalletAccount | null>(null);
    const [walletsAvailable, setWalletsAvailable] = useState<ConnectedWallet[]>(
        []
    );
    const { primaryWallet } = useDynamicContext();
    const switchWallet = useSwitchWallet();
    const userWallets = useUserWallets();

    const updateWallet = useCallback(
        async (primaryWallet: DynamicWallet | null) => {
            try {
                const newWallet =
                    await mapDynamicPrimaryWalletToConnectedWallet(
                        primaryWallet
                    );
                setWallet(newWallet);
            } catch (error) {
                console.error(`Failed to connect wallet`, error);
                setWallet(null);
                setIsWalletConnecting(false);
                return false;
            }
            return true;
        },
        []
    );

    const setPrimaryWallet = useCallback(
        (walletId: string) => {
            switchWallet(walletId);
        },
        [switchWallet]
    );

    // [ ] Move this to `useSafeWallets` and move `proxyFor` to a variable and privitize it, exposing "isProxying" and "getActiveAddress"
    const setProxyWallet = useCallback((proxyAddr: string | null) => {
        setWallet((prevWallet) => {
            if (!prevWallet) return null;

            return {
                ...prevWallet,
                proxyFor: proxyAddr,
            };
        });
    }, []);

    const userWalletsMounted = useRef(false);

    useDynamicEvents("userWalletsChanged", async (wallets) => {
        userWalletsMounted.current = true;
        const newWallets: ConnectedWallet[] =
            await mapDynamicUserWalletsToConnectedWallets(wallets.userWallets);
        setWalletsAvailable(newWallets);
    });

    useEffect(() => {
        // Ensure that useEffect([userWallets]) only runs once for non-`requiresLogin` scenarios
        //      useDynamicEvents("userWalletsChanged") will handle future updates
        if (requiresDynamicLogin && userWalletsMounted.current) return;
        (async () => {
            const newWallets: ConnectedWallet[] =
                await mapDynamicUserWalletsToConnectedWallets(userWallets);
            setWalletsAvailable(newWallets);
        })();
        if (userWallets.length) userWalletsMounted.current = true;
    }, [requiresDynamicLogin, userWallets]);

    useEffect(() => {
        if (!primaryWallet?.address) {
            setIsWalletConnecting(false);
            setWallet(null);
            return;
        }

        setIsWalletConnecting(true);
        (async () => {
            await updateWallet(primaryWallet);
        })();
    }, [
        primaryWallet,
        primaryWallet?.id,
        primaryWallet?.address,
        primaryWallet?.connector,
        updateWallet,
    ]);

    useEffect(() => {
        if (!wallet) return;

        setIsWalletConnecting(false);
    }, [wallet]);

    // Listens to ONLY the primaryWallet's connector and fires if ALL of its accounts have disconnected (even from the wallet UI)
    //      As long as a single account from that wallet is connected (even if it isn't a linked account), the primaryWallet will
    //      detect the change as part of the `useEffect` on the primaryWallet.connector. However, if ALL accounts are disconnected,
    //      the primaryWallet object will not detect the change, so the primaryWallet won't be detected as disconnected.
    useWalletConnectorEvent(
        primaryWallet?.connector ? [primaryWallet?.connector] : [],
        "disconnect",
        async () => await updateWallet(primaryWallet)
    );

    // Listens to ONLY the primaryWallet's connector and fires if ANY of its accounts have reconnected after having had no accounts connected
    //      When all accounts for a wallet are disconnected, using the connect() method is the only way to reconnect them. However,
    //      no events fire in that instances, so this seems to be the only way to detect a wallet having been reconnected so as to
    //      update the `isConnected` status of the primaryWallet. In `requiresLogin` scenarios, if the primaryWallet as not the one
    //      connected, Dynamic will switch to the correct account, and in non-`requiresLogin` scenarios, only one account per wallet
    //      is connected, so this works in both scenarios.
    useWalletConnectorEvent(
        primaryWallet?.connector ? [primaryWallet?.connector] : [],
        "accountChange",
        async () => await updateWallet(primaryWallet)
    );

    return {
        isWalletConnecting,
        isWalletConnected: !!wallet?.address,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    };
};

export { DynamicWallet };
export default useWalletManagement;
