import { ChangeEvent, useCallback, useMemo } from "react";
import { AvailableNetwork, Blockchain, NetworkObject } from "default-variables";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { useWallet } from "context/Wallet";
import SelectWithIcon from "components/SelectWithIcon";
import { hasMultipleUniqueValues } from "utils/arrays";
import { firstToUpper } from "utils/strings";
import { SelectOption } from "components/Select";

interface NetworkSelectProps extends React.HTMLAttributes<HTMLSelectElement> {
    networks?: NetworkObject[];
}

const NetworkSelect = ({ networks, ...props }: NetworkSelectProps) => {
    const {
        isNetworkSetting,
        walletConnected,
        networkConnected,
        setConnectedNetwork,
    } = useWallet();

    const { getAvailableNetworks } = useAvailableNetworks();

    const handleNetworkChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
            setConnectedNetwork({ networkId: value });
        },
        [setConnectedNetwork]
    );

    const networkOptions: SelectOption[] = useMemo(() => {
        const allNetworks = getAvailableNetworks(true);

        const availableNetworks = networks
            ? networks.reduce<AvailableNetwork[]>((usedNetworks, { id }) => {
                  const found = allNetworks.find((n) => id == n.id);
                  if (found) {
                      usedNetworks.push(found);
                  }
                  return usedNetworks;
              }, [])
            : allNetworks;

        const isMultiChain = hasMultipleUniqueValues(
            availableNetworks,
            "chain",
            1
        );

        // Sort options on the chain the wallet is connected to to the top
        if (isMultiChain) {
            availableNetworks.sort((a, b) => {
                if (a.chain === walletConnected?.chain) return -1;
                if (b.chain === walletConnected?.chain) return 1;
                return 0;
            });
        }

        return availableNetworks.map(({ networkId, label, chain }) => {
            return {
                label: `${firstToUpper(label)}${
                    chain !== walletConnected?.chain
                        ? ` (Switch to compatible wallet)`
                        : ``
                }`,
                value: networkId,
                disabled: chain !== walletConnected?.chain,
            };
        });
    }, [networks, getAvailableNetworks, walletConnected?.chain]);

    const areNetworksAvailable = networkOptions.some(
        ({ disabled }) => !disabled
    );

    const chainName =
        walletConnected?.chain && Blockchain[walletConnected.chain];

    const placeholder = !networkConnected
        ? areNetworksAvailable
            ? {
                  value: ``,
                  label: `Switch to an available network`,
              }
            : chainName
              ? {
                    value: ``,
                    label: `Switch to compatible wallet`,
                }
              : undefined
        : undefined;

    return (
        <SelectWithIcon
            selectProps={{
                ...props,
                name: "network",
                value: networkConnected?.networkId || ``,
                onChange: handleNetworkChange,
                placeholder: placeholder,
                options: networkOptions,
                disabled: isNetworkSetting,
                className: props.className + " fs-unmask",
                title:
                    networkConnected?.networkId ||
                    `Switch to an available network`,
            }}
            icon={
                networkConnected
                    ? {
                          src: networkConnected.icon,
                          alt: `${networkConnected.label} logo`,
                      }
                    : undefined
            }
        />
    );
};

export default NetworkSelect;
