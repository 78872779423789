import Check from "components/icons/Check";
import styled from "styled-components";
import colors from "theme/colors";
import spacing from "theme/spacing";
import { colorsDeprecated as c } from "global-style";

export const MultiSigPendingDetailsSection = styled.section`
    margin-bottom: 2rem;
    margin-top: 0rem;
    gap: 1rem;
    display: flex;
`;

export const AuthorizationsList = styled.ul`
    margin: 0;
`;

export const AuthorizationItem = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
`;

export const AuthorizationPending = styled.span`
    color: ${colors.warning};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
`;

export const AuthorizationFailed = styled.span`
    color: #ff5449;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const MultiSigWrapper = styled.div`
    margin-top: 0.5rem;
    text-align: center;
`;

export const Circle = styled.div`
    display: inline-grid;
    place-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 0 auto;
    background-color: ${c.secondaryLight};
    margin-inline-end: ${spacing.xs};
`;

export const Checkmark = styled(Check)`
    fill: ${c.onSecondaryDark};
    height: 1rem;
    width: 1rem;
`;
