import {
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { useQueryProvider } from "context/QueryProvider";
import { useCallback, useEffect, useMemo } from "react";

export function useCustomQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
>(
    options: UseQueryOptions<TQueryFnData, TError, TData>
): UseQueryResult<TData, TError> {
    const { queriesFetchedOnFirstMount, queryClient } = useQueryProvider();
    const queryKeyString = JSON.stringify(options.queryKey);
    const queryInfo = useQuery<TQueryFnData, TError, TData>({
        ...options,
    });

    const { refetch, isFetching } = queryInfo;

    const invalidateAndRefetch = useCallback(async () => {
        // Invalidating the stale data
        await queryClient.invalidateQueries({
            queryKey: options.queryKey.slice(0, 1), // Extract the base query key and invalidate everything related to it.
            refetchType: "none", // This prevents the query from being refetch as we are returning the refetch function.
        });
        return refetch();
    }, [queryClient, options.queryKey, refetch]);

    // Ensure a refetch on first page visit
    useEffect(() => {
        const queryWasFetchedOnFirstMount =
            queriesFetchedOnFirstMount.includes(queryKeyString);

        // We have already fetched the query at least once
        if (queryWasFetchedOnFirstMount) return;

        // We are already fetching (non-cached)
        if (isFetching) {
            queriesFetchedOnFirstMount.push(queryKeyString);
            return;
        }

        // We are not fetching (cached) & query hasn't been fetched at least once
        if (
            !queryWasFetchedOnFirstMount &&
            !isFetching &&
            options.enabled !== false // can be true/false/undefined(default to true)
        ) {
            refetch();
            queriesFetchedOnFirstMount.push(queryKeyString);
        }
    }, [
        isFetching,
        options.enabled,
        options.queryKey,
        queriesFetchedOnFirstMount,
        queryKeyString,
        refetch,
    ]);

    const stableReturnValue = useMemo(
        () => ({
            ...queryInfo,
            refetch: invalidateAndRefetch,
        }),
        [queryInfo, invalidateAndRefetch]
    );

    return stableReturnValue;
}
