import {
    Ref,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import * as S from "./style";
import DateRange from "company/components/DateRange";
import Field from "components/Field";
import { SelectOption } from "components/Select";
import SelectMultiple, {
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { firstToUpper } from "utils/strings";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useDebounce } from "hooks/useDebounce";
import Question from "components/icons/Question";

interface FiltersProps {
    csvHeadings?: Heading[];
    csvRows?: Tuple[];
    csvName?: string;
    dateRangeFilters:
        | {
              options: SelectOption<number>[];
              default: number | undefined;
          }
        | false;
    onChange: () => void;
}

export type FiltersRef = {
    dateRange: number;
    search: string;
    selectedNetworksNames: string[];
    selectedEntitiesNames: string[];
};

const Filters = forwardRef(
    ({ dateRangeFilters, onChange }: FiltersProps, ref: Ref<FiltersRef>) => {
        const { networks } = useGetNetworks();
        const {
            config: { entities },
        } = useGetCompanyConfig();

        const [selectedNetworksNames, setSelectedNetworksNames] = useState<
            string[]
        >([]);
        const [selectedEntitiesNames, setSelectedEntitiesNames] = useState<
            string[]
        >([]);
        const [search, setSearch] = useState<string>("");
        const debouncedSearch = useDebounce(search);
        const [dateRange, setDateRange] = useState<number>(
            (dateRangeFilters && dateRangeFilters?.default) || 0
        );

        // Reset only dateRange when dateRangeFilters changes
        useEffect(() => {
            if (dateRangeFilters) {
                setDateRange(dateRangeFilters.default || 0);
            } else {
                setDateRange(0);
            }
        }, [dateRangeFilters]);

        // Don't reset other filter values when type changes
        useImperativeHandle(
            ref,
            () => ({
                dateRange: dateRangeFilters ? dateRange : 0, // Only include dateRange if filters are enabled
                search,
                selectedNetworksNames,
                selectedEntitiesNames,
            }),
            [
                dateRangeFilters,
                dateRange,
                search,
                selectedNetworksNames,
                selectedEntitiesNames,
            ]
        );

        const networksOptions: SelectMultipleOptionProps[] =
            networks.map((network) => {
                return {
                    value: network.id,
                    label: firstToUpper(network.name),
                };
            }) || [];

        const entitiesOptions: SelectMultipleOptionProps[] =
            entities.map((entity) => {
                return {
                    value: entity.entityId,
                    label: firstToUpper(entity.name),
                };
            }) || [];

        const onNetworksSelectChange = (values: string[]) => {
            setSelectedNetworksNames(values);
        };
        const onEntitiesSelectChange = (values: string[]) => {
            setSelectedEntitiesNames(values);
        };

        useEffect(() => {
            onChange();
        }, [
            debouncedSearch,
            dateRange,
            selectedNetworksNames,
            selectedEntitiesNames,
            onChange,
        ]);

        const numberOfAdditionalFilters =
            (dateRangeFilters ? 1 : 0) +
            (entities.length > 1 ? 1 : 0) +
            (networks.length > 1 ? 1 : 0);

        return (
            <S.Filters $numberOfAdditionalFilters={numberOfAdditionalFilters}>
                <S.FieldWithInfo>
                    <S.SearchInput
                        type="search"
                        name="search"
                        placeholder="Search"
                        value={search}
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                    />
                    <S.SearchTips
                        title={
                            <>
                                <p>
                                    Search by sender and receiver (address or
                                    wallet), invoice id, subscription id,
                                    transaction hash, or item name
                                </p>

                                <p>
                                    Using only a number will search by invoiced
                                    amount in USD (ie, "5" will return
                                    transactions for $5.00)
                                </p>
                            </>
                        }
                        placement="bottom"
                    >
                        <S.QIcon style={{ lineHeight: `1` }}>
                            <Question height="1rem" width="1rem" />
                        </S.QIcon>
                    </S.SearchTips>
                </S.FieldWithInfo>
                {entities.length > 1 && (
                    <Field>
                        <SelectMultiple
                            options={entitiesOptions}
                            name="entities"
                            values={selectedEntitiesNames}
                            onChange={onEntitiesSelectChange}
                            placeholder="Filter by entity"
                        />
                    </Field>
                )}
                {networks.length > 1 && (
                    <Field>
                        <SelectMultiple
                            options={networksOptions}
                            name="networks"
                            values={selectedNetworksNames}
                            onChange={onNetworksSelectChange}
                            placeholder="Filter by network"
                        />
                    </Field>
                )}
                {dateRangeFilters && (
                    <DateRange
                        value={dateRange}
                        options={dateRangeFilters.options}
                        setDateRange={setDateRange}
                    />
                )}
            </S.Filters>
        );
    }
);

export default Filters;
