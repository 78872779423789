import { useEffect, useState } from "react";
import { ethers, getAddress } from "ethers";
import { PrimaryWalletAccount } from "./useWalletManagement";
import { AvailableNetwork } from "default-variables";
import SafeApiKit from "@safe-global/api-kit";
import { EthersAdapter } from "@safe-global/protocol-kit";
import { EvmWalletSigner } from "types/common";

export interface SafeWallet {
    adapter: EthersAdapter;
    service: SafeApiKit;
    wallets: {
        address: string;
    }[];
}

const useWalletSafes = (
    wallet: PrimaryWalletAccount | null,
    network: AvailableNetwork | null
): { safe: Promise<SafeWallet | null> } => {
    const [safe, setSafe] = useState<Promise<SafeWallet | null>>(
        Promise.resolve(null)
    );

    useEffect(() => {
        if (!wallet || !network || network.chain !== "EVM") {
            setSafe(Promise.resolve(null));
            return;
        }

        const loadSafeWallets = async (): Promise<SafeWallet> => {
            const safeAdapter = new EthersAdapter({
                ethers,
                signerOrProvider: wallet.signer as EvmWalletSigner,
            });

            const safeService = new SafeApiKit({
                chainId: BigInt(network.id),
            });

            const safeWallets = await safeService.getSafesByOwner(
                getAddress(wallet.address)
            );

            return Promise.resolve({
                adapter: safeAdapter,
                service: safeService,
                wallets: safeWallets.safes.map((addr) => ({
                    address: addr,
                })),
            });
        };

        setSafe(loadSafeWallets());
    }, [wallet, network]);

    return { safe };
};

export default useWalletSafes;
