import { Blockchain } from "default-variables";
import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
    useState,
    ComponentProps,
    ReactNode,
} from "react";
import { useWallet } from "context/Wallet";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { useNetworkAndToken } from "context/NetworkAndToken";
import Field from "components/Field";
import Label from "components/Label";
import SelectWithIcon from "components/SelectWithIcon";

interface SelectNetworkProps
    extends Omit<ComponentProps<typeof Field>, "children"> {
    children?: ReactNode;
}

const SelectNetwork = forwardRef(
    ({ ...props }: SelectNetworkProps, ref: Ref<NetworkFieldRef>) => {
        const { isNetworkSetting, walletConnected } = useWallet();
        const { networkValue, handleNetworkValueChange, networkOptions } =
            useNetworkAndToken();
        const [disabled, setDisabled] = useState<boolean>(false);
        const { getNetworkById } = useAvailableNetworks();

        const networkRef = useRef<HTMLSelectElement>(null);
        useImperativeHandle(ref, () => ({ value: networkValue }));

        const handleNetworkChange = useCallback(
            ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
                setDisabled(true);
                handleNetworkValueChange(value).finally(() =>
                    setDisabled(false)
                );
            },
            [handleNetworkValueChange]
        );

        const areNetworksAvailable = networkOptions.some(
            ({ disabled }) => !disabled
        );

        const chainName =
            walletConnected?.chain && Blockchain[walletConnected.chain];

        const network = getNetworkById(networkValue);

        // [ ] This functionality should be unified with the other instances of "Switch to an available network"
        const placeholder = !networkValue
            ? areNetworksAvailable
                ? {
                      value: ``,
                      label: `Switch to an available network`,
                  }
                : chainName
                  ? {
                        value: ``,
                        label: `Switch to compatible wallet`,
                    }
                  : !walletConnected
                    ? {
                          value: ``,
                          label: `Connect a wallet`,
                      }
                    : undefined
            : undefined;

        return (
            <Field {...props}>
                <Label htmlFor="networkSelect">Network</Label>
                <SelectWithIcon
                    selectProps={{
                        innerRef: networkRef,
                        name: "networkSelect",
                        className: "fs-unmask",
                        value: networkValue || ``,
                        onChange: handleNetworkChange,
                        placeholder: placeholder,
                        options: networkOptions,
                        disabled:
                            disabled || isNetworkSetting || !walletConnected,
                    }}
                    icon={
                        network
                            ? {
                                  src: network.icon,
                                  alt: `${network.label} logo`,
                              }
                            : undefined
                    }
                />
            </Field>
        );
    }
);

export default SelectNetwork;
