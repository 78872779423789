import {
    SortableCompanyTransactionColumns,
    TransactionTableRow,
    TransactionValues,
} from "company/types";
import { useEffect, useState } from "react";
import { useTransactionFormat } from "./useTransactionFormat";
import { rowsToTableRecords } from "utils/tables";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { getAmountInCentsFromStringNumber } from "utils/numbers";
import {
    TransactionFilters,
    TransactionType,
} from "company/routes/Transactions/types";

interface TransactionTableProps {
    columns: TransactionValues[];
    type?: TransactionType;
    filters?: TransactionFilters;
    sort: SortBy;
    page: number;
    perPage: number;
}

export const useTransactionTable = ({
    columns,
    type,
    filters = {},
    sort,
    page,
    perPage,
}: TransactionTableProps) => {
    const [headings, setHeadings] = useState<RowHeading<TransactionTableRow>[]>(
        []
    );
    const [records, setRecords] = useState<Tuple[]>([]);

    const {
        config: { entities },
        getCompanyConfigIsLoading,
        getCompanyConfigIsError,
        getCompanyConfigIsFetching,
    } = useGetCompanyConfig();

    const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
        useGetTokensMetadata();

    const {
        networks,
        getNetworksIsLoading,
        getNetworksIsError,
        getNetworksIsFetching,
    } = useGetNetworks();

    if (filters?.search) {
        const amount = getAmountInCentsFromStringNumber(filters.search);
        if (amount) {
            filters.amount = amount;
            filters.search = undefined;
        }
    }

    const {
        getCompanyTransfersIsLoading,
        getCompanyTransfersIsError,
        getCompanyTransfersIsFetching,
        getCompanyTransfersRefetch,
        getCompanyTransfersDataUpdatedAt,
        transactions,
        totalResults,
    } = useGetCompanyTransfers({
        type,
        filters,
        sortBy:
            sort?.column !== undefined
                ? (TransactionValues[
                      columns[sort.column]
                  ] as SortableCompanyTransactionColumns)
                : undefined,
        sortDir: sort?.isAscending ? "asc" : "desc",
        page: page - 1,
        limit: perPage,
    });

    const { getTransactionsTableHeadings, getFormatTransactions } =
        useTransactionFormat({ entities, tokens, networks });

    const isLoading =
        getTokensMetadataIsLoading ||
        getCompanyConfigIsLoading ||
        getNetworksIsLoading ||
        getCompanyTransfersIsLoading;

    useEffect(() => {
        if (isLoading) return;

        const colHeadings = getTransactionsTableHeadings(columns);
        setHeadings(colHeadings);

        const txRows = getFormatTransactions(transactions);
        setRecords(rowsToTableRecords(txRows, colHeadings));
    }, [
        getTransactionsTableHeadings,
        getFormatTransactions,
        columns,
        transactions,
        isLoading,
    ]);

    const isError =
        getTokensMetadataIsError ||
        getCompanyConfigIsError ||
        getNetworksIsError ||
        getCompanyTransfersIsError;

    const isFetching =
        getCompanyTransfersIsFetching ||
        getCompanyConfigIsFetching ||
        getNetworksIsFetching;

    return {
        totalResults,
        headings,
        records,
        transactionsIsLoading: isLoading,
        transactionsIsError: isError,
        transactionsIsFetching: isFetching,
        transactionsRefetch: getCompanyTransfersRefetch,
        transactionsDataUpdatedAt: getCompanyTransfersDataUpdatedAt,
    };
};
