import * as S from "./style";
import safeLogo from "assets/img/logos/safe-wallet.svg";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Tooltip from "components/Tooltip";
import Anchor from "components/Anchor";
import Badge from "components/Badge";
import { ButtonProps, ButtonSizes } from "components/Button";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import InfoToolTip from "components/InfoToolTip";
import Pencil from "components/icons/Pencil";
import X from "components/icons/X";
import colors from "theme/colors";
import { useWallet } from "context/Wallet";
import useSafesConnected from "hooks/useSafesConnected";
import { Density } from "types/common-enums";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import {
    ConnectedWallet,
    PrimaryWalletAccount,
} from "context/Wallet/hooks/useWalletManagement";

interface WalletConnectPanelProps extends Omit<ButtonProps, "children"> {
    children?: ReactNode;
    editWalletExternally?: boolean;
    safesEnabled?: boolean;
    disabled?: boolean;
}

const filterOutConnectedWallet = (
    wallets: ConnectedWallet[],
    walletConnected: PrimaryWalletAccount | null
) => {
    if (!walletConnected) return wallets;

    return wallets
        .map((wallet) => ({
            ...wallet,
            addresses: wallet.addresses?.filter(
                ({ address }) => address !== walletConnected?.address
            ),
        }))
        .filter((wallet) => wallet.addresses.length > 0);
};

const WalletConnectPanel = ({
    children = "Connect",
    editWalletExternally = false,
    safesEnabled = true,
    disabled = false,
    ...props
}: WalletConnectPanelProps) => {
    const {
        handleConnectWallet,
        handleUserLogout,
        isWalletConnecting,
        walletConnected,
        networkConnected,
        setProxyWallet,
        walletsAvailable,
        setPrimaryWallet,
        requiresDynamicLogin,
    } = useWallet();

    const [otherWallets, setOtherWallets] = useState<ConnectedWallet[]>(
        filterOutConnectedWallet(walletsAvailable, walletConnected)
    );

    const { safesConnected } = useSafesConnected();
    const isSafeAvailable =
        safesEnabled && !!safesConnected && walletConnected?.isConnected;

    const handleConnectClick = () => {
        handleConnectWallet();
    };

    const handleEditClick = () => {
        if (editWalletExternally) {
            // Wallet switcher lives at checkout's root url
            window.open(import.meta.env.VITE_CHECKOUT_URL);
        } else {
            handleConnectWallet();
        }
    };

    const handleDisconnectClick = () => {
        handleUserLogout();
    };

    const handleClickEoaWallet = () => {
        if (!walletConnected?.proxyFor) return;
        setProxyWallet(null);
    };

    const handleClickSafeWallet = (safeWallet: string) => {
        setProxyWallet(
            walletConnected?.proxyFor?.toLowerCase() ===
                safeWallet?.toLowerCase()
                ? null
                : safeWallet
        );
    };

    const handleClickEoaUnproxy = () => {
        setProxyWallet(null);
    };

    const handleConnectWalletAccount = useCallback(async () => {
        if (!walletConnected?.connect) return;
        await walletConnected.connect();
    }, [walletConnected]);

    useEffect(() => {
        setOtherWallets(
            filterOutConnectedWallet(walletsAvailable, walletConnected)
        );
    }, [walletsAvailable, walletConnected]);

    return !walletConnected ? (
        <S.ConnectWallet
            {...props}
            type="button"
            title="Connect Wallet"
            onClick={handleConnectClick}
            loading={isWalletConnecting}
            disabled={disabled}
        >
            {isWalletConnecting ? "Connecting..." : children}
        </S.ConnectWallet>
    ) : (
        <S.WalletConnectPanel
            {...props}
            disabled={disabled}
            isWalletUsedAsLogin={requiresDynamicLogin}
        >
            <S.Connected>Connected:</S.Connected>
            {walletConnected?.icon && (
                <Tooltip title={walletConnected.label} placement="top">
                    <S.WalletIcon
                        src={walletConnected.icon}
                        alt={`${walletConnected.label} logo`}
                        title={walletConnected.label}
                    />
                </Tooltip>
            )}
            <S.MainAddress>
                <DynamicAddressDisplay
                    address={walletConnected.address}
                    networkId={networkConnected?.networkId}
                    monospace
                    shorten
                    placement="top"
                    anchor={false}
                    underlined={false}
                    style={
                        isSafeAvailable && !walletConnected.proxyFor
                            ? { color: colors.primary }
                            : !walletConnected?.isConnected
                              ? { color: colors.neutralDark }
                              : undefined
                    }
                    onClick={
                        !walletConnected?.isConnected &&
                        walletConnected?.connect
                            ? handleConnectWalletAccount
                            : walletConnected.proxyFor
                              ? handleClickEoaWallet
                              : undefined
                    }
                />
                {isSafeAvailable && !walletConnected.proxyFor && (
                    <Badge variant="purple" density={Density.Compact}>
                        active
                    </Badge>
                )}
                {walletConnected?.connect ? (
                    <Tooltip
                        title="This address is not connected to the site. Connect from your wallet application."
                        placement="top"
                    >
                        <S.ConnectDisconnectedWallet
                            size={ButtonSizes.Small}
                            onClick={handleConnectWalletAccount}
                        >
                            Connect {walletConnected.label}
                        </S.ConnectDisconnectedWallet>
                    </Tooltip>
                ) : (
                    !walletConnected?.isConnected && (
                        <Tooltip
                            title="This address is not connected to the site. Connect from your wallet application."
                            placement="top"
                        >
                            <S.Disconnected
                                variant="gray"
                                density={Density.Compact}
                                onClick={handleConnectWalletAccount}
                            >
                                disconnected
                            </S.Disconnected>
                        </Tooltip>
                    )
                )}
                {isSafeAvailable && walletConnected.proxyFor && (
                    <S.Proxying
                        variant="orange"
                        density={Density.Compact}
                        onClick={!disabled ? handleClickEoaUnproxy : undefined}
                    >
                        proxy
                    </S.Proxying>
                )}
            </S.MainAddress>
            <S.EditWallet>
                <Tooltip
                    title={
                        requiresDynamicLogin
                            ? `Manage your linked wallets`
                            : `Manage the connected wallet`
                    }
                    placement="top"
                    disabled={disabled}
                >
                    <S.ActionBtn
                        type="button"
                        noStyles
                        onClick={handleEditClick}
                        disabled={disabled}
                    >
                        <Pencil height="1em" width="1em" fill="inherit" />
                    </S.ActionBtn>
                </Tooltip>
            </S.EditWallet>
            {!requiresDynamicLogin && (
                <S.DisconnectWallet>
                    <Tooltip
                        title="Disconnect wallet"
                        placement="top"
                        disabled={disabled}
                    >
                        <S.ActionBtn
                            type="button"
                            noStyles
                            onClick={handleDisconnectClick}
                            disabled={disabled}
                        >
                            <X height="1rem" width="1rem" fill="inherit" />
                        </S.ActionBtn>
                    </Tooltip>
                </S.DisconnectWallet>
            )}
            {isSafeAvailable && (
                <S.SafeWallets>
                    <h3>
                        <Anchor
                            href="https://safe.global/"
                            underlined={false}
                            inheritColor={true}
                        >
                            <S.SafeLogo src={safeLogo} alt="Safe (wallet)" />
                            Safe
                        </Anchor>{" "}
                        wallets available{" "}
                        <InfoToolTip title="Your EOA wallet can act as a proxy for any Safe wallet it represents. Click a Safe address to activate it. Click again to switch back to your EOA wallet." />
                    </h3>
                    <S.Wallets>
                        {safesConnected?.map(({ address: safeAddress }) => (
                            <S.Address
                                as={"li"}
                                key={safeAddress}
                                data-proxying={
                                    walletConnected.proxyFor?.toLowerCase() ===
                                    safeAddress.toLowerCase()
                                }
                                onClick={
                                    !disabled
                                        ? () =>
                                              handleClickSafeWallet(safeAddress)
                                        : undefined
                                }
                                tabIndex="0"
                            >
                                <DynamicAddressDisplay
                                    shorten
                                    address={safeAddress}
                                    networkId={networkConnected?.networkId}
                                    anchor={false}
                                    placement="right"
                                />
                                {walletConnected.proxyFor?.toLowerCase() ===
                                    safeAddress.toLowerCase() && (
                                    <>
                                        {` `}
                                        <Badge
                                            variant="purple"
                                            density={Density.Compact}
                                        >
                                            active
                                        </Badge>
                                    </>
                                )}
                            </S.Address>
                        ))}
                    </S.Wallets>
                </S.SafeWallets>
            )}

            {otherWallets.length > 0 && (
                <S.OtherWallets>
                    <Title level="h3" size="h4" spacing={[Spacing.xxs]}>
                        Use another wallet
                    </Title>
                    <S.Wallets>
                        {otherWallets.map((wallet) => (
                            <S.WalletBrand key={wallet.walletId}>
                                <Title
                                    level="h4"
                                    size="h5"
                                    spacing={[Spacing.xxs, Spacing.none]}
                                >
                                    {wallet.icon && (
                                        <S.WalletIconSm
                                            src={wallet.icon}
                                            alt={`${wallet.label} logo`}
                                            title={wallet.label}
                                        />
                                    )}
                                    {wallet.label}
                                </Title>
                                <ul>
                                    {wallet.addresses.map(({ id, address }) => (
                                        <S.OtherAddress
                                            as={"li"}
                                            key={address}
                                            onClick={() => setPrimaryWallet(id)}
                                        >
                                            <DynamicAddressDisplay
                                                shorten
                                                address={address}
                                                anchor={false}
                                                placement="right"
                                            />
                                            {}
                                        </S.OtherAddress>
                                    ))}
                                </ul>
                            </S.WalletBrand>
                        ))}
                    </S.Wallets>
                </S.OtherWallets>
            )}
        </S.WalletConnectPanel>
    );
};

export default WalletConnectPanel;
