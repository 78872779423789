import { ReactNode, createContext, useContext, useMemo } from "react";
import { AvailableNetwork } from "default-variables";
import { properties } from "global-style";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicUserProfile } from "@dynamic-labs/sdk-react-core";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import WalletModal from "components/WalletModal";
import withDynamicContext from "context/Wallet/hoc/withDynamicContext";
import useWalletBalance, {
    GetUpdatedTokenBalanceProps,
    TokenBalanceProps,
} from "context/Wallet/hooks/useWalletBalance";
import useWalletManagement, {
    ConnectedWallet,
    PrimaryWalletAccount,
} from "context/Wallet/hooks/useWalletManagement";
import useWalletNetwork, {
    SetConnectedNetworkProps,
} from "context/Wallet/hooks/useWalletNetwork";
import useWalletSignature, {
    WalletSignerToken,
} from "context/Wallet/hooks/useWalletSignature";
import useWalletEmail from "context/Wallet/hooks/useWalletEmail";
import useWalletAllowance, {
    GetTokenAllowanceProps,
    SetTokenAllowanceProps,
    TokenAllowanceProps,
} from "context/Wallet/hooks/useWalletAllowance";
import useWalletSafes, {
    SafeWallet,
} from "context/Wallet/hooks/useWalletSafes";
import useWalletLoginLogout from "context/Wallet/hooks/useWalletLoginLogout";
import useWalletTransaction, {
    SendTokenPaymentProps,
    WalletTransactionData,
} from "context/Wallet/hooks/useWalletTransaction";
import { withSingletonSync } from "./hoc/withSingletonSync";
import { WalletChangeEvent, NetworkChangeEvent } from "connect/src/types";
import useWalletLoopConnect from "./hooks/useWalletLoopConnect";

export interface LocalhostWalletEmail {
    wallet: string;
    email: string;
}

export interface WalletProviderValue {
    walletConnected: PrimaryWalletAccount | null;
    networkConnected: AvailableNetwork | null;
    walletsAvailable: ConnectedWallet[];
    safeWallet: Promise<SafeWallet | null>;
    isWalletModalOpen: boolean;
    isWalletConnecting: boolean;
    requiresDynamicLogin: boolean;
    hasUserBeenVerified: boolean;
    isUserLoggedIn: boolean;
    walletSessionToken: string | null;
    isWalletConnected: boolean;
    isNetworkSetting: boolean;
    handleConnectWallet: () => void;
    handleUserLogout: () => Promise<void>;
    setPrimaryWallet: (wallet: string) => void;
    setProxyWallet: (proxyAddr: string | null) => void;
    setConnectedNetwork: (
        options: SetConnectedNetworkProps
    ) => Promise<boolean>;
    hasTokenBalanceStored: (options: TokenBalanceProps) => boolean;
    getTokenBalance: (options?: GetUpdatedTokenBalanceProps) => Promise<string>;
    hasTokenAllowanceStored: (options: TokenAllowanceProps) => boolean;
    getTokenAllowance: (options: GetTokenAllowanceProps) => Promise<bigint>;
    setTokenAllowance: (
        options: SetTokenAllowanceProps
    ) => Promise<bigint | null>;
    setTokenAllowanceIfInsufficient: (
        options: SetTokenAllowanceProps
    ) => Promise<bigint | null | false>;
    addToTokenAllowance: (
        options: SetTokenAllowanceProps
    ) => Promise<bigint | null>;
    setWalletEmail: (email: string) => void;
    getWalletEmail: () => string | null;
    getSignedMessage: () => Promise<WalletSignerToken | false>;
    generateTransferSignatureToken: (props: any) => Promise<any>;
    verifySignedMessage: (signature: string) => boolean | undefined;
    sendTokenPayment: (
        params: SendTokenPaymentProps
    ) => Promise<WalletTransactionData>;
    confirmTransaction: (params: WalletTransactionData) => Promise<boolean>;
}

const WalletContext = createContext<WalletProviderValue | undefined>(undefined);

export interface WalletProviderProps {
    children?: ReactNode;
    requiresDynamicLogin?: boolean;
    environmentId?: string;
    onProviderMount?: (provider: WalletProviderValue) => void;

    // [ ] If provided, only then are the two event handlers available to pass in
    WalletContextSingleton?: {
        // [ ] Define this type elsewhere
        getProviderValue: () => WalletProviderValue | null;
        setProviderValue: (value: WalletProviderValue) => void;
        subscribeToProvider: (
            callback: (value: WalletProviderValue) => void
        ) => () => void;
    };
    onWalletChange?: (detail: WalletChangeEvent) => void;
    onNetworkChange?: (detail: NetworkChangeEvent) => void;
}

const WalletProvider = ({
    children,
    requiresDynamicLogin = false,
    WalletContextSingleton,
    onWalletChange,
    onNetworkChange,
}: WalletProviderProps) => {
    const {
        isWalletConnecting,
        isWalletConnected,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    } = useWalletManagement(requiresDynamicLogin);

    const { network, isNetworkSetting, setConnectedNetwork } = useWalletNetwork(
        wallet?.address
    );

    const {
        hasUserBeenVerified,
        isUserLoggedIn,
        isWalletModalOpen,
        setIsWalletModalOpen,
        willUseDynamicUserProfilePopup,
        walletSessionToken,
        handleConnectWallet,
        handleUserLogout,
    } = useWalletLoginLogout(requiresDynamicLogin, isWalletConnected, wallet);

    const { getTokenBalance, hasTokenBalanceStored } = useWalletBalance(
        wallet,
        network
    );
    const { setWalletEmail, getWalletEmail } = useWalletEmail(wallet);

    const {
        generateTransferSignatureToken, // [ ] No longer supported, consider removal
        getSignedMessage,
        verifySignedMessage,
    } = useWalletSignature(wallet, network);

    const {
        hasTokenAllowanceStored,
        getTokenAllowance,
        setTokenAllowance,
        addToTokenAllowance,
        setTokenAllowanceIfInsufficient,
    } = useWalletAllowance(wallet, network);

    const { safe } = useWalletSafes(wallet, network);
    const { sendTokenPayment, confirmTransaction } = useWalletTransaction(
        wallet,
        safe
    );

    useWalletLoopConnect({
        wallet,
        network,
        onWalletChange,
        onNetworkChange,
    });

    // [ ] Base value continues to update on each render, again, something is wrong here
    const value = useMemo<WalletProviderValue>(
        () => ({
            walletConnected: wallet,
            networkConnected: network,
            walletsAvailable,
            safeWallet: safe,
            isWalletModalOpen,
            isWalletConnecting,
            isWalletConnected,
            requiresDynamicLogin,
            hasUserBeenVerified,
            isUserLoggedIn,
            walletSessionToken,
            isNetworkSetting,
            handleConnectWallet,
            handleUserLogout,
            setPrimaryWallet,
            setProxyWallet,
            setConnectedNetwork,
            hasTokenBalanceStored,
            getTokenBalance,
            hasTokenAllowanceStored,
            getTokenAllowance,
            setTokenAllowance,
            setTokenAllowanceIfInsufficient,
            addToTokenAllowance,
            setWalletEmail,
            getWalletEmail,
            getSignedMessage,
            generateTransferSignatureToken,
            verifySignedMessage,
            sendTokenPayment,
            confirmTransaction,
        }),
        [
            wallet,
            network,
            safe,
            walletsAvailable,
            isWalletModalOpen,
            isWalletConnecting,
            isWalletConnected,
            requiresDynamicLogin,
            hasUserBeenVerified,
            isUserLoggedIn,
            walletSessionToken,
            isNetworkSetting,
            handleConnectWallet,
            handleUserLogout,
            setPrimaryWallet,
            setProxyWallet,
            setConnectedNetwork,
            hasTokenBalanceStored,
            getTokenBalance,
            hasTokenAllowanceStored,
            getTokenAllowance,
            setTokenAllowance,
            setTokenAllowanceIfInsufficient,
            addToTokenAllowance,
            setWalletEmail,
            getWalletEmail,
            getSignedMessage,
            generateTransferSignatureToken,
            verifySignedMessage,
            sendTokenPayment,
            confirmTransaction,
        ]
    );

    const Provider = useMemo(() => {
        if (!WalletContextSingleton) {
            return WalletContext.Provider;
        }

        const WrappedProvider = withSingletonSync(WalletContext.Provider);
        return ({
            value,
            children,
        }: {
            value: WalletProviderValue;
            children: ReactNode;
        }) => (
            <WrappedProvider
                value={value}
                WalletContextSingleton={WalletContextSingleton}
            >
                {children}
            </WrappedProvider>
        );
    }, [WalletContextSingleton]);

    return (
        <Provider value={value}>
            {children}
            {!willUseDynamicUserProfilePopup ? (
                <WalletModal
                    onStateChange={setIsWalletModalOpen}
                    isWalletConnecting={isWalletConnecting}
                    isWalletConnected={isWalletConnected}
                />
            ) : (
                <DynamicUserProfile variant="modal" />
            )}
        </Provider>
    );
};

const WalletContextProvider = withDynamicContext<WalletProviderProps>(
    WalletProvider,
    ({ environmentId = import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID }) => ({
        environmentId,
        walletConnectors: [EthereumWalletConnectors, SolanaWalletConnectors],
        networkValidationMode: "never",
        enableVisitTrackingOnConnectOnly: true,
        privacyPolicyUrl: "https://loopcrypto.xyz/privacy-policy",
        termsOfServiceUrl: "https://loopcrypto.xyz/terms-of-service",
        onboardingImageUrl:
            "https://loop-entity-logos.s3.us-east-2.amazonaws.com/loop-crypto-long.svg",
        cssOverrides: `
            .collect-user-data__main-img {
                width: 80%;
                margin: 1rem auto;
            }
            .dynamic-footer {
                display: none;
            }
            .mfa-verification-view__choose-another-method {
                margin-bottom: 0;
                padding-block: 1rem;
            }
            .modal-card, .dynamic-widget-card, .dynamic-widget-modal {
                border-radius: ${properties.radiusXl};
                width: var(--dynamic-modal-width);
                max-width: 100%;
            }
            .footer-options-switcher__container {
                border-bottom-left-radius: ${properties.radiusXl};
                border-bottom-right-radius: ${properties.radiusXl};
            }
            .send-balance-widget-view {
                max-width: 100%;
            }
    
            /* Change "Login or Signup" */
            [copykey="dyn_wallet_list.title.connect"], [copykey="dyn_login.title.all"] {
                visibility: hidden;
                position: relative;
            }
            [copykey="dyn_wallet_list.title.connect"]::after, [copykey="dyn_login.title.all"]::after {
                content: "Log in";
                visibility: visible;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                white-space: nowrap;
            }
    
            /* Can remove this if display: none remains */
            [data-testid="sandbox-indicator"]:has(+ .modal-card > .loop-dynamic-embedded-login) {
                border-top-left-radius: var(--dynamic-border-radius) !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: var(--dynamic-border-radius) !important;
                left: 0 !important;
            }
            [data-testid="sandbox-indicator"] {
                display: none;
            }
        `,
        walletsFilter: (wallets) =>
            wallets.filter(
                (wallet) =>
                    ![
                        `safe`,
                        `walletconnect`,
                        `ledger`,
                        `phantomledger`,
                    ].includes(wallet.key)
            ),
    })
);

export const useWallet = (): WalletProviderValue => {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error(`useWallet() must be used within a WalletProvider`);
    }
    return context;
};

export default WalletContextProvider;
