import * as S from "./style";
import { useState, useRef, FunctionComponent, FormEvent, useMemo } from "react";
import {
    CompanyFrequency,
    Company,
    UpdateItemRequest,
    ItemSourceType,
} from "company/types";
import { ItemType } from "types/common-enums";
import { updatedCompanyItems } from "api";
import { useUser } from "context/User";
import Form from "components/Form";
import Button from "components/Button";
import { useNotificationQueue } from "context/NotificationQueue";
import Notification, {
    NotificationContext,
    NotificationType,
} from "components/Notification";
// Item Form specific components
import ItemNameField, {
    ItemNameFieldRef,
} from "company/components/items/ItemNameField";
import ItemPriceMetadataField, {
    ItemPriceMetadataFieldRef,
} from "company/components/items/ItemPriceMetadataField";
import ItemAcceptedTokensField, {
    ItemAcceptedTokensFieldRef,
} from "company/components/items/ItemAcceptedTokensField";
import ItemAmountField, {
    ItemAmountFieldRef,
} from "company/components/items/ItemAmountField";

import ItemInitialOffsetField, {
    ItemInitialOffsetFieldRef,
} from "../ItemInitialOffsetField";
import ItemFrequencyField, {
    ItemFrequencyFieldRef,
} from "company/components/items/ItemFrequencyField";
import {
    canEditItemAcceptedTokensByNetwork,
    canEditItemAmount,
    canEditItemFrequency,
    canEditItemItemName,
    canEditItemPriceMetadata,
    itemIsSubscription,
    canEditItemInitialOffset,
    ItemNetwork,
    ItemNetworkDisabledReason,
    itemIsOneTimePayment,
} from "company/utils/items";
import ItemSource from "company/components/items/ItemSource";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import Anchor from "components/Anchor";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { getInboundTreasuryAddressByNetworkId } from "company/utils/entities";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { getItemPriceType, isPricedInToken } from "utils/items";
import { useAcceptedTokensByNetwork } from "company/hooks/useAcceptedTokensByNetwork";

interface EditItemFormProps {
    item: Company.Item;
    onSuccess?: () => void;
}

const EditItemForm: FunctionComponent<EditItemFormProps> = ({
    item,
    onSuccess,
}) => {
    const { getCompanyItemsRefetch } = useGetCompanyItems();

    const { networks } = useGetNetworks();
    const {
        config: { entities, contracts },
    } = useGetCompanyConfig();
    const { acceptedTokensByNetwork } = useAcceptedTokensByNetwork();
    const { tokens } = useGetTokensMetadata();

    const entity = entities.find((entity) => entity.entityId === item.entityId);
    const inboundTreasuries = getInboundTreasuryAddressByNetworkId(entity);

    const { addNotification } = useNotificationQueue();
    const [loading, setLoading] = useState<boolean>(false);

    const [fixedPrice, _setFixedPrice] = useState<boolean>(item.amount !== 0);
    const [autoInvoice, _setAutoInvoice] = useState<
        Company.Item["autoInvoice"]
    >(item.autoInvoice);
    const { getEntityId, getSessionToken } = useUser();

    // Different UX / payload (frequency)
    const isSubscription = itemIsSubscription(item);
    const isOneTimePayment = itemIsOneTimePayment(item);

    // Stripe
    const isLoop = item.sourceId === ItemSourceType.Loop;

    // We always show receiving wallet for non-Loop items
    const showReceivingWallet = autoInvoice || !isLoop;

    // Original state
    const isAutoInvoice = item.autoInvoice;

    const { agreements } = useGetCompanyAgreements({
        itemIds: item.id,
    });

    const itemNetworks: ItemNetwork[] = useMemo(() => {
        return networks
            .filter((network) => network.active)
            .map((network) => {
                const isSupported = contracts.some(
                    (c) => c.networkId === network.id
                );

                const acceptedTokensAddresses =
                    acceptedTokensByNetwork[network.id];
                const acceptedTokens = tokens.filter(
                    (token) =>
                        acceptedTokensAddresses &&
                        acceptedTokensAddresses.includes(token.address) &&
                        token.networkId === network.id
                );

                const selectedTokensAddresses =
                    item.acceptedTokensByNetwork[network.id];
                const selectedTokens = tokens.filter(
                    (token) =>
                        selectedTokensAddresses &&
                        selectedTokensAddresses.includes(token.address) &&
                        token.networkId === network.id
                );

                return {
                    network: network,
                    acceptedTokens: acceptedTokens,
                    selectedTokens: selectedTokens,
                    inboundTreasuryAddress: inboundTreasuries[network.id],
                    showInboundTreasuryAddress: showReceivingWallet,
                    disabled: !isSupported,
                    disabledReason: isSupported
                        ? undefined
                        : ItemNetworkDisabledReason.MissingContract,
                };
            });
    }, [
        acceptedTokensByNetwork,
        contracts,
        inboundTreasuries,
        item.acceptedTokensByNetwork,
        networks,
        showReceivingWallet,
        tokens,
    ]);

    // Fixed state
    const hasExistingAgreements = agreements?.length > 0;

    // Refs to the different item fields
    const itemNameFieldRef = useRef<ItemNameFieldRef>(null);
    const itemPriceMetadataFieldRef = useRef<ItemPriceMetadataFieldRef>(null);
    const itemAcceptedTokensFieldRef = useRef<ItemAcceptedTokensFieldRef>(null);
    const itemAmountFieldRef = useRef<ItemAmountFieldRef>(null);
    const itemFrequencyFieldRef = useRef<ItemFrequencyFieldRef>(null);
    const itemInitialOffsetFieldRef = useRef<ItemInitialOffsetFieldRef>(null);

    // Submit
    const updateItem = async (event: FormEvent<HTMLFormElement>) => {
        // Get & Validate name
        let name: string = "";
        if (itemNameFieldRef.current) {
            name = itemNameFieldRef.current.name;
            if (!itemNameFieldRef.current.validate()) return;
        }
        if (!name) return;

        // Get priceMetadata
        let priceMetadata: string | undefined = undefined;
        if (itemPriceMetadataFieldRef.current) {
            priceMetadata = itemPriceMetadataFieldRef.current.priceMetadata;
        }

        const typeId: ItemType = isOneTimePayment
            ? ItemType.One_Time
            : ItemType.Subscription; // Default to subscription

        // Get acceptedTokens
        const acceptedTokensByNetwork =
            itemAcceptedTokensFieldRef?.current?.acceptedTokensByNetwork;

        // Get & Validate frequency
        let frequency: CompanyFrequency | undefined = undefined;

        // 0 as default for OTP
        let frequencyCount: number | undefined = 0;

        // Only Subscription can have frequency
        if (isSubscription && itemFrequencyFieldRef.current) {
            frequency = itemFrequencyFieldRef.current.frequency;
            frequencyCount = itemFrequencyFieldRef.current.frequencyCount;
            if (!itemFrequencyFieldRef.current.validate()) return;

            if (!frequency) return;
            if (!frequencyCount) return;
        }

        // Get & Validate payment collection settings

        let initialOffset: number | undefined = undefined;
        // InitialOffset are only available for fixed amount, autoInvoice
        if (!isOneTimePayment && itemInitialOffsetFieldRef.current) {
            initialOffset = itemInitialOffsetFieldRef.current.initialOffset;
        }

        const updatedItem: UpdateItemRequest = {
            itemId: item.id,
            name,
            priceMetadata,
            typeId,
            externalId: item.externalId ? item.externalId : undefined,
            entityId: item.entityId,
            acceptedTokens: acceptedTokensByNetwork,
            frequency,
            frequencyCount,
            autoInvoice,
            initialOffset,
        };

        // Get & Validate amount
        if (fixedPrice && itemAmountFieldRef.current && item.amount !== null) {
            updatedItem.amount = itemAmountFieldRef.current.amount;
            if (!itemAmountFieldRef.current.validate()) return;
        }

        setLoading(true);

        const { response } = await updatedCompanyItems([updatedItem], {
            Authorization: getSessionToken(),
            "Content-Type": "application/json",
            "entity-id": getEntityId(),
        });

        const successFullResponse = response.ok && response.status === 200;

        if (successFullResponse) {
            await getCompanyItemsRefetch();
            addNotification({
                msg: `"${item.name}" successfully updated`,
                type: NotificationType.SUCCESS,
            });
            if (onSuccess) onSuccess();
        } else {
            addNotification({
                msg: `There was an error while updating the item`,
                type: NotificationType.ERROR,
            });
        }

        setLoading(false);
    };

    return (
        <Form onSubmit={updateItem} preventEnterKeySubmission>
            {/* Source */}
            <ItemSource item={item} />

            {/* Note: For autoInvoice items with existing agreements, we need to warn the user */}
            {isSubscription && isAutoInvoice && hasExistingAgreements && (
                <Notification
                    key={"itemEditAutoInvoice.id"}
                    message={{
                        id: "formErrorDisplay",
                        msg: (
                            <>
                                Changing the amount or frequency of this item
                                will affect the next automatically generated
                                invoice for active customers subscribed to this
                                item.{" "}
                                <Anchor
                                    href={
                                        import.meta.env
                                            .VITE_LOOP_DOCS_AUTO_INVOICE
                                    }
                                >
                                    Learn more
                                </Anchor>
                            </>
                        ),
                        type: NotificationType.INFO,
                        expires: false,
                        context: NotificationContext.STATIC,
                    }}
                    removeNotification={false}
                />
            )}
            {/* Name */}
            <ItemNameField
                disabled={loading || !canEditItemItemName(item)}
                ref={itemNameFieldRef}
                defaultName={item.name}
            />

            {/* Pricing */}

            {/* Price amount & description */}
            <S.PriceSectionWrapper>
                <S.AmountWrapper>
                    <ItemAmountField
                        disabled={loading || !canEditItemAmount(item)}
                        ref={itemAmountFieldRef}
                        priceType={getItemPriceType(item.amount)}
                        defaultAmount={
                            isPricedInToken(item.amount)
                                ? "N/A"
                                : (item.amount / 100).toString()
                        }
                    />
                </S.AmountWrapper>
                <S.PriceMetadataWrapper>
                    <ItemPriceMetadataField
                        disabled={loading || !canEditItemPriceMetadata(item)}
                        defaultPriceMetadata={item.priceMetadata}
                        ref={itemPriceMetadataFieldRef}
                    />
                </S.PriceMetadataWrapper>
            </S.PriceSectionWrapper>

            {/* Subscription-only: Frequency */}
            {isSubscription && (
                <ItemFrequencyField
                    disabled={loading || !canEditItemFrequency(item)}
                    ref={itemFrequencyFieldRef}
                    defaultFrequency={
                        item.frequency?.type.toLocaleUpperCase() as CompanyFrequency
                    }
                    defaultFrequencyCount={item.frequency?.value}
                />
            )}

            {/* Free trials */}
            {!isOneTimePayment && (
                <ItemInitialOffsetField
                    disabled={loading}
                    ref={itemInitialOffsetFieldRef}
                    defaultInitialOffset={item.initialOffset}
                    canEdit={canEditItemInitialOffset(item, autoInvoice)}
                />
            )}

            {/* Accepted tokens */}
            <ItemAcceptedTokensField
                disabled={loading || !canEditItemAcceptedTokensByNetwork(item)}
                ref={itemAcceptedTokensFieldRef}
                itemNetworks={itemNetworks}
            />
            <Button loading={loading}>{loading ? "Saving" : "Save"}</Button>
        </Form>
    );
};

export default EditItemForm;
