import { Navigate, RouterProvider } from "react-router-dom";
import { GlobalStyle } from "global-style";
import { NotificationQueueProvider } from "context/NotificationQueue";
import { ModalProvider } from "context/ModalProvider";
import WalletContextProvider from "context/Wallet";
import LoginDynamic from "components/LoginDynamic";
import AccessControl from "components/AccessControl";
import Dashboard from "components/Dashboard";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import { MenuData, MenuType } from "components/MainMenu";
import EnsProvider from "context/EnsProvider";
import QueryProvider from "context/QueryProvider";
import { useUser, UserRole, UserProvider, LoginType } from "context/User";
import HomeIcon from "components/icons/Home";
import { useMemo } from "react";
import { typedCreateBrowserRouter } from "utils/router";
import HomeDashboard from "admin/components/HomeDashboard";

/* RP: LC-657
    This is a temporary solution to theming our application. Similar temporary
    code exists in other files, search "LC-657" to find other instances */
const theme = `dark`;

const AdminPortal = () => {
    const { isReadOnly } = useUser();

    const menus: MenuData[] = useMemo(
        () => [
            {
                id: `general-menu`,
                type: MenuType.Main,
                path: `/`,
                items: [
                    {
                        path: ``,
                        icon: (
                            <HomeIcon
                                fill="inherit"
                                height="1.25rem"
                                width="1.25rem"
                                name="Home"
                            />
                        ),
                        disableUnlessWithinRoles: [UserRole.ADMIN],
                        label: `Home`,
                    },
                ],
            },
        ],
        []
    );

    const router = useMemo(
        () =>
            typedCreateBrowserRouter(
                [
                    {
                        path: "/login",
                        element: <LoginDynamic />,
                        errorElement: <ErrorMessage>404</ErrorMessage>,
                        children: [
                            {
                                path: "*",
                                element: <Navigate to="/login" replace />,
                            },
                        ],
                    },
                    {
                        path: "/",
                        element: (
                            <ModalProvider>
                                <Dashboard
                                    theme={theme}
                                    menus={menus}
                                    dataLoaders={[]}
                                    readOnly={isReadOnly}
                                    wallet={true}
                                />
                            </ModalProvider>
                        ),
                        errorElement: <ErrorMessage>404</ErrorMessage>,
                        children: [
                            {
                                index: true,
                                handle: {
                                    name: `Home`,
                                    heading: "Home",
                                },
                                element: (
                                    <AccessControl
                                        rolesWithAccess={[UserRole.ADMIN]}
                                    >
                                        <HomeDashboard />
                                    </AccessControl>
                                ),
                            },
                        ],
                    },
                ],
                {
                    future: {
                        v7_normalizeFormMethod: true,
                    },
                }
            ),
        [menus, isReadOnly]
    );

    return <RouterProvider router={router} fallbackElement={<Loading />} />;
};

const App = () => {
    return (
        <QueryProvider>
            <EnsProvider>
                <NotificationQueueProvider>
                    <GlobalStyle />
                    <WalletContextProvider requiresDynamicLogin={true}>
                        <UserProvider loginType={LoginType.WEB2}>
                            <AdminPortal />
                        </UserProvider>
                    </WalletContextProvider>
                </NotificationQueueProvider>
            </EnsProvider>
        </QueryProvider>
    );
};

export default App;
