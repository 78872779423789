import * as S from "./style";
import { Company, TransactionValues } from "company/types";
import Table from "components/Table";
import Button from "components/Button";
import { useCallback, useRef, useMemo } from "react";
import { itemSourceForDisplay, itemSourceForInvoice } from "company/types";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import DollarCoin from "components/icons/Money";
import HomeLink, { HomeLinkProps } from "company/components/HomeLink";
import { useNotificationQueue } from "context/NotificationQueue";
import Section from "components/Section";
import Invoice from "components/icons/Invoice";
import Category from "components/icons/Category";
import Clock from "components/icons/Clock";
import { canInvoiceItem } from "company/utils/items";
import { toSentence } from "utils/strings";
import { NotificationType } from "components/Notification";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { canImportEntityExternalSubscription } from "company/utils/entities";
import { useUser, UserRole } from "context/User";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import SubSection from "components/SubSection";
import Anchor from "components/Anchor";
import EmptyTableMessage from "components/EmptyTableMessage";
import { useTransactionTable } from "company/routes/Transactions/hooks/useTransactionTable";
import {
    TransactionType,
    TransactionFilters,
    defaultTransactionRoute,
} from "company/routes/Transactions/types";
import { getPastAndFutureDateRanges } from "utils/filters";
import { getDateInSeconds } from "utils/dates";

const columns: TransactionValues[] = [
    TransactionValues.dateDue,
    TransactionValues.invoiced,
    TransactionValues.allowanceBalance,
    TransactionValues.sender,
    TransactionValues.receiver,
    TransactionValues.itemName,
    TransactionValues.entity,
    TransactionValues.details,
];
const { future } = getPastAndFutureDateRanges(false);

const dateRangeFilters = {
    options: future.uniques,
    default: future.furthest?.value,
};

const initialFilters: TransactionFilters = {
    date: dateRangeFilters.default
        ? getDateInSeconds(dateRangeFilters.default)
        : undefined,
};

const defaultSort: SortBy = { column: 0, isAscending: true };

const Home = () => {
    const { addNotification, removeNotification } = useNotificationQueue();
    const { getEntityId, hasRole, getSessionToken } = useUser();

    const { headings, records, transactionsIsLoading, transactionsIsError } =
        useTransactionTable({
            columns,
            type: TransactionType.UPCOMING,
            sort: defaultSort,
            filters: initialFilters,
            page: 1,
            perPage: 3,
        });

    const {
        config: { userEntity },
    } = useGetCompanyConfig();

    const { items } = useGetCompanyItems();

    const companyRole = hasRole(UserRole.COMPANY);

    /* Invoice URL */

    // Can the current logged in entity use invoice url
    const sessionEntitySupportsInvoice = useMemo(() => {
        // Get all items that can be invoiced, and their entity id (unique)
        const invoiceItemsUniqueEntityIds = items.reduce(
            (uniqueEntityIds: Company.Item["id"][], item) => {
                if (
                    canInvoiceItem(item) &&
                    !uniqueEntityIds.includes(item.entityId)
                ) {
                    uniqueEntityIds.push(item.entityId);
                }
                return uniqueEntityIds;
            },
            []
        );

        return invoiceItemsUniqueEntityIds.includes(getEntityId());
    }, [items, getEntityId]);

    const invoiceSupportedSourceForDisplay = itemSourceForInvoice.map(
        (itemSource) => itemSourceForDisplay[itemSource]
    );

    // link to checkout url
    const invoiceUrl = `${import.meta.env.VITE_CHECKOUT_URL}/${getEntityId()}`;

    const hasUpcomingPayments = records.length > 0;

    // Notification
    const notificationMessage = useMemo(
        () => (
            <>
                <div>
                    <strong>Invoice link copied!</strong>
                </div>
                <span>{invoiceUrl}</span>
            </>
        ),
        [invoiceUrl]
    );

    // Keep track of notification
    const invoiceCopiedNotifiationId = useRef<string>("");

    // Copy to clipboard & show the notification
    const copyInvoiceUrl = useCallback(() => {
        removeNotification(invoiceCopiedNotifiationId.current);
        invoiceCopiedNotifiationId.current = addNotification({
            msg: notificationMessage,
            type: NotificationType.SUCCESS,
        });

        navigator.clipboard.writeText(invoiceUrl);
    }, [addNotification, invoiceUrl, notificationMessage, removeNotification]);

    const getPaidLinks: HomeLinkProps[] = [
        {
            title: "Create a checkout link for new customers",
            description:
                "Collect payment for a subscription, one-time payment or multiple products",
            icon: <Category />,
            path: "/checkout-url",
        },
        {
            title: `Create a dedicated checkout link for an existing customer`,
            description: "Charge an existing subscription via crypto",
            icon: <DollarCoin />,
            path: "/subscriptions/charge",
            disabled:
                !userEntity ||
                !canImportEntityExternalSubscription(userEntity) ||
                !userEntity.paymentPlatformProvider,
        },
        {
            title: "Your invoice link",
            description: `This link works with invoices made in ${toSentence(
                invoiceSupportedSourceForDisplay
            )}`,
            icon: <Invoice />,
            path: "/",
            onClick: copyInvoiceUrl,
            disabled: !sessionEntitySupportsInvoice,
        },
    ];

    const payLinks: HomeLinkProps[] = [
        {
            title: "Schedule payment",
            description:
                "Schedule a one-time or recurring payment to many wallets",
            icon: <Clock />,
            path: "/pay",
            disabled: !companyRole,
        },
    ];

    return (
        <>
            <Section title="Quick start">
                <SubSection
                    title="Get paid"
                    description="Create a payment link you can send to accept payment in
                        crypto"
                >
                    <S.HomeLinksGrid>
                        {getPaidLinks.map((link, index) => (
                            <li key={index}>
                                <HomeLink {...link} />
                            </li>
                        ))}
                    </S.HomeLinksGrid>
                </SubSection>
                <SubSection
                    title="Pay"
                    description="Schedule payments from your wallet to other wallets"
                >
                    <S.HomeLinksGrid>
                        {payLinks.map((link, index) => (
                            <li key={index}>
                                <HomeLink {...link} />
                            </li>
                        ))}
                    </S.HomeLinksGrid>
                </SubSection>
            </Section>

            {getSessionToken() && (
                <Section title="Upcoming Payments">
                    <SubSection
                        description={
                            !hasUpcomingPayments &&
                            "There are no upcoming payments"
                        }
                    >
                        {transactionsIsLoading && (
                            <LoadingBox
                                placeholderStyle={LoadingPlaceholderStyle.Table}
                                tablePlaceholderRows={3}
                                tablePlaceholderCols={columns.length}
                            />
                        )}
                        {!transactionsIsLoading && transactionsIsError && (
                            <FailedDataFetchingMessage />
                        )}
                        {!transactionsIsLoading && !transactionsIsError && (
                            <>
                                {hasUpcomingPayments && (
                                    <Table
                                        overflowShadow={false}
                                        data={{
                                            headings,
                                            records,
                                        }}
                                        ifNoRecords={
                                            <EmptyTableMessage
                                                title="No upcoming payments to display"
                                                description={
                                                    <>
                                                        Need help? Take a look
                                                        at our{" "}
                                                        <Anchor
                                                            href={
                                                                import.meta.env
                                                                    .VITE_LOOP_DOCS_API_ENABLED_CONTRACT_ADDING_ITEMS
                                                            }
                                                        >
                                                            documentation
                                                        </Anchor>{" "}
                                                        or{" "}
                                                        <Anchor
                                                            href={`mailto:${
                                                                import.meta.env
                                                                    .VITE_EMAIL_SUPPORT
                                                            }`}
                                                        >
                                                            contact us
                                                        </Anchor>
                                                        .
                                                    </>
                                                }
                                            />
                                        }
                                    />
                                )}
                                <Button
                                    href={`/transactions/${defaultTransactionRoute}`}
                                >
                                    View all transactions
                                </Button>
                            </>
                        )}
                    </SubSection>
                </Section>
            )}
        </>
    );
};

export default Home;
