import { useState } from "react";
import {
    Box,
    Link,
    TableRow,
    TableCell,
    Checkbox,
    Button,
    Typography,
} from "@mui/material";
import { TransactionReceipt } from "ethers";
import { useFetch } from "hooks/useFetch";
import { apiServerUrl } from "default-variables";
import EnhancedTable from "components/EnhancedTableDEPRECATED";
import NewItemForm from "admin/components/Forms/NewItemForm";
import NewSubscriptionContractForm from "admin/components/Forms/NewSubscriptionContractForm";
import NewVariableRatesContractForm from "admin/components/Forms/NewVariableRatesContractForm";
import SelectedContract from "admin/components/SelectedContract";
import Copyable from "components/Copyable";
import ContractEntityForm from "admin/components/Forms/ContractEntityForm";
import { Contract, ContractToken } from "admin/types";
import { useModal } from "context/ModalProvider";
import { useWallet } from "context/Wallet";
import { useUser } from "context/User";

type Props = {
    factoryAddr: string; // Address
    factoryId: string;
    walletAddr?: string; // Address
    networkId: string;
    subscriptionContracts: Contract[];
    availableTokens: ContractToken[];
};

type TsxDetailsState = {
    receipt?: TransactionReceipt | null;
    companyName?: string;
    newContractAddr?: string; // Address
    ownerAddr?: string; // Address
    factoryAddr?: string; // Address
    networkId?: string;
    templateType?: number | null;
    inboundTreasury?: string; // Address
    outboundTreasury?: string; // Address
    response?: any;
};

const View = ({
    factoryAddr,
    factoryId,
    walletAddr,
    networkId,
    subscriptionContracts,
    availableTokens,
}: Props) => {
    const [selectedSubContractsMap, setSelectedSubContractsMap] = useState<{
        [address: string]: Contract | undefined;
    }>({});
    const [tsxDetails, setTsxDetails] = useState<TsxDetailsState>({
        receipt: null,
        companyName: ``,
        newContractAddr: ``,
        ownerAddr: ``,
        factoryAddr: ``,
        networkId: ``,
        templateType: null,
        inboundTreasury: ``,
        outboundTreasury: ``,
        response: null,
    });

    const { openModal, closeModal } = useModal();

    const selectedSubContracts = subscriptionContracts.filter(
        (contract: Contract) =>
            !!selectedSubContractsMap[contract.contractAddress]
    );

    const handleNewItemSubmit = ({ receipt: newItemReceipt }: any) => {
        setTsxDetails({ receipt: newItemReceipt });
    };

    const handleNewEntitySubmit = ({ response: newEntityResponse }: any) => {
        setTsxDetails({ response: newEntityResponse });
    };

    const handleNewContractSubmit = ({
        receipt,
        newContractAddr,
        templateType,
        form,
    }: any) => {
        setTsxDetails({
            companyName: templateType === 1 ? form.companyName : "",
            ownerAddr: walletAddr,
            templateType,
            receipt,
            newContractAddr,
            factoryAddr,
            networkId,
            inboundTreasury: templateType === 2 ? form.inboundTreasury : "",
            outboundTreasury: templateType === 2 ? form.outboundTreasury : "",
        });
    };

    const handleCheckFactory = (idx: number) => {
        return () => {
            const contract: Contract = subscriptionContracts[idx];

            setSelectedSubContractsMap({
                ...selectedSubContractsMap,
                [contract.contractAddress]: selectedSubContractsMap[
                    contract.contractAddress
                ]
                    ? undefined
                    : contract,
            });
        };
    };

    return (
        <>
            {tsxDetails.response && (
                <Box mb={4} display="flex" flexDirection="column" gap={2}>
                    <Typography variant="h2">Entity Created!</Typography>
                    <Typography>
                        New User Email: {tsxDetails.response.newUserEmail}
                    </Typography>
                    <Link
                        href={`${
                            import.meta.env.VITE_COMPANY_URL
                        }/login/activate/${
                            tsxDetails.response.verificationHash
                        }?email=${tsxDetails.response.newUserEmail}`}
                        target="_blank"
                        color="inherit"
                    >
                        Activate Account
                    </Link>
                </Box>
            )}
            {tsxDetails.receipt && tsxDetails.receipt.blockHash && (
                <Box mb={4} display="flex" flexDirection="column" gap={2}>
                    <Typography variant="h2">
                        Transaction Successful!
                    </Typography>
                    <Copyable>
                        Transaction Hash: {tsxDetails.receipt.blockHash}
                    </Copyable>
                    {tsxDetails.templateType &&
                        tsxDetails.templateType === 2 && (
                            <>
                                <Typography>
                                    New Contract Address:{" "}
                                    {tsxDetails.newContractAddr}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        openModal(
                                            <ContractEntityForm
                                                networkId={parseInt(networkId)}
                                                contractAddress={
                                                    tsxDetails.newContractAddr!
                                                }
                                                inboundTreasuryAddress={
                                                    tsxDetails.inboundTreasury
                                                }
                                                outboundTreasuryAddress={
                                                    tsxDetails.outboundTreasury
                                                }
                                                contractFactoryId={factoryId}
                                                onSubmit={handleNewEntitySubmit}
                                                closePopover={closeModal}
                                            />,
                                            "Link Entity with Contract"
                                        )
                                    }
                                >
                                    Create Entity and Link Contract
                                </Button>
                            </>
                        )}
                    {tsxDetails.templateType &&
                        tsxDetails.templateType === 1 && (
                            <>
                                <Typography
                                    variant="h3"
                                    sx={{ textTransform: `uppercase` }}
                                >
                                    Paste the values below into the #development
                                    slack channel
                                </Typography>
                                <Copyable>
                                    <Typography>
                                        Company Name: {tsxDetails.companyName}
                                    </Typography>
                                    <Typography>
                                        New Contract Address:{" "}
                                        {tsxDetails.newContractAddr}
                                    </Typography>
                                    <Typography>
                                        Contract Owner Address:{" "}
                                        {tsxDetails.ownerAddr}
                                    </Typography>
                                    <Typography>
                                        Factory Address:{" "}
                                        {tsxDetails.factoryAddr}
                                    </Typography>
                                    <Typography>
                                        Network ID: {tsxDetails.networkId}
                                    </Typography>
                                    <Typography>
                                        Template Type: {tsxDetails.templateType}
                                    </Typography>
                                </Copyable>
                            </>
                        )}
                </Box>
            )}
            <h2>Subscription Contracts</h2>
            <Box mb={2}>
                <Copyable>
                    Factory:{" "}
                    <Link
                        href={`https://etherscan.io/address/${factoryAddr}`}
                        target="_blank"
                        color="inherit"
                    >
                        {factoryAddr}
                    </Link>
                </Copyable>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={1} gap={2}>
                <Button
                    variant="contained"
                    disabled={
                        selectedSubContracts.length > 0 ||
                        !factoryAddr ||
                        !walletAddr
                    }
                    onClick={() => {
                        setTsxDetails({});
                        openModal(
                            <NewVariableRatesContractForm
                                availableTokens={availableTokens}
                                onSubmit={handleNewContractSubmit}
                                closePopover={closeModal}
                                factoryAddr={factoryAddr}
                            />,
                            "New Variable Rate Contract"
                        );
                    }}
                >
                    New VR Contract
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        selectedSubContracts.length > 0 ||
                        !factoryAddr ||
                        !walletAddr
                    }
                    onClick={() => {
                        setTsxDetails({});

                        openModal(
                            <NewSubscriptionContractForm
                                availableTokens={availableTokens}
                                onSubmit={handleNewContractSubmit}
                                closePopover={closeModal}
                                factoryAddr={factoryAddr}
                            />,
                            "New Subscription Contract"
                        );
                    }}
                >
                    Create New Subscription Contract
                </Button>

                <Button
                    variant="contained"
                    disabled={selectedSubContracts.length !== 1}
                    onClick={() => {
                        setTsxDetails({});

                        openModal(
                            <NewItemForm
                                onSubmit={handleNewItemSubmit}
                                closePopover={closeModal}
                                contract={
                                    selectedSubContracts[0].contractAddress
                                }
                            />,
                            "New Subscription Plan"
                        );
                    }}
                >
                    Create New Item
                </Button>
            </Box>
            <Box mb={8}>
                <EnhancedTable
                    headers={[
                        { label: `` },
                        { label: `contract` },
                        { label: `type` },
                        { label: `company name` },
                        { label: `network` },
                        { label: `owner` },
                    ]}
                >
                    {subscriptionContracts.map(
                        (
                            {
                                contractAddress,
                                contractType,
                                owner,
                                entityName,
                                network,
                            }: Contract,
                            idx: number
                        ) => {
                            const isSelected =
                                !!selectedSubContractsMap[contractAddress];
                            return (
                                <TableRow key={contractAddress}>
                                    <TableCell>
                                        <Checkbox
                                            value={isSelected}
                                            onChange={handleCheckFactory(idx)}
                                            disabled={
                                                selectedSubContracts.length >=
                                                    1 && !isSelected
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Copyable>
                                            <Link
                                                href={`https://etherscan.io/address/${contractAddress}`}
                                                target="_blank"
                                                color="inherit"
                                            >
                                                {contractAddress}
                                            </Link>
                                        </Copyable>
                                    </TableCell>
                                    <TableCell>{contractType}</TableCell>
                                    <TableCell>{entityName}</TableCell>
                                    <TableCell>{network}</TableCell>
                                    <TableCell>
                                        <Copyable>{owner}</Copyable>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    )}
                </EnhancedTable>
            </Box>
            <Box display="flex" gap={2}>
                <Box>
                    {selectedSubContracts.length === 1 && (
                        <Box mb={4}>
                            <SelectedContract
                                contract={selectedSubContracts[0]}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

const Home = () => {
    const { networkConnected, walletConnected } = useWallet();
    const { getSessionToken } = useUser();
    const { data, loading }: any = useFetch(
        `${apiServerUrl}/api/v1/manage/contracts`,
        {
            headers: {
                Authorization: getSessionToken(),
            },
        }
    );

    if (loading) {
        return <>Loading...</>;
    }

    const availableTokens = data.availableTokens.filter(
        (token: ContractToken) =>
            token.networkId === networkConnected?.networkId
    );
    const factory = data.factoryMetadata.find(
        (factory: any) => factory.networkId === networkConnected?.networkId
    );

    return (
        <View
            factoryAddr={factory ? factory.address : null}
            factoryId={factory ? factory.id : null}
            walletAddr={walletConnected?.address}
            networkId={networkConnected?.networkId || ""}
            subscriptionContracts={
                data.contractMetadata ? data.contractMetadata : []
            }
            availableTokens={availableTokens}
        />
    );
};

export default Home;
