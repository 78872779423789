import { HeadersObject } from "api/rest";
import { BaseItem } from "types/common";
import { ItemCategoryType, TransferStatus } from "types/common-enums";
import { TransactionType } from "company/routes/Transactions/types";
import { PagingQueryParams, PagingQueryParamsAdjusted } from "api/types";
import { CompanyAgreementResponse } from "api/types/company";

export type TagPayload = {
    id: number;
    content: string;
};

export interface TransactionElement {
    name: string;
    quantity: number;
    amount: string | null;
    discountAmount: number | null;
    type: ItemCategoryType;
}

enum AccessType {
    CreateTransfer = "CreateTransfer",
    GetEntityTransfers = "GetEntityTransfers",
    GetAllTransfers = "GetAllTransfers",
    GetValidTransfers = "GetValidTransfers",
    CreateItems = "CreateItems",
    CreateChildEntities = "CreateChildEntities",
    UpdateItems = "UpdateItems",
    GetItems = "GetItems",
    CreateNewAgreements = "CreateNewAgreements",
    GetAgreements = "GetAgreements",
    CancelAgreements = "CancelAgreements",
    CancelTransfers = "CancelTransfers",
    DeactivateApiKey = "DeactivateApiKey",
    GetEntities = "GetEntities",
}

// Note: The main difference with the backend's PaymentPlatformSource
//       is the case (capitalized first letter), see toGetEntityResponseViewFromDomainModel
export enum PaymentPlatformSource {
    Stripe = "Stripe",
    Chargebee = "Chargebee",
    QuickBooks = "Quickbooks",
    Xero = "Xero",
}

export enum FeeConfigurationType {
    Network = "Network",
    Subscription = "Subscripion",
    Invoice = "Invoice",
    Outbound = "Outbound",
    Platform = "Platform",
}

export enum AmountType {
    Fiat = "Fiat",
    Token = "Token",
    Percent = "Percent",
}

// What we have
export type EntityInboundTreasuries = Record<string, string[]>;

// What we want/what it should be
export type EntityInboundTreasuriesByNetworkId = Record<string, string>;

export interface CompanyEntityFeeResponse {
    type: keyof typeof FeeConfigurationType;
    amountType: keyof typeof AmountType;
    networkId: number;
    amount: number;
}

export declare namespace Company {
    interface Entity extends BaseEntity {
        inboundTreasuries?: EntityInboundTreasuries | null;
        replyTo?: string | null;
        secret?: string | null;
        webhooks?: Webhook[] | null;
        contractVersion: 1 | 2;
        delegatedSigning?: boolean;
        signupRedirectUrl: string | null;
        paymentPlatformProvider?: PaymentPlatformSource;
        externalSite?: string;
        fees: CompanyEntityFeeResponse[];
    }
    interface AcceptedTokensByNetwork {
        [networkId: number]: string[];
    }
    interface Item extends Omit<BaseItem, "amount"> {
        amount: number | null;
        usdStakeAmount: number;
        externalId?: string | null;
        autoInvoice: boolean;
        initialOffset: number;
        sourceId: ItemSourceType;
        acceptedTokensByNetwork: AcceptedTokensByNetwork;
    }

    interface Contract extends BaseContract {}

    interface Transaction {
        agreementId: string;
        transferId: string;
        entityId: string;
        amount: string | null;
        usd: boolean;
        networkId: number;
        token: string;
        sender: Wallet;
        receiver: Wallet;
        billDate: number;
        invoiceId: string | null;
        status: `${TransferStatus}`;
        transactionTypes: TransactionType[];
        payment: Payment | null;
        tags: TagPayload[];
        notes: string | null;
        sourceId: TransferSource;
        externalId: string | null;
        externalReferenceId: string | null;
        elements: TransactionElement[];
    }

    interface ApiKey {
        createdAt: number;
        name: string;
        permissions: AccessType[];
    }

    interface Webhook {
        endpoint: string;
        networkId: number;
        webhookEvent: string;
        contractAddress: string;
    }
}

// ************* Transaction tables *************
export enum TransactionValues {
    id = "id",
    dateDue = "dateDue",
    datePaid = "datePaid",
    draftStatus = "draftStatus",
    invoiced = "invoiced",
    billedInUsd = "billedInUsd",
    invoicedAmt = "invoicedAmt",
    invoicedToken = "invoicedToken",
    received = "received",
    receivedAmt = "receivedAmt",
    txHash = "txHash",
    sender = "sender",
    senderAddress = "senderAddress",
    senderEmail = "senderEmail",
    receiver = "receiver",
    receiverAddress = "receiverAddress",
    receiverEmail = "receiverEmail",
    network = "network",
    networkId = "networkId",
    itemName = "itemName",
    invoice = "invoice",
    entity = "entity",
    status = "status",
    tags = "tags",
    details = "details",
    notes = "notes",
    allowanceBalance = "allowanceBalance",
    transactionTypes = "transactionTypes",
}

export const SortableTransactionColumns = {
    [TransactionValues.dateDue]: TransactionValues.dateDue,
    [TransactionValues.datePaid]: TransactionValues.datePaid,
} as const satisfies Record<string, TransactionValues>;

export type SortableCompanyTransactionColumns =
    (typeof SortableTransactionColumns)[keyof typeof SortableTransactionColumns] &
        TransactionValues;

export type TransactionRow = {
    [key in TransactionValues]: Field;
};

export type TransactionTableRow = TableRow<TransactionRow>;

export enum TransferSource {
    Manual = 1,
    AutoGenerated = 2,
    Stripe = 3,
    Chargebee = 4,
    QuickBooks = 5,
    Xero = 6,
}

// ************* Customer (Agreements) tables *************
export enum CustomerTableColumns {
    id = "id",
    sender = "sender",
    senderAddress = "senderAddress",
    senderEmail = "senderEmail",
    item = "item",
    externalId = "externalId",
    frequency = "frequency",
    network = "network",
    startDate = "startDate",
    token = "token",
    allowanceAndBalance = "allowanceAndBalance",
    manage = "manage",
}

export const SortableCustomerColumns = {
    [CustomerTableColumns.sender]: CustomerTableColumns.sender,
    [CustomerTableColumns.externalId]: CustomerTableColumns.externalId,
    [CustomerTableColumns.startDate]: CustomerTableColumns.startDate,
} as const satisfies Record<string, CustomerTableColumns>;

export type SortableCompanyAgreementColumns =
    (typeof SortableCustomerColumns)[keyof typeof SortableCustomerColumns] &
        CustomerTableColumns;

export type CustomerHeadings = {
    [K in CustomerTableColumns]: Field;
};

// ************* Items data *************
export enum CompanyFrequency {
    Hour = "HOUR",
    Day = "DAY",
    Week = "WEEK",
    Month = "MONTH",
    Year = "YEAR",
}

export enum ItemSourceType {
    Loop = 1,
    Chargebee = 2,
    Stripe = 3,
    QuickBooks = 4,
    Xero = 5,
}

export const itemSourceForDisplay = {
    [ItemSourceType.Loop]: "Loopcrypto",
    [ItemSourceType.Stripe]: "Stripe",
    [ItemSourceType.Chargebee]: "Chargebee",
    [ItemSourceType.QuickBooks]: "QuickBooks",
    [ItemSourceType.Xero]: "Xero",
};

export const itemSourceForCheckout = [
    ItemSourceType.Loop,
    ItemSourceType.Stripe,
    ItemSourceType.Chargebee,
];

export const itemSourceForInvoice = [
    ItemSourceType.Stripe,
    ItemSourceType.QuickBooks,
    ItemSourceType.Xero,
];

export interface AcceptedTokensPayload {
    [networkId: number]: string[];
}

export interface GetCompanyItemsResponse {
    items: Company.Item[];
}

export interface GetCompanyItemsHttpRequest {
    entityId: string;
}

export interface GetCompanyAgreementsHttpRequest
    extends PagingQueryParams<SortableCompanyAgreementColumns> {
    id?: string;
    statuses?: string;
    from?: string;
    itemIds?: string;
    itemTypes?: string;
    networks?: string;
    entities?: string;
}

export interface WalletDetails {
    wallet: string;
    email: string | null;
}

export interface CompanyAgreementsResponse {
    agreements: CompanyAgreementResponse[];
}

export interface GetCompanyTransfersHttpRequest
    extends PagingQueryParamsAdjusted<SortableCompanyTransactionColumns> {
    id?: string;
    type?: TransactionType;
    amount?: string;
    date?: string;
    search?: string;
    entities?: string;
    networks?: string;
}

export interface GetCompanyTransfersResponse {
    totalResults: number;
    transactions: Company.Transaction[];
}

export class GetCompanyConfigHttpRequest {}

export interface GetCompanyConfigResponse {
    entities: Company.Entity[];
    apiKeys: Company.ApiKey[];
    contracts: Company.Contract[];
}

export interface UpdateTransfer {
    /**
     * The transfer ID
     * @example "401bb7e7-a7f4-406d-b66c-ce769fdde7ca"
     */
    transferId: string;
    /**
     * Additional comments from the transfer initiator
     */
    notes?: string;
}

// Non-Delegated Signers, additional attributes will require a signature from the user
export type UpdateTransferNonDelegatedSigner = UpdateTransfer;

// Delegated Signers, attributes don't required a signature from the user (signature is generated
// in the backend)
export type UpdateTransferDelegatedSigner = UpdateTransfer & {
    /**
     * The approximate time at which to process the given transfer request. This needs to be formatted as a Unix timestamp in seconds. If 0, the transfer request will be processed "immediately" (as soon as the Loop bot network picks it up)
     * @example 1664096943
     */
    billDate?: number;
    /**
     * The address receiving the transfer
     * @example "0x0f2672BA12aed17BEe075F7AEabC24b98E3098Ca"
     */
    toAddress?: string;
    /**
     * The amount to transfer, denominated either in USD if field usd is set to true or the given token
     * @example "1000000000"
     */
    amount?: string;
    /**
     * The usd boolean to show wether we show the amount in usd or in token
     * @example "1000000000"
     */
    usd?: boolean;
};

export type PatchTransfersRequest =
    | UpdateTransferNonDelegatedSigner[]
    | UpdateTransferDelegatedSigner[];

export type CompanyAuthorizationHeaders = HeadersObject & {
    Authorization: string;
    "entity-id": string;
};

export enum SplitPaymentAmountType {
    Percent = 1,
    Dollar = 2,
}
export enum PaymentType {
    Transaction = 1,
    Fee = 2,
}

export type BaseTransfer = {
    invoiceId: string;
    amount: string;
    token: string;
    usd: boolean;
    itemId: string;
    status?: TransferStatus;
    networkId?: number;
    source?: TransferSource;
    paymentTypeId?: PaymentType;
    batchId?: string;
    notes?: string | null;
};

export type Transfer = BaseTransfer & {
    transferId: string;
    entityId?: string;
    toAddress: string;
    fromAddress: string;
    billDate?: number;
    signature?: string;
    tagIds?: string[];
    decodedSignature?: { v: number; r: string; s: string };
    failureMessage?: string;
    failureCode?: string | null; // convert this to a code & store in the db
    datePaid?: number | null;
    dateCreated: number;
    transactionHash?: string | null;
    feeAmount: number;
    feePaid: string;
};
export type PatchTransferResponse = {
    transfers: Transfer[];
};

export interface CreateItemRequest {
    name: string;
    amount: number;
    frequency?: string;
    frequencyCount: number;
    acceptedTokens?: AcceptedTokensPayload;
    entityId?: string;
    externalId?: string | null;
    active?: boolean;
    priceMetadata?: string | null;
    typeId: number;
    categoryId?: number;
    autoInvoice?: boolean;
    initialOffset?: number;
}

export interface GeneralItemsResponse {
    items: Company.Item[];
}

export interface UpdateItemRequest extends Partial<CreateItemRequest> {
    itemId: string;
}

/**
 * A response will be a JSON object that represents a list of items, agreements & customer email
 */
export interface GetCompanyExternalSubscriptionResponse {
    agreements: CompanyAgreementResponse[];
    items: Company.Item[];
    email?: string;
    externalSubscriptionId?: string;
}
