import { useEffect, useState } from "react";
import {
    JsonRpcProvider,
    TransactionReceipt,
    TransactionResponse,
} from "ethers";
import { isEvmTxHash, isSolTxHash } from "utils/addresses";
import { asyncCallWithRetries } from "utils/async";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import {
    Connection,
    BlockheightBasedTransactionConfirmationStrategy,
} from "@solana/web3.js";
import { AvailableNetwork } from "default-variables";

enum TxStatus {
    Invalid = "invalid", // Invalid hash or network
    Pending = "pending",
    Confirmed = "confirmed",
    Failed = "failed", // Transaction failed to confirm on chain
    Error = "error", // Fetch not complete (or tx not found)
}

type TransactionStatusReturn = {
    message: string;
    transaction: TransactionResponse | string | null;
} & (
    | {
          status: TxStatus.Confirmed;
          receipt: TransactionReceipt | number;
      }
    | {
          status: Exclude<TxStatus, TxStatus.Confirmed>;
          receipt: TransactionReceipt | number | null;
      }
);

const retryDelays = [2000, 3000, 5000];

const confirmTransactionByHash = async (
    hash: string,
    network: Pick<AvailableNetwork, "chain" | "rpcUrl" | "label">
): Promise<TransactionStatusReturn> => {
    if (!hash || !network) {
        return {
            status: TxStatus.Invalid,
            message: "Invalid transaction hash or network data",
            transaction: null,
            receipt: null,
        };
    }

    try {
        if (network.chain === "SOL") {
            const provider = new Connection(network.rpcUrl, "confirmed");

            try {
                const resp = await provider.confirmTransaction(
                    {
                        signature: hash,
                    } as BlockheightBasedTransactionConfirmationStrategy,
                    "confirmed"
                );

                return {
                    status: !resp.value.err
                        ? TxStatus.Confirmed
                        : TxStatus.Failed,
                    message: !resp.value.err
                        ? "Transaction confirmed on chain"
                        : "Transaction failed on chain",
                    transaction: hash,
                    receipt: resp.context.slot,
                };
            } catch (error) {
                console.error(
                    `${error}: Unable to locate signature ${hash} on ${network.label}`
                );
                return {
                    status: TxStatus.Error,
                    message: `Unable to locate signature ${hash} on ${network.label}`,
                    transaction: null,
                    receipt: null,
                };
            }
        } else {
            const provider = new JsonRpcProvider(network.rpcUrl);

            try {
                const tx = await asyncCallWithRetries(
                    provider.getTransaction,
                    retryDelays,
                    [hash],
                    {
                        error: `Transaction not found`,
                        context: provider,
                    }
                );

                if (!tx) {
                    return {
                        status: TxStatus.Error,
                        message: `Transaction not found on ${network.label}`,
                        transaction: null,
                        receipt: null,
                    };
                }

                try {
                    const receipt = await tx.wait();
                    if (!receipt?.status)
                        return {
                            status: TxStatus.Failed,
                            message: "Transaction failed on chain",
                            transaction: tx,
                            receipt: null,
                        };

                    return {
                        status: TxStatus.Confirmed,
                        message: `Transaction confirmed on chain`,
                        transaction: tx,
                        receipt: receipt,
                    };
                } catch (error) {
                    return {
                        status: TxStatus.Failed,
                        message: `Transaction failed on chain`,
                        transaction: tx,
                        receipt: null,
                    };
                }
            } catch (error) {
                console.error(
                    `${error}: Unable to locate transaction ${hash} on ${network.label} after ${retryDelays.length} attempts.`
                );
                return {
                    status: TxStatus.Error,
                    message: `Unable to locate transaction ${hash} on ${network.label}.`,
                    transaction: null,
                    receipt: null,
                };
            }
        }
    } catch (error) {
        console.error(`Unexpected error confirming transaction: ${error}`);
        return {
            status: TxStatus.Error,
            message: `Unexpected error confirming transaction`,
            transaction: null,
            receipt: null,
        };
    }
};

const useTransactionStatus = (
    hash: string | undefined,
    networkHex: string
): Omit<TransactionStatusReturn, "message"> => {
    const [status, setStatus] = useState<TxStatus>(
        !hash || !isEvmTxHash(hash) || !isSolTxHash(hash) || !networkHex
            ? TxStatus.Invalid
            : TxStatus.Pending
    );
    const [transaction, setTransaction] = useState<any>(null);
    const [receipt, setReceipt] = useState<any>(null);
    const { getNetworkById } = useAvailableNetworks();

    useEffect(() => {
        const network = getNetworkById(networkHex);

        if (!hash || !network) {
            setStatus(TxStatus.Invalid);
            setTransaction(null);
            setReceipt(null);
            return;
        }

        setStatus(TxStatus.Pending);

        // Use the extracted function to confirm the transaction
        let isMounted = true;
        confirmTransactionByHash(hash, network)
            .then((result: TransactionStatusReturn) => {
                if (isMounted) {
                    setStatus(result.status);
                    setTransaction(result.transaction);
                    setReceipt(result.receipt);
                }
            })
            .catch((error) => {
                if (isMounted) {
                    console.error(`Error confirming transaction: ${error}`);
                    setStatus(TxStatus.Error);
                    setTransaction(null);
                    setReceipt(null);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [hash, networkHex, getNetworkById]);

    return { status, transaction, receipt };
};

export { useTransactionStatus, confirmTransactionByHash, TxStatus };
