import {
    useIsLoggedIn,
    getAuthToken,
    useDynamicContext,
} from "@dynamic-labs/sdk-react-core";
import { useCallback, useEffect, useState } from "react";
import { PrimaryWalletAccount } from "./useWalletManagement";

const useWalletLoginLogout = (
    requiresDynamicLogin: boolean,
    isWalletConnected: boolean,
    walletConnected: PrimaryWalletAccount | null
) => {
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

    const {
        setShowDynamicUserProfile,
        setShowAuthFlow,
        showDynamicUserProfile,
        showAuthFlow,
        handleLogOut,
        sdkHasLoaded,
        primaryWallet,

        // [ ] Separate handleLogOut() and hanelDisconnectWallet() functionality
        /* handleDisconnectWallet: (walletId: string) => void  */
    } = useDynamicContext();

    // In "connect-only" mode, connecting a wallet is enough to return true here
    const isLoggedInOrConnected = useIsLoggedIn();

    // Only set to true if the user is logged in AND the login is required
    const isUserLoggedIn = requiresDynamicLogin && isLoggedInOrConnected;

    const hasUserBeenVerified = sdkHasLoaded;

    const walletSessionToken = isUserLoggedIn ? (getAuthToken() ?? null) : null;

    const handleConnectWallet = useCallback(() => {
        return (isWalletConnected && !requiresDynamicLogin) ||
            (isUserLoggedIn && requiresDynamicLogin)
            ? setShowDynamicUserProfile(true)
            : setShowAuthFlow(true);
    }, [
        setShowDynamicUserProfile,
        setShowAuthFlow,
        isWalletConnected,
        isUserLoggedIn,
        requiresDynamicLogin,
    ]);

    const handleUserLogout = useCallback(async () => {
        await handleLogOut();
    }, [handleLogOut]);

    const willUseDynamicUserProfilePopup =
        !requiresDynamicLogin || isUserLoggedIn;

    useEffect(() => {
        if (!willUseDynamicUserProfilePopup) return;
        setIsWalletModalOpen(showDynamicUserProfile || showAuthFlow);
    }, [willUseDynamicUserProfilePopup, showDynamicUserProfile, showAuthFlow]);

    return {
        isUserLoggedIn,
        hasUserBeenVerified,
        isWalletModalOpen,
        setIsWalletModalOpen,
        willUseDynamicUserProfilePopup,
        walletSessionToken,
        handleConnectWallet,
        handleUserLogout,
    };
};

export default useWalletLoginLogout;
