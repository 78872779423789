import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import { forwardRef, Ref, useEffect, useRef, useState } from "react";
import { Company, ItemSourceType } from "company/types";
import { getCheckoutUrlQuery } from "utils/items";
import Label from "components/Label";
import Field from "components/Field";
import Input from "components/Input";
import Copyable from "components/Copyable";
import Checkbox from "components/Checkbox";
import CarretDown from "components/icons/CarretDown";
import ItemsCheckoutSuggestedAllowanceForm, {
    ItemsCheckoutSuggestedAllowanceFormRef,
} from "company/components/items/ItemsCheckoutSuggestedAllowanceForm";
import ItemsCheckoutMinimumRequiredBalanceForm, {
    ItemsCheckoutMinimumRequiredBalanceFormRef,
} from "company/components/items/ItemsCheckoutMinimumRequiredBalanceForm";
import ItemsCheckoutMinimumAllowanceForm, {
    ItemsCheckoutMinimumAllowanceFormRef,
} from "company/components/items/ItemsCheckoutMinimumAllowanceForm";
import SubSection from "components/SubSection";
import ItemSource from "company/components/items/ItemSource";

export interface ItemCheckoutUrlFieldProps {
    disabled?: boolean;
    items: Company.Item[];
    onChange?: (sourceId: ItemSourceType) => void;
    label?: React.ReactNode | false;
    forceEmail?: string;
    forceExternalSubscriptionId?: string;
}

export type ItemCheckoutUrlFieldRef = {};

function ItemCheckoutUrlField(
    {
        disabled = false,
        items,
        label = "Checkout URL",
        forceEmail,
        forceExternalSubscriptionId,
    }: ItemCheckoutUrlFieldProps,
    ref: Ref<ItemCheckoutUrlFieldRef>
) {
    // If they reach this point, all items should have the same entityId
    // [ ] Is this definitely true? What if there is a parent/child item?
    const entityId = items[0].entityId;

    const itemsCheckoutMinimumRequiredBalanceFormRef =
        useRef<ItemsCheckoutMinimumRequiredBalanceFormRef>(null);

    const itemsCheckoutMinimumAllowanceFormRef =
        useRef<ItemsCheckoutMinimumAllowanceFormRef>(null);

    const itemsCheckoutSuggestedAllowanceFormRef =
        useRef<ItemsCheckoutSuggestedAllowanceFormRef>(null);

    const [showAdvancedOptions, setShowAdvancedOptions] =
        useState<boolean>(false);

    // UrlParams
    const [cartEnabled, setCartEnabled] = useState<boolean>(true);
    const [payNow, setPayNow] = useState<boolean>(false);
    const [refId, setRefId] = useState<string | undefined>("");
    const [email, setEmail] = useState<string>(forceEmail || "");
    const [externalSubscriptionId, setExternalSubscriptionId] =
        useState<string>(forceExternalSubscriptionId || "");

    const itemsSource = items[0].sourceId;

    // MINIMUM BALANCE
    const [minimumBalanceRequired, setMinimumBalanceRequired] =
        useState<number>(0);

    // MINIMUM ALLOWANCE
    const [minimumAllowance, setMinimumAllowance] = useState<number>(0);

    // SUGGESTED ALLOWANCE
    const [suggestedAllowance, setSuggestedAllowance] = useState<number>(0);

    // Base URL (/entity)
    const [invoiceUrl, setInvoiceUrl] = useState<string>(``);
    const baseUrl = `${import.meta.env.VITE_CHECKOUT_URL}/${entityId}`;
    const firstItem = items[0];

    useEffect(() => {
        const minimumBalanceRequiredWasEdited =
            !!itemsCheckoutMinimumRequiredBalanceFormRef.current?.wasEdited;
        const suggestedAllowanceWasEdited =
            !!itemsCheckoutSuggestedAllowanceFormRef.current?.wasEdited;

        const checkoutUrlQuery = getCheckoutUrlQuery({
            items,
            cartEnabled,
            payNow,
            email,
            minimumBalanceRequired,
            minimumBalanceRequiredWasEdited,
            suggestedAllowance,
            suggestedAllowanceWasEdited,
            refId,
            externalSubscriptionId,
        }).toString();

        setInvoiceUrl(
            `${baseUrl}/${firstItem.id}${
                checkoutUrlQuery ? `?${checkoutUrlQuery}` : ``
            }`
        );
    }, [
        baseUrl,
        firstItem.id,
        items,
        cartEnabled,
        payNow,
        email,
        minimumBalanceRequired,
        suggestedAllowance,
        refId,
        externalSubscriptionId,
    ]);

    const emailReadyOnly = forceEmail !== undefined;
    const externalSubscriptionIdReadyOnly =
        forceExternalSubscriptionId !== undefined;

    const hasTokenPricedItems = items.some(({ amount }) => amount === null);

    return (
        <>
            <Field>
                {label && <Label htmlFor="itemCheckoutUrl">{label}</Label>}

                <Copyable value={invoiceUrl}>
                    <S.CheckoutUrlTextArea
                        name="itemCheckoutUrl"
                        readOnly
                        autoGrow
                        resize={false}
                        value={invoiceUrl}
                        disabled={disabled}
                        onClick={(event) => event.currentTarget.select()}
                    />
                </Copyable>
            </Field>
            <SubSection>
                <S.AdvancedOptions
                    onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                >
                    <S.AdvancedOptionsTitle>
                        Advanced options{" "}
                        <S.CarretContainer
                            showAdvancedOptions={showAdvancedOptions}
                        >
                            <CarretDown
                                width="1rem"
                                height="1rem"
                                fill={c.popoverCloseLink}
                            />
                        </S.CarretContainer>
                    </S.AdvancedOptionsTitle>
                    <p>
                        Changing these configurations will create a unique
                        payment link that will disappear when you close this
                        page.
                    </p>
                </S.AdvancedOptions>
            </SubSection>
            {showAdvancedOptions && (
                <>
                    <SubSection
                        title="Cart display"
                        description="You can hide the cart"
                    >
                        <Field>
                            <Label htmlFor="cartEnabled">
                                <Checkbox
                                    name="cartEnabled"
                                    checked={cartEnabled}
                                    onChange={(event) =>
                                        setCartEnabled(event.target.checked)
                                    }
                                />
                                Cart enabled
                            </Label>
                        </Field>
                    </SubSection>
                    {!!externalSubscriptionId && (
                        <SubSection
                            title="Pay Now"
                            description="You can override the due date to be paid immediately"
                        >
                            <Field>
                                <Label htmlFor="payNow">
                                    <Checkbox
                                        name="payNow"
                                        checked={payNow}
                                        onChange={(event) =>
                                            setPayNow(event.target.checked)
                                        }
                                    />
                                    Pay Now
                                </Label>
                            </Field>
                        </SubSection>
                    )}
                    {!hasTokenPricedItems && (
                        <SubSection title="Cart functionality">
                            <ItemsCheckoutMinimumRequiredBalanceForm
                                items={items}
                                disabled={disabled}
                                ref={itemsCheckoutMinimumRequiredBalanceFormRef}
                                onChange={(minimumBalanceRequired: number) =>
                                    setMinimumBalanceRequired(
                                        minimumBalanceRequired
                                    )
                                }
                            />
                            <ItemsCheckoutSuggestedAllowanceForm
                                items={items}
                                minimumBalanceRequired={minimumBalanceRequired}
                                disabled={disabled}
                                ref={itemsCheckoutSuggestedAllowanceFormRef}
                                onChange={(suggestedAllowance: number) =>
                                    setSuggestedAllowance(suggestedAllowance)
                                }
                            />
                            <ItemsCheckoutMinimumAllowanceForm
                                items={items}
                                minimumBalanceRequired={minimumBalanceRequired}
                                disabled={disabled}
                                ref={itemsCheckoutMinimumAllowanceFormRef}
                                onChange={(minimumAllowance: number) =>
                                    setMinimumAllowance(minimumAllowance)
                                }
                            />
                        </SubSection>
                    )}
                    <SubSection
                        title={
                            emailReadyOnly
                                ? "Email address"
                                : "Pre-populate email address"
                        }
                        description={
                            emailReadyOnly
                                ? "Email address on checkout"
                                : "Pre-populate the email address on checkout"
                        }
                    >
                        <Field>
                            <Input
                                name="email"
                                value={email}
                                type="email"
                                readOnly={emailReadyOnly}
                                onChange={(event) =>
                                    setEmail(event.target.value.trim())
                                }
                            />
                        </Field>
                    </SubSection>
                    <SubSection title="External subscription ID">
                        <Field>
                            <Input
                                readOnly={externalSubscriptionIdReadyOnly}
                                name="refId"
                                value={externalSubscriptionId}
                                onChange={(event) =>
                                    setExternalSubscriptionId(
                                        event.target.value
                                    )
                                }
                            />
                        </Field>
                    </SubSection>
                    <SubSection
                        title="Pass in reference ID"
                        description="This value will be passed back in Loop’s
                                AgreementSignUp webhook"
                    >
                        <Field>
                            <Input
                                name="refId"
                                value={refId}
                                onChange={(event) =>
                                    setRefId(event.target.value)
                                }
                            />
                        </Field>
                    </SubSection>
                </>
            )}
        </>
    );
}

export default forwardRef(ItemCheckoutUrlField);
