import { useEffect, RefObject, useState, useCallback } from "react";

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: () => void,
    disable?: boolean
) => {
    // A click
    const [clickStartedOutside, setClickStartedOutside] =
        useState<boolean>(false);
    const [clickEndedOutside, setClickEndedOutside] = useState<boolean>(false);

    const clickedOutside = useCallback(
        (event: Event) => {
            if (disable) {
                return false;
            }

            const el = ref?.current;
            if (!el || el.contains(event?.target as Node)) {
                return false;
            }

            return true;
        },
        [ref, disable]
    );

    const listenerStart = useCallback(
        (event: Event) => {
            setClickEndedOutside(false);
            setClickStartedOutside(clickedOutside(event));
        },
        [clickedOutside]
    );

    const listenerEnd = useCallback(
        (event: Event) => {
            setClickEndedOutside(clickedOutside(event));
        },
        [clickedOutside]
    );

    useEffect(() => {
        document.addEventListener("mouseup", listenerEnd);
        document.addEventListener("touchend", listenerEnd);
        document.addEventListener("mousedown", listenerStart);
        document.addEventListener("touchstart", listenerStart);

        return () => {
            document.removeEventListener("mouseup", listenerEnd);
            document.removeEventListener("touchend", listenerEnd);
            document.removeEventListener("mousedown", listenerStart);
            document.removeEventListener("touchstart", listenerStart);
        };
    }, [ref, handler, listenerEnd, listenerStart]); // Reload only if ref or handler changes

    useEffect(() => {
        if (clickStartedOutside && clickEndedOutside) handler();
    }, [clickEndedOutside, clickStartedOutside, handler]);
};

export default useOnClickOutside;
