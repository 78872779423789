import { colorsDeprecated as c } from "global-style";
import { useCallback, useState } from "react";
import Button, { ButtonVariants } from "components/Button";
import Tooltip from "components/Tooltip";
import Download from "components/icons/Download";
import colors from "theme/colors";

interface HoverTipProps {
    children: JSX.Element;
    disabled?: boolean;
    title?: string;
}

const HoverTip = ({
    children,
    disabled = false,
    title = ``,
}: HoverTipProps) => {
    return disabled ? (
        children
    ) : (
        <Tooltip title={title}>
            <span>{children}</span>
        </Tooltip>
    );
};

// Behavior:
//  - If `disabled` prop is true, no ToolTop is shown, button appears disabled
//  - If `disabled` prop is false/undefined, ToolTop is shown, button depends on number of rows

interface DownloadCsvProps {
    fetchCsv: () => Promise<{
        url: string;
    }>;
    disabled?: boolean;
}

const DownloadCsv = ({
    fetchCsv,
    disabled = false,
    ...props
}: DownloadCsvProps) => {
    const [loading, setLoading] = useState(false);

    const handleDownloadCsv = useCallback(async () => {
        if (disabled) return;
        try {
            setLoading(true);
            const { url } = await fetchCsv();

            if (!url) {
                throw new Error("No data available to download");
            }
            // For S3 pre-signed URLs, we can directly open in a new tab
            // since the URL already handles the download
            window.open(url, "_blank");
        } catch (error) {
            console.error(error);
            throw new Error(`Failed to download CSV: ${error}`);
        } finally {
            setLoading(false);
        }
    }, [disabled, fetchCsv]);

    return (
        <HoverTip
            disabled={disabled}
            title={loading ? "Loading..." : "Download as CSV"}
        >
            <Button
                onClick={handleDownloadCsv}
                variant={ButtonVariants.NeutralOutlined}
                disabled={disabled || loading}
                {...props}
            >
                <Download
                    fill={disabled ? c.disabled : colors.primary}
                    height="1rem"
                    width="1rem"
                />
            </Button>
        </HoverTip>
    );
};

export default DownloadCsv;
