import { useEffect, ReactElement } from "react";
import { createRoot } from "react-dom/client";
import { ServerStyleSheet, StyleSheetManager } from "styled-components";

interface ShadowDomInjectorOptions {
    shadowHostSelector: string;
    targetSelector: string;
    component: ReactElement;
    position?: "replace" | "prepend" | "append" | "before" | "after";
}

const useShadowDomInjector = ({
    shadowHostSelector,
    targetSelector,
    component,
    position = "replace",
}: ShadowDomInjectorOptions) => {
    useEffect(() => {
        let root: ReturnType<typeof createRoot> | null = null;
        const sheet = new ServerStyleSheet();
        let injected = false;

        try {
            const injectComponent = () => {
                const shadowHost = document.querySelector(shadowHostSelector);
                if (!shadowHost?.shadowRoot) return false;

                const target =
                    shadowHost.shadowRoot.querySelector(targetSelector);
                if (!target) return false;

                if (target.hasAttribute("data-injected") && injected)
                    return true;

                let styleElement = shadowHost.shadowRoot.querySelector(
                    'style[data-injected="true"]'
                ) as HTMLStyleElement;

                if (!styleElement) {
                    styleElement = document.createElement("style");
                    styleElement.setAttribute("data-injected", "true");
                    shadowHost.shadowRoot.appendChild(styleElement);
                }

                target.setAttribute("data-injected", "true");

                let container: Element;
                if (position === "replace") {
                    container = target;
                } else if (position === "before" || position === "after") {
                    const parent = target.parentElement;
                    if (!parent) return false;

                    container = document.createElement("div");
                    if (position === "before") {
                        parent.insertBefore(container, target);
                    } else {
                        parent.insertBefore(container, target.nextSibling);
                    }
                } else {
                    container = document.createElement("div");
                    if (position === "prepend") {
                        target.prepend(container);
                    } else {
                        target.append(container);
                    }
                }

                root?.unmount();
                root = createRoot(container as HTMLElement);
                root.render(
                    <StyleSheetManager target={styleElement}>
                        {component}
                    </StyleSheetManager>
                );

                injected = true;
                return true;
            };

            injectComponent(); // Try immediate injection

            const shadowObserver = new MutationObserver(() => {
                if (!injected) {
                    injectComponent();
                }
            });

            // Observer for shadow root creation/recreation
            const hostObserver = new MutationObserver((mutations) => {
                for (const mutation of mutations) {
                    if (mutation.type === "childList") {
                        const shadowHost =
                            document.querySelector(shadowHostSelector);
                        if (shadowHost?.shadowRoot) {
                            shadowObserver.observe(shadowHost.shadowRoot, {
                                childList: true,
                                subtree: true,
                            });

                            if (!injected) {
                                injectComponent();
                            }
                        }
                    }
                }
            });

            // Start observing for shadow host
            hostObserver.observe(document.body, {
                childList: true,
                subtree: true,
            });

            // Initial observation if shadow root exists
            const shadowHost = document.querySelector(shadowHostSelector);
            if (shadowHost?.shadowRoot) {
                shadowObserver.observe(shadowHost.shadowRoot, {
                    childList: true,
                    subtree: true,
                });
            }

            const retryInterval = setInterval(() => {
                if (!injected) {
                    injectComponent();
                    clearInterval(retryInterval);
                }
            }, 100);

            const timeoutId = setTimeout(() => {
                clearInterval(retryInterval);
            }, 5000);

            return () => {
                hostObserver.disconnect();
                shadowObserver.disconnect();
                root?.unmount();
                sheet.seal();
                clearInterval(retryInterval);
                clearTimeout(timeoutId);
            };
        } finally {
            sheet.seal();
        }
    }, [shadowHostSelector, targetSelector, component, position]);
};

export default useShadowDomInjector;
