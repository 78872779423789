import * as S from "./style";
import { HTMLAttributes, ReactNode, useMemo } from "react";
import withStyledComponentOrProperties, {
    StyledComponentOrProperties,
} from "hoc/withStyledComponentOrProperties";
import { SpacingTrack } from "theme/spacing";

export interface DescriptionListItem {
    id?: string;
    term: ReactNode;
    definition: ReactNode;
    style?: StyledComponentOrProperties;
    show?: boolean;
}

interface DescriptionListProps extends HTMLAttributes<HTMLDListElement> {
    items: DescriptionListItem[];
    inline?: boolean;
    separator?: string;
    spacing?: SpacingTrack;
}

const Row = withStyledComponentOrProperties(S.Row);

const DescriptionList = ({
    items,
    inline = false,
    separator = ``,
    spacing,
    ...props
}: DescriptionListProps) => {
    return (
        <S.DescriptionList inline={inline} {...props}>
            {items.reduce<ReactNode[]>(
                (acc, { id, style, term, definition, show = true }, i) => {
                    if (!show) return acc;
                    return [
                        ...acc,
                        <Row key={id ?? i} style={style} spacing={spacing}>
                            <S.Term separator={separator}>{term}</S.Term>
                            <S.Definition>{definition}</S.Definition>
                        </Row>,
                    ];
                },
                []
            )}
        </S.DescriptionList>
    );
};

export default DescriptionList;
