import * as S from "./style";
import { ReactNode } from "react";

export interface SubmenuItem {
    to: string;
    label: ReactNode;
    end?: boolean;
}

interface SubmenuProps {
    data: SubmenuItem[];
    name?: string;
}

const Submenu = ({ data, name = ``, ...props }: SubmenuProps) => {
    return (
        <S.Submenu {...props} role="navigation" aria-label={name}>
            {data.map(({ to, label, end }, index) => (
                <li key={index}>
                    <S.Item to={to} end={end}>
                        {label}
                    </S.Item>
                </li>
            ))}
        </S.Submenu>
    );
};

export default Submenu;
