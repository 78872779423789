import { GlobalStyle } from "global-style";
import { useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useSearchParams,
} from "react-router-dom";
import { IframeMessages } from "checkout/types/widget";
import { NotificationQueueProvider } from "context/NotificationQueue";
import ErrorMessage from "components/ErrorMessage";
import Checkout from "checkout/routes/Checkout";
import {
    getAllStringQueryParams,
    getBooleanQueryParams,
    getNumberQueryParam,
    getStringQueryParam,
} from "utils/queryParam";
import { onlyUnique } from "utils/arrays";
import WalletSwitch from "checkout/routes/WalletSwitch";
import WalletContextProvider from "context/Wallet";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const CheckoutPortal = () => {
    return (
        <>
            <NotificationQueueProvider>
                <GlobalStyle />
                <WalletContextProvider requiresDynamicLogin={false}>
                    <Router>
                        <RouteTree />
                    </Router>
                </WalletContextProvider>
            </NotificationQueueProvider>
            <TawkMessengerReact
                propertyId="66a8d81a32dca6db2cb76cf0"
                widgetId="1i475n1k1"
            />
        </>
    );
};

const RouteTree = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const itemIds = [
        ...getAllStringQueryParams(searchParams, "item_id"),
        ...getAllStringQueryParams(searchParams, "itemId"),
    ].filter(onlyUnique);

    const queryParams: CheckoutQueryParams = {
        embed: getBooleanQueryParams(searchParams, `embed`, false),
        cartEnabled: getBooleanQueryParams(searchParams, `cartEnabled`, true),
        defaultSpendingCap: getNumberQueryParam(
            searchParams,
            "defaultSpendingCap"
        ),
        minimumBalanceRequired: getNumberQueryParam(
            searchParams,
            "minimumBalanceRequired"
        ),
        itemIds: itemIds,
        email: getStringQueryParam(searchParams, "email")?.replace(" ", "+"),
        sub: getStringQueryParam(searchParams, "sub"),
        coupon: getStringQueryParam(searchParams, "coupon"),
        trialDays: getNumberQueryParam(searchParams, "trial"),
        discountPercent: getNumberQueryParam(searchParams, "discount"),
        refId: getStringQueryParam(searchParams, "refId"),
        invoiceId: getStringQueryParam(searchParams, "invoiceId"),
        invoiceAmount: getNumberQueryParam(searchParams, "invoiceAmount"),
        location: getStringQueryParam(searchParams, "location"),
        metalType: getStringQueryParam(searchParams, "metal_type"),
        plan: getStringQueryParam(searchParams, "plan"),
        planType: getStringQueryParam(searchParams, "plan_type"),
        plugin: getStringQueryParam(searchParams, "plugin"),
        solanaType: getStringQueryParam(searchParams, "solanaType"),
        customerId: getStringQueryParam(searchParams, "customerId"),
        billDate: getNumberQueryParam(searchParams, "billDate"),
        newCustomer: getBooleanQueryParams(searchParams, "newCustomer", false),
        payNow: getBooleanQueryParams(searchParams, "payNow", false),
    };

    // Listen to checkout widget's parent for updates
    useEffect(() => {
        if (!window) return;

        const processMessage = (event: MessageEvent) => {
            if (event.data.type === IframeMessages.SetEmail) {
                setSearchParams((params) => {
                    params.set(`email`, event.data.email);
                    return params;
                });
            }

            if (event.data.type === IframeMessages.SetExternalSubscriptionId) {
                setSearchParams((params) => {
                    params.set(`sub`, event.data.externalSubscriptionId);
                    return params;
                });
            }

            if (event.data.type === IframeMessages.SetCoupon) {
                setSearchParams((params) => {
                    params.set(`coupon`, event.data.coupon);
                    return params;
                });
            }
        };

        window.addEventListener(`message`, processMessage);
        return () => window.removeEventListener(`message`, processMessage);
    }, [setSearchParams]);

    return (
        <Routes>
            <Route
                path="/:entityId/:itemId?"
                element={<Checkout queryParams={queryParams} />}
            ></Route>
            <Route path="/">
                <Route path="/" element={<WalletSwitch />} />
            </Route>
            <Route path="*">
                <Route
                    path="*"
                    element={<ErrorMessage msg="Nothing to see here" />}
                />
            </Route>
        </Routes>
    );
};

export default CheckoutPortal;
