import * as S from "./style";
import { CheckoutPanel } from "checkout/components/CartAndForm";
import ManagedDetails, {
    ManagedDetailsProps,
} from "components/Details/ManagedDetails";
import InputEmail from "checkout/components/StepTwoDetails/InputEmail";

interface DropdownContactProps
    extends Omit<ManagedDetailsProps<CheckoutPanel.CONTACT>, "children"> {}

const StepTwoDetails = (props: DropdownContactProps) => {
    return (
        <ManagedDetails {...props}>
            <S.CollectEmail>
                <InputEmail />
            </S.CollectEmail>
        </ManagedDetails>
    );
};

export default StepTwoDetails;
