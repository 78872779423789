import { TokenExchangeDetailsResponse } from "api";
import { ItemSourceType } from "company/types";
import { ItemFrequency } from "types/common";

export interface CheckoutEntityResponse {
    entityId: string;
    name: string;
    logoUrl: string | null;
    websiteUrl: string | null;
    redirectUrl: string | null;
    emailRequired: boolean;
}

export interface CheckoutNetworkResponse {
    id: number;
    hexId: string;
    name: string;
}

export interface CheckoutContractResponse {
    address: string;
    networkId: number;
    contractType: number;
}

export interface ItemPriceResponse {
    amount: string;
    tokenAddress: string;
    network: number;
}

export interface CheckoutItemResponse {
    id: string;
    name: string;
    amount: string | null;
    frequency: ItemFrequency;
    discountAmount: number | null;
    taxPercentage: number | null;
    quantity: number | null;
    type: string;
    initialOffset: number;
    priceMetadata: string | null;
    prices: ItemPriceResponse[] | null;
    sourceId: ItemSourceType;
}

export interface InvoiceDetailsResponse {
    amount: number;
    dueDate: number;
    timezone?: string;
}

export interface GetCheckoutResponse {
    entity: CheckoutEntityResponse;
    networks: CheckoutNetworkResponse[];
    contracts: CheckoutContractResponse[];
    tokens: TokenExchangeDetailsResponse[];
    items: CheckoutItemResponse[];
    validExternalSubscription: boolean | null;
    validExternalCustomer: boolean | null;
    invoiceDetails: InvoiceDetailsResponse | null;
    hasExternalEmail: boolean;
    email: string | null;
}

export interface PostWalletRequest {
    email: string;
    contractAddress: string;
    pendingMessageHash?: string;
    network: number;
}

export enum WalletEmailResponseCodes {
    SuccessfullyCreated = "successfullyCreated",
    SuccessfullyOverwritten = "successfullyOverwritten",
    EmailPending = "emailPending",
    SomethingWentWrong = "Something went wrong",
}

export interface PostWalletResponse {
    response: WalletEmailResponseCodes;
}
