import { GlobalStyle } from "global-style";
import { Navigate, RouterProvider } from "react-router-dom";
import { typedCreateBrowserRouter } from "utils/router";
import { NotificationQueueProvider } from "context/NotificationQueue";
import { LoginType, UserProvider, UserRole } from "context/User";
import AccessControl from "components/AccessControl";
import Dashboard from "components/Dashboard";
import { MenuData, MenuType } from "components/MainMenu";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import { ModalProvider } from "context/ModalProvider";
import QueryProvider from "context/QueryProvider";
import EnsProvider from "context/EnsProvider";
import WalletContextProvider from "context/Wallet";
import Contact from "components/icons/Contact";
import Question from "components/icons/Question";
import Clipboard from "components/icons/Clipboard";
import { invoiceDataLoader } from "invoice/hooks/useInvoiceTable";
import InvoicesTable from "invoice/components/InvoicesTable";
import LoginDynamic from "components/LoginDynamic";

const menus: MenuData[] = [
    {
        id: `resources`,
        heading: `Resources`,
        type: MenuType.Bottom,
        items: [
            {
                path: import.meta.env.VITE_LOOP_DOCS_HOME || ``,
                icon: (
                    <Clipboard
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Documentation"
                    />
                ),
                label: `Documentation`,
            },
            {
                path: import.meta.env.VITE_GITBOOK_FAQ || ``,
                icon: (
                    <Question
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Frequently asked questions"
                    />
                ),
                label: `FAQs`,
            },
            {
                path: `mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`,
                icon: (
                    <Contact
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Contact us"
                    />
                ),
                label: `Contact Us`,
            },
        ],
    },
];

/* RP: LC-657
    This is a temporary solution to theming our application. Similar temporary
    code exists in other files, search "LC-657" to find other instances */
const theme = `dark`;

const router = typedCreateBrowserRouter(
    [
        {
            path: "/login",
            element: <LoginDynamic />,
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    path: "*",
                    element: <Navigate to="/login" replace />,
                },
            ],
        },
        {
            path: "/",
            element: (
                <ModalProvider>
                    <Dashboard theme={theme} menus={menus} dataLoaders={[]} />
                </ModalProvider>
            ),
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    index: true,
                    handle: {
                        name: `Invoices`,
                        heading: "Invoices",
                    },
                    loader: invoiceDataLoader,
                    element: (
                        <AccessControl rolesWithAccess={[UserRole.INVOICE]}>
                            <InvoicesTable />
                        </AccessControl>
                    ),
                },
                {
                    path: "*",
                    element: <Navigate to="/" replace />,
                },
            ],
        },
    ],
    {
        future: {
            v7_normalizeFormMethod: true,
        },
    }
);

const InvoiceApp = () => {
    return (
        <>
            <QueryProvider>
                <EnsProvider>
                    <NotificationQueueProvider>
                        <GlobalStyle />
                        <WalletContextProvider requiresDynamicLogin={true}>
                            <UserProvider loginType={LoginType.WEB2}>
                                <RouterProvider
                                    router={router}
                                    fallbackElement={<Loading />}
                                />
                            </UserProvider>
                        </WalletContextProvider>
                    </NotificationQueueProvider>
                </EnsProvider>
            </QueryProvider>
        </>
    );
};

export default InvoiceApp;
