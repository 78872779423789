import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";

export const List = styled.ul``;

export const ListItem = styled.li`
    margin-bottom: 1rem;
    display: flex;
`;

export const ListItemIcon = styled.div`
    margin-top: 0.25rem;
    min-width: 1.7rem;
    display: inline-block;
`;

export const NumberContainer = styled.span`
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid ${c.onDisabled};
    border-radius: 1.5rem;
    font-size: 0.9rem;
`;

export const ListItemContent = styled.div`
    padding-left: 1rem;
    flex: 1;
    align-content: center;
`;

export const ButtonContainer = styled.div`
    margin-top: 1rem;
`;
