import * as S from "./style";
import { HTMLAttributes, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toCoin, toDollar } from "utils/financial";
import { centsToToken } from "utils/exchangeRates";
import { Spacing } from "theme/spacing";
import { useWallet } from "context/Wallet";
import { useNotificationQueue } from "context/NotificationQueue";
import { NetworkAndTokenProvider } from "context/NetworkAndToken";
import SelectNetwork from "context/NetworkAndToken/SelectNetwork";
import SelectToken from "context/NetworkAndToken/SelectToken";
import { useInvoicePay } from "pay/context/InvoicePay";
import usePostPayment from "pay/hooks/usePostPayment";
import DescriptionList from "components/DescriptionList";
import { NotificationType } from "components/Notification";
import { CardSection } from "components/Card";
import Form from "components/Form";
import Anchor from "components/Anchor";
import Button, { ButtonVariants } from "components/Button";
import Loading from "components/Loading";
import { isNullish } from "utils/numbers";

interface CheckoutFormProps extends HTMLAttributes<HTMLDivElement> {}

const CheckoutForm = ({ ...props }: CheckoutFormProps) => {
    const { walletConnected, getTokenBalance } = useWallet();
    const [availableBalance, setAvailableBalance] = useState<string | null>(
        null
    );

    const {
        entityAlias,
        entity,
        networks,
        tokens,
        network,
        token,
        invoiceId,
        invoiceAmt,
        invoiceEmail,
        tokenAmt,
        confirmed,
        setNetwork,
        setToken,
        setTokenAmt,
        setConfirmed,
    } = useInvoicePay();

    const { sendPayment, loading, error } = usePostPayment();

    const { addNotification, removeNotification } = useNotificationQueue();

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setAvailableBalance(
                token?.address && network?.hexId && walletConnected?.address
                    ? await getTokenBalance({
                          tokenAddress: token.address,
                          networkId: network.hexId,
                          walletAddress:
                              walletConnected?.proxyFor ||
                              walletConnected?.address,
                      })
                    : null
            );
        })();
    }, [
        getTokenBalance,
        token?.address,
        network?.hexId,
        walletConnected?.proxyFor,
        walletConnected?.address,
    ]);

    useEffect(() => {
        if (!invoiceAmt || !token?.exchange?.rate) {
            setTokenAmt(undefined);
            return;
        }

        setTokenAmt(
            centsToToken(invoiceAmt, token.exchange.rate, token.decimals)
        );
    }, [invoiceAmt, token?.exchange.rate, token?.decimals, setTokenAmt]);

    useEffect(() => {
        if (!error) return;

        addNotification({
            msg: (
                <>
                    Error sending payment to {entity.name}
                    <br />
                    {error}
                </>
            ),
            type: NotificationType.ERROR,
        });
    }, [error, entity.name, addNotification]);

    useEffect(() => {
        if (!confirmed?.success) return;

        navigate(`/confirmed/${entityAlias}`);
    }, [confirmed?.success, entityAlias, navigate]);

    // If at any point, these three pieces of information are not present, navigate back to start
    if (!invoiceId || !invoiceAmt || !invoiceEmail) {
        const to = `/${entityAlias}${invoiceId ? `/id/${invoiceId}` : ""}${
            invoiceAmt ? `/amount/${invoiceAmt}` : ""
        }${invoiceEmail ? `/email/${invoiceEmail}` : ""}`;
        return <Navigate to={to} />;
    }

    const handleNetworkChange = (networkId: string) => {
        const network = networks.find((n) => n.hexId === networkId);
        setNetwork(network);
    };

    const handleTokenChange = (tokenAddress: string) => {
        const token = tokens.find((t) => t.address === tokenAddress);
        setToken(token);
    };

    const handleSubmit = async () => {
        const notificationId = addNotification({
            msg: `Sending payment to ${entity.name}...`,
            type: NotificationType.WORKING,
        });

        const result = await sendPayment();
        setConfirmed(result || null);

        removeNotification(notificationId);
    };

    const paymentItems = [
        {
            term: invoiceId,
            definition: (
                <>
                    <S.InvoiceAmt value={invoiceAmt}>
                        {toDollar(invoiceAmt)}
                    </S.InvoiceAmt>
                    <S.ChangeBtn
                        variant={ButtonVariants.Anchor}
                        anchorVariantUnderlined={false}
                        onClick={() => navigate(-1)}
                    >
                        change
                    </S.ChangeBtn>
                </>
            ),
        },
    ];

    const tokenItems =
        tokenAmt && token?.symbol
            ? [
                  {
                      term: `Rate`,
                      definition: (
                          <>
                              {toDollar(invoiceAmt)} = {toCoin(tokenAmt)}{" "}
                              {token.symbol}
                          </>
                      ),
                  },
              ]
            : null;

    const hasEnoughBalance =
        !isNullish(availableBalance) &&
        Number(availableBalance) >= Number(tokenAmt ?? 0);

    return (
        <S.CheckoutLayout {...props}>
            {loading && <Loading />}
            <S.SummaryCard>
                <S.CardHeading level="h2">Pay {entity.name}</S.CardHeading>
                <DescriptionList items={paymentItems} />
            </S.SummaryCard>
            <S.PaymentCard>
                <Form onSubmit={handleSubmit}>
                    <S.CardHeading level="h3">Payment</S.CardHeading>
                    <S.WalletPanel
                        full
                        safesEnabled={false}
                        connected={!!walletConnected}
                    />
                    <CardSection>
                        <NetworkAndTokenProvider
                            tokens={tokens}
                            networks={networks}
                            onNetworkChange={handleNetworkChange}
                            onTokenChange={handleTokenChange}
                        >
                            <S.NetworkAndToken>
                                <SelectNetwork spacing={[Spacing.none]} />
                                <SelectToken spacing={[Spacing.none]} />
                            </S.NetworkAndToken>
                        </NetworkAndTokenProvider>
                        {availableBalance !== null && token?.symbol && (
                            <S.Balance>
                                Available: {toCoin(availableBalance)}{" "}
                                {token.symbol}
                            </S.Balance>
                        )}
                    </CardSection>
                    {tokenItems && <S.ExchangeRate items={tokenItems} />}
                    {walletConnected && (
                        <CardSection>
                            <Button
                                type="submit"
                                full
                                disabled={
                                    !network || !token || !hasEnoughBalance
                                }
                            >
                                {!hasEnoughBalance
                                    ? "Insufficient wallet balance"
                                    : "Pay"}
                            </Button>
                        </CardSection>
                    )}
                    <S.Terms>
                        By connecting your wallet, you agree to our{" "}
                        <Anchor href={import.meta.env.VITE_TERMS_OF_SERVICE}>
                            Terms of service
                        </Anchor>
                        .
                    </S.Terms>
                </Form>
            </S.PaymentCard>
        </S.CheckoutLayout>
    );
};

export default CheckoutForm;
