import { ReactNode, createContext, useContext, useMemo, useRef } from "react";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { reactAppBuildType } from "default-variables";

export const QUERY_STORAGE_KEY = "loop-crypto-cache";
export const HAS_INITIALLY_FETCHED_QUERIES = [];

interface QueryContextType {
    queriesFetchedOnFirstMount: string[];
    queryClient: QueryClient;
}

const QueryContext = createContext<QueryContextType | undefined>(undefined);

interface QueryProviderProps {
    children: ReactNode;
}

export const useQueryProvider = (): QueryContextType => {
    const context = useContext(QueryContext);
    if (!context) {
        throw new Error("useQueryProvider must be used within a QueryProvider");
    }
    return context;
};

const fiveMinute = 1000 * 60 * 5;
const oneHour = 1000 * 60 * 60 * 1;

export default function QueryProvider({ children }: QueryProviderProps) {
    const queriesFetchedOnFirstMount = useRef<string[]>([]);

    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: fiveMinute,
                        retry: false,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                        refetchOnReconnect: false,
                        gcTime: oneHour,
                    },
                },
            }),
        []
    );

    const persister = createSyncStoragePersister({
        storage: window.sessionStorage,
        key: `${
            import.meta.env.VITE_BUILD_TARGET
        }.${reactAppBuildType}.${QUERY_STORAGE_KEY}`,
    });

    return (
        <QueryContext.Provider
            value={{
                queriesFetchedOnFirstMount: queriesFetchedOnFirstMount.current,
                queryClient,
            }}
        >
            <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister }}
            >
                {children}
            </PersistQueryClientProvider>
        </QueryContext.Provider>
    );
}
