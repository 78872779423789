import { FunctionComponent, useCallback, useMemo } from "react";
import useCustomerTable from "company/routes/Customers/hooks/useCustomerTable";
import Section from "components/Section";
import Anchor from "components/Anchor";
import Table from "components/Table";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import EmptyTableMessage from "components/EmptyTableMessage";
import {
    ItemType,
    ItemCategoryTypeInboundKeysAsStrings,
} from "types/common-enums";
import { getValuesFromKeys } from "utils/objects";
import DownloadCsv from "components/DownloadCsv";
import * as S from "./styles";
import { getCompanyAgreementsCsv } from "api";
import { useUser } from "context/User";

interface CustomersTableProps {}

const CustomersTable: FunctionComponent<CustomersTableProps> = () => {
    const { getEntityId, getSessionToken } = useUser();

    const itemTypesString = useMemo(
        () =>
            getValuesFromKeys(
                ItemType,
                ItemCategoryTypeInboundKeysAsStrings
            ).join(","),
        []
    );

    const requestHeaders = useMemo(
        () => ({ Authorization: getSessionToken() }),
        [getSessionToken]
    );

    const queryParams = useMemo(
        () => ({ itemTypes: itemTypesString }),
        [itemTypesString]
    );

    const {
        headings,
        records,
        totalResults,
        isLoading,
        isFetching,
        isError,
        pagination,
        sort,
        refetch,
        dataUpdatedAt,
        handleSortChange,
        handlePageChange,
    } = useCustomerTable({
        initialFilters: { itemTypes: itemTypesString },
        initialSort: { column: 3, isAscending: false },
        initialPagination: { perPage: 50, page: 1 },
    });

    const fetchAgreementsCsv = useCallback(async () => {
        const result = await getCompanyAgreementsCsv(
            getEntityId(),
            queryParams,
            requestHeaders
        );
        if (!result) {
            throw new Error("Failed to get CSV data");
        }
        return result;
    }, [getEntityId, queryParams, requestHeaders]);

    if (isLoading)
        return (
            <LoadingBox
                placeholderStyle={LoadingPlaceholderStyle.Table}
                tablePlaceholderCols={headings.length}
            />
        );
    if (isError) return <FailedDataFetchingMessage />;

    return (
        <Section>
            <S.TableHeader>
                <RefreshCounter
                    refreshedAt={dataUpdatedAt}
                    counter={CounterType.Running}
                    onRefresh={refetch}
                    refreshing={isFetching}
                />
                <S.DownloadContainer>
                    <DownloadCsv fetchCsv={fetchAgreementsCsv} />
                </S.DownloadContainer>
            </S.TableHeader>
            <Table
                data={{ headings, records }}
                totalRecords={totalResults}
                pagination={pagination}
                externalPageAndSort={true}
                defaultSort={sort}
                onSort={handleSortChange}
                onPageChange={handlePageChange}
                refetching={isFetching}
                ifNoRecords={
                    <EmptyTableMessage
                        title="No customers to display yet"
                        description={
                            <>
                                Customers are wallets that have given permission
                                to the smart contract to spend. These are "from"
                                wallets in the payment request. <br />
                                Need help? Take a look at our{" "}
                                <Anchor
                                    href={
                                        import.meta.env
                                            .VITE_LOOP_DOCS_TOOLS_COMPANY_ACCOUNTS
                                    }
                                >
                                    documentation
                                </Anchor>{" "}
                                or{" "}
                                <Anchor
                                    href={`mailto:${
                                        import.meta.env.VITE_EMAIL_SUPPORT
                                    }`}
                                >
                                    contact us
                                </Anchor>
                                .
                            </>
                        }
                    />
                }
            />
        </Section>
    );
};

export default CustomersTable;
