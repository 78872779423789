import { Provider, useState, useEffect } from "react";
import { shallowEqual } from "utils/objects";
import { WalletProviderValue } from "context/Wallet";

interface WithSingletonProps {
    WalletContextSingleton: {
        getProviderValue: () => WalletProviderValue | null;
        setProviderValue: (value: WalletProviderValue) => void;
        subscribeToProvider: (
            callback: (value: WalletProviderValue) => void
        ) => () => void;
    };
}

export const withSingletonSync = (
    WrappedComponent: Provider<WalletProviderValue | undefined>
) => {
    return function WithSingletonSync({
        children,
        WalletContextSingleton,
        value,
    }: WithSingletonProps & {
        value: WalletProviderValue;
        children?: React.ReactNode;
    }) {
        const [syncedValue, setSyncedValue] =
            useState<WalletProviderValue>(value);

        // Update singleton when value changes
        useEffect(() => {
            WalletContextSingleton.setProviderValue(value);
        }, [WalletContextSingleton, value]);

        // Subscribe to singleton updates
        useEffect(() => {
            const unsubscribe = WalletContextSingleton.subscribeToProvider(
                (newValue: WalletProviderValue) => {
                    if (!shallowEqual(syncedValue, newValue)) {
                        setSyncedValue(newValue);
                    }
                }
            );

            return () => unsubscribe();
        }, [WalletContextSingleton, syncedValue]);

        return (
            <WrappedComponent value={syncedValue}>{children}</WrappedComponent>
        );
    };
};
