import { addTimePeriodToDate } from "utils/datetime";
import { SelectOption } from "components/Select";

interface DateSet {
    future: SelectOption<number>[];
    past: SelectOption<number>[];
}

export interface SortedFilter {
    uniques: SelectOption<number>[];
    furthest: SelectOption<number> | null;
}

const getPastAndFutureDateRanges = (includeAll: boolean = true) => {
    const now = new Date();
    const nowS = now.valueOf() / 1000; // in seconds

    const quarter = [
        { name: `1st`, month: 2 },
        { name: `2nd`, month: 5 },
        { name: `3rd`, month: 8 },
        { name: `4th`, month: 11 },
    ].find(({ month }) => now.getMonth() <= month);

    // `value` is the limit, so will be checked as `<` or `>` (written in reverse order)
    const dateLimits: DateSet = {
        future: [
            {
                label: `Remainder of ${now.getFullYear()}`,
                value: addTimePeriodToDate(
                    12 - now.getMonth(),
                    true,
                    nowS,
                    `months`
                ),
            },
            {
                label: `Remainder of ${quarter?.name} quarter`,
                value: addTimePeriodToDate(
                    // @ts-ignore
                    quarter.month + 1 - now.getMonth(),
                    true,
                    nowS,
                    `months`
                ),
            },
            {
                label: `Remainder of ${now.toLocaleString("en-US", {
                    month: "long",
                })}`,
                value: addTimePeriodToDate(1, true, nowS, `months`),
            },
            {
                label: `Next 12 months`,
                value: addTimePeriodToDate(12, true, nowS, `months`),
            },
            {
                label: `Next 3 months`,
                value: addTimePeriodToDate(3, true, nowS, `months`),
            },
            {
                label: `Next 30 days`,
                value: addTimePeriodToDate(30, true, nowS),
            },
            {
                label: `Next 7 days`,
                value: addTimePeriodToDate(7, true, nowS),
            },
            {
                label: `Today`,
                value: addTimePeriodToDate(1, true, nowS),
            },
        ],
        past: [
            {
                label: `All of ${now.getFullYear()}`,
                value: addTimePeriodToDate(
                    -now.getMonth(),
                    true,
                    nowS,
                    `months`
                ),
            },
            {
                label: `All of ${quarter?.name} quarter`,
                value: addTimePeriodToDate(
                    // @ts-ignore
                    quarter.month - 3 - now.getMonth() + 1,
                    true,
                    nowS,
                    `months`
                ),
            },
            {
                label: `All of ${now.toLocaleString("en-US", {
                    month: "long",
                })}`,
                value: addTimePeriodToDate(0, true, nowS, `months`),
            },
            {
                label: `Previous 12 months`,
                value: addTimePeriodToDate(-12 + 1, true, nowS, `months`),
            },
            {
                label: `Previous 3 months`,
                value: addTimePeriodToDate(-3 + 1, true, nowS, `months`),
            },
            {
                label: `Previous 30 days`,
                value: addTimePeriodToDate(-30 + 1, true, nowS),
            },
            {
                label: `Previous 7 days`,
                value: addTimePeriodToDate(-7 + 1, true, nowS),
            },
            {
                label: `Today`,
                value: addTimePeriodToDate(0, true, nowS),
            },
        ],
    };

    // Check if deadline already exists, if so skip, then reverse for printing
    const { future, past }: { [key: string]: SortedFilter } = Object.keys(
        dateLimits
    ).reduce((allTypes, optionSetName) => {
        // For each of the objects in the filter set
        const optionSet: SortedFilter = dateLimits[
            optionSetName as keyof typeof dateLimits
        ]?.reduce<SortedFilter>(
            (
                { uniques, furthest }: SortedFilter,
                filter: SelectOption<number>
            ) => {
                // Determine the furthest range from the current date, in the direction being viewed
                const furthestRange =
                    optionSetName === `future`
                        ? !furthest || filter.value > furthest.value // options in the future
                            ? filter
                            : furthest
                        : // else
                          !furthest || filter.value < furthest.value // options in the past
                          ? filter
                          : furthest;

                // Filter for unique date limits, remove option if a record with that data limit already exists
                return uniques.filter(
                    ({ value }: SelectOption) => value === filter.value
                ).length
                    ? { uniques, furthest: furthestRange }
                    : {
                          uniques: [...uniques, filter],
                          furthest: furthestRange,
                      };
            },
            { uniques: [], furthest: null }
        );

        // Order in reverse of the way they're written (roughly: shortest to longest)
        optionSet.uniques.reverse();

        return { ...allTypes, [optionSetName]: optionSet };
    }, {});

    if (includeAll) {
        future.uniques.push({
            label: `Any date`,
            value: 0,
        });
        past.uniques.push({
            label: `Any date`,
            value: 0,
        });
    }

    return { future, past };
};

export { getPastAndFutureDateRanges };
