import {
    Company,
    GetCompanyTransfersHttpRequest,
    SortableCompanyTransactionColumns,
} from "company/types";
import { useState, useEffect, useMemo } from "react";
import {
    TransactionFilters,
    TransactionType,
} from "company/routes/Transactions/types";
import { getCompanyTransfers } from "api";
import { useAuthQuery } from "hooks/useAuthQuery";
import { useUser } from "context/User";
import { useEns } from "context/EnsProvider";
import { QueryKey } from "api/types";
import { getDateInSeconds } from "utils/dates";

interface GetCompanyTransfersProps {
    id?: string;
    type?: TransactionType;
    filters?: TransactionFilters;
    sortBy?: SortableCompanyTransactionColumns | undefined;
    sortDir?: "asc" | "desc" | undefined;
    page?: number;
    limit?: number;
}

export const useGetCompanyTransfers = (props?: GetCompanyTransfersProps) => {
    const { id, type, filters, sortBy, sortDir, page, limit } = props || {};

    const { getEntityId, getSessionToken } = useUser();
    const { lookupEnsRecords } = useEns();

    const [transactions, setTransactions] = useState<Company.Transaction[]>([]);
    const [totalResults, setTotalResults] = useState<number>();

    // Convert all params to their Request format
    const queryParams: GetCompanyTransfersHttpRequest = useMemo(() => {
        const params: Record<string, string> = {};

        if (page !== undefined) params.page = page.toString();
        if (limit !== undefined) params.limit = limit.toString();
        if (sortBy) params.sortBy = sortBy;
        if (sortDir) params.sortDir = sortDir;
        if (id) params.id = id;
        if (type) params.type = type;

        if (filters?.date !== undefined)
            params.date = getDateInSeconds(filters.date).toString();
        if (filters?.search) params.search = filters.search;
        if (filters?.amount) params.amount = filters.amount;
        if (filters?.entities) params.entities = filters.entities;
        if (filters?.networks) params.networks = filters.networks;

        return params;
    }, [
        page,
        limit,
        sortBy,
        sortDir,
        id,
        type,
        filters?.date,
        filters?.search,
        filters?.amount,
        filters?.entities,
        filters?.networks,
    ]);

    const queryKey = [QueryKey.CompanyTransfers, queryParams];
    const requestHeaders = {
        Authorization: getSessionToken(),
    };

    const {
        data: getCompanyTransfersData,
        isError: getCompanyTransfersIsError,
        isLoading: getCompanyTransfersIsLoading,
        isSuccess: getCompanyTransfersIsSuccess,
        dataUpdatedAt: getCompanyTransfersDataUpdatedAt,
        refetch: getCompanyTransfersRefetch,
        isFetching: getCompanyTransfersIsFetching,
    } = useAuthQuery({
        queryKey,
        queryFn: () => {
            return getCompanyTransfers(
                getEntityId(),
                queryParams,
                requestHeaders
            );
        },
    });

    useEffect(() => {
        setTransactions(getCompanyTransfersData?.transactions || []);
        setTotalResults(getCompanyTransfersData?.totalResults || 0);

        // Find and format wallet addresses from Contract[] objects
        const transfersAddresses =
            getCompanyTransfersData?.transactions.reduce<string[]>(
                (addresses, { receiver }) => {
                    addresses.push(receiver.wallet);
                    return addresses;
                },
                []
            ) || [];
        lookupEnsRecords(transfersAddresses);
    }, [getCompanyTransfersData, lookupEnsRecords]);

    return {
        totalResults,
        transactions,
        getCompanyTransfersIsError,
        getCompanyTransfersIsLoading,
        getCompanyTransfersIsSuccess,
        getCompanyTransfersDataUpdatedAt,
        getCompanyTransfersRefetch,
        getCompanyTransfersIsFetching,
    };
};
