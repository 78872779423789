import { useCallback, useMemo, useState } from "react";
import {
    AgreementCanceledStatuses,
    ItemCategoryType,
    ItemCategoryTypeInbound,
    TransferStatus,
} from "types/common-enums";
import { formatUnits } from "ethers";
import { toCoin, toDollar } from "utils/financial";
import { getDateInSeconds } from "utils/dates";
import { toNetworkHex } from "utils/addresses";
import { firstToUpper } from "utils/strings";
import { isStableCoin } from "utils/tokens";
import { isTransactionUpcomingOrDue } from "utils/transactions";
import { patchCustomerCancelAgreements } from "api";
import { useSession } from "context/Session";
import useGetCustomerData from "customer/hooks/useGetCustomerData";
import { useWalletConnected } from "context/Wallet/WalletConnected";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import {
    CustomerAuthorization,
    CustomerAuthorizationType,
} from "customer/types";
import {
    getNextTransactionStatusMessage,
    getSubscriptionAmount,
    getSubscriptionFrequency,
    getSubscriptionStatusBadge,
    getUpcomingPaymentStatusBadge,
} from "customer/utils/subscriptions";

const useAuthorizations = () => {
    const {
        data,
        isLoading: isCustomerDataLoading,
        isError,
        getCustomerDataRefetch,
    } = useGetCustomerData();
    const { getSessionToken } = useSession();
    const { walletConnected } = useWalletConnected();
    const [isCancelAuthorizationsLoading, setIsCancelAuthorizationsLoading] =
        useState(false);

    const authorizations: CustomerAuthorization[] = useMemo(() => {
        if (
            !data?.agreements ||
            !data?.entities ||
            !data?.contracts ||
            !data?.items ||
            !data?.networks ||
            !data?.tokens ||
            !data?.transactions
        )
            return [];

        return data.agreements
            .sort((a, b) => b.createdAt - a.createdAt)
            .reduce<CustomerAuthorization[]>(
                (formattedAuthorizations, agreement) => {
                    const entity = data.entities.find(
                        ({ entityId }) => entityId === agreement.entity
                    );

                    const contract = data.contracts.find(
                        ({ owner, networkId }) =>
                            owner === agreement.entity &&
                            networkId === agreement.networkId
                    );

                    const items = data.items.filter(({ id }) =>
                        agreement.items.includes(id)
                    );

                    const network = data.networks.find(
                        ({ id }) => id === agreement.networkId
                    );

                    const token = data?.tokens.find(
                        ({ address, networkId }) =>
                            address === agreement.token &&
                            networkId === agreement.networkId
                    );

                    if (!entity || !contract || !items || !network || !token)
                        return formattedAuthorizations;

                    const hasInvalidItems = items.some(
                        ({ type }) =>
                            !Object.values(ItemCategoryTypeInbound).includes(
                                type as ItemCategoryType
                            )
                    );
                    if (hasInvalidItems) return formattedAuthorizations;

                    // If any item is subscription, it's a subscription
                    const type = items.some(
                        ({ type }) => type === ItemCategoryType.Subscription
                    )
                        ? CustomerAuthorizationType.Subscription
                        : items.some(
                                ({ type }) => type === ItemCategoryType.One_Time
                            )
                          ? CustomerAuthorizationType.Invoice
                          : CustomerAuthorizationType.Other;

                    // If it's Canceled, otherwise it's Active
                    const active = !AgreementCanceledStatuses.includes(
                        agreement.status
                    );

                    // The subscription frequency
                    const frequency = getSubscriptionFrequency(items);

                    // The next scheduled payment
                    const nextScheduledTx = data.transactions
                        .sort((a, b) => b.billDate - a.billDate)
                        .find(
                            ({ agreementId, status }) =>
                                agreementId === agreement.id &&
                                isTransactionUpcomingOrDue(status)
                        );

                    const statusOfNextPayment = getNextTransactionStatusMessage(
                        agreement,
                        nextScheduledTx,
                        token
                    );

                    const subscriptionStatusBadge = getSubscriptionStatusBadge(
                        agreement,
                        nextScheduledTx
                    );

                    const upcomingPaymentStatusBadge =
                        getUpcomingPaymentStatusBadge(nextScheduledTx);

                    const isPastDue =
                        !!nextScheduledTx &&
                        nextScheduledTx.billDate < getDateInSeconds();

                    const amount = getSubscriptionAmount(items);

                    const transactions = data.transactions.filter(
                        ({ agreementId, status }) =>
                            agreement.id === agreementId &&
                            status === TransferStatus.Succeeded
                    );

                    if (nextScheduledTx) {
                        transactions.unshift(nextScheduledTx);
                    }

                    return [
                        ...formattedAuthorizations,
                        {
                            id: agreement.id,
                            type,
                            contract: contract.address,
                            entityId: entity.entityId,
                            entityName: entity.name,
                            itemName: items.map(({ name }) => name).join(`, `),
                            amount,
                            frequency,
                            nextPayment: nextScheduledTx,
                            statusOfNextPayment,
                            isPastDue,
                            active,
                            statusBadge: subscriptionStatusBadge,
                            wallet: agreement.sender.wallet as HexAddress,
                            email: agreement.sender.email,
                            token,
                            tokenSymbol: token.symbol,
                            tokenAddress: token.address,
                            networkHex: toNetworkHex(agreement.networkId),
                            networkName: firstToUpper(network.name),
                            transactions: transactions.reduce<
                                CustomerAuthorization["transactions"]
                            >(
                                (
                                    txs,
                                    {
                                        id,
                                        billDate,
                                        payment,
                                        status,
                                        amount,
                                        usd,
                                        tokenAddress,
                                        networkId,
                                        items,
                                        invoiceId,
                                    }
                                ) => {
                                    // Payment token
                                    const txToken = data.tokens.find(
                                        ({ address: a, networkId: n }) =>
                                            a === tokenAddress &&
                                            n === networkId
                                    );

                                    if (!txToken) return txs;

                                    const amtUsd =
                                        amount && usd ? toDollar(amount) : ``;
                                    const amtToken =
                                        payment?.paidAmount &&
                                        (!isStableCoin(txToken) || !usd)
                                            ? toCoin(
                                                  formatUnits(
                                                      payment.paidAmount,
                                                      txToken.decimals
                                                  )
                                              ) + ` ${txToken.symbol}`
                                            : ``;

                                    return [
                                        ...txs,
                                        {
                                            id,
                                            billDate,
                                            datePaid:
                                                payment?.processedAt || null,
                                            amount:
                                                amtUsd && amtToken
                                                    ? `${amtUsd} (${amtToken})`
                                                    : amtUsd || amtToken,
                                            status,
                                            txHash:
                                                status ===
                                                TransferStatus.Succeeded ? (
                                                    payment?.transactionHash ? (
                                                        <DynamicAddressDisplay
                                                            shorten
                                                            address={
                                                                payment.transactionHash
                                                            }
                                                            networkId={toNetworkHex(
                                                                agreement.networkId
                                                            )}
                                                            inheritColor={false}
                                                        >
                                                            Paid
                                                        </DynamicAddressDisplay>
                                                    ) : (
                                                        <span>Paid</span>
                                                    )
                                                ) : upcomingPaymentStatusBadge ? (
                                                    upcomingPaymentStatusBadge
                                                ) : (
                                                    ``
                                                ),
                                            itemName: items.join(`, `),
                                            invoiceId: invoiceId ?? ``,
                                        },
                                    ];
                                },
                                []
                            ),
                        },
                    ];
                },
                []
            );
    }, [
        data?.agreements,
        data?.contracts,
        data?.entities,
        data?.items,
        data?.networks,
        data?.tokens,
        data?.transactions,
    ]);

    const getAuthorization = useCallback(
        (authorizationId: string) => {
            return authorizations.find(({ id }) => authorizationId === id);
        },
        [authorizations]
    );

    const getAuthorizations = useCallback(
        (options?: { type?: CustomerAuthorizationType; entityId?: string }) => {
            return authorizations.filter(
                ({ type, entityId }) =>
                    (!options?.type || type === options.type) &&
                    (!options?.entityId || entityId === options.entityId)
            );
        },
        [authorizations]
    );

    const cancelAuthorization = async (
        authorization: CustomerAuthorization
    ) => {
        // [ ] Need an error message here? or throw?
        if (authorization.type !== CustomerAuthorizationType.Subscription)
            return;

        setIsCancelAuthorizationsLoading(true);

        // [ ] Do we have a query hook that can setup and manage loading/error, but not call immediately - then only call when requested?
        return await patchCustomerCancelAgreements(
            walletConnected.proxyFor || walletConnected.address,
            authorization.id,
            {
                "Content-Type": "application/json",
                Authorization: getSessionToken(),
                address: walletConnected.address,
            }
        ).finally(() => setIsCancelAuthorizationsLoading(false));
    };

    return {
        getAuthorizations,
        getAuthorization,
        cancelAuthorization,
        getCustomerDataRefetch,
        isLoading: isCustomerDataLoading || isCancelAuthorizationsLoading,
        isError,
    };
};

export default useAuthorizations;
