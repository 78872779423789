import styled from "styled-components";
import { sizes as s } from "global-style";
import spacing from "theme/spacing";
import Input from "components/Input";
import Field from "components/Field";
import Tooltip from "components/Tooltip";

export const Filters = styled.section<{ $numberOfAdditionalFilters: number }>`
    margin-block: ${spacing.none} ${spacing.md};
    align-items: start;
    display: grid;
    @media screen and (min-width: ${s.md}) {
        grid-template-columns:
            minmax(12rem, 2fr)
            ${({ $numberOfAdditionalFilters }) =>
                `repeat(${$numberOfAdditionalFilters}, minmax(auto, 1fr))`};
    }
    gap: ${spacing.sm};
`;

export const FieldWithInfo = styled(Field)`
    position: relative;
`;

export const SearchInput = styled(Input)`
    padding-inline-end: ${spacing.lg};
`;

export const SearchTips = styled(Tooltip)`
    align-self: flex-start;
    position: absolute;
    top: 0;
    right: ${spacing.xxs};
    bottom: 0;
    margin: auto;
`;

export const QIcon = styled.span``;
