import { useEffect } from "react";
import { UNAUTHORIZED_MESSAGE } from "api/rest";
import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useCustomQuery } from "hooks/useCustomQuery";
import { useUser } from "context/User";

export function useAuthQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
>(
    options: UseQueryOptions<TQueryFnData, TError, TData> & {
        enabled?: boolean;
    }
): UseQueryResult<TData, TError> {
    const { getEntityId, getSessionToken, logout } = useUser();

    const entityId = getEntityId();
    const sessionToken = getSessionToken();
    const queryKey = [...options.queryKey, entityId];

    // User is logged in with a valid entity, can do protected auth
    const isEnabled =
        entityId.length > 0 &&
        sessionToken.length > 0 &&
        (options.enabled ?? true);

    const queryInfo = useCustomQuery({
        ...options,
        queryKey,
        enabled: isEnabled,
    });

    const errorMessage = queryInfo.error
        ? (queryInfo.error as { message?: string })?.message
        : undefined;

    useEffect(() => {
        if (errorMessage === UNAUTHORIZED_MESSAGE) {
            logout();
        }
    }, [errorMessage, logout]);

    return queryInfo;
}
