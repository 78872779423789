import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem, ExchangableToken } from "types/common";
import { calculateMinimumBalance, DiscountInfo } from "utils/checkout";
import { CheckoutItem } from "checkout/types";

export const useMinimumBalance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[] | CheckoutItem[],
    minimumBalanceRequired: number | undefined,
    coupons: CouponResponse[],
    selectedToken?: ExchangableToken
) => {
    const [minimumBalance, setMinimumBalance] = useState<number>(0);

    useEffect(() => {
        const discountInfo: DiscountInfo = {
            discountPercentage: coupons[0]?.discountPercentage ?? null,
            discountAmount: coupons[0]?.discountAmount ?? null,
            singleUse: coupons[0]?.singleUse ?? false,
        };

        const baseMinimumBalance = calculateMinimumBalance(
            invoiceAmount,
            items,
            minimumBalanceRequired,
            discountInfo
        );

        setMinimumBalance(baseMinimumBalance);
    }, [invoiceAmount, items, minimumBalanceRequired, coupons, selectedToken]);

    return minimumBalance;
};
