import { formatUnits } from "ethers";

// This function pairs well with the `coins` regex to broaden the scope of what is considered a number
export const toNumber = (value: string | number): number => {
    if (typeof value === `string` && value.trim() === `.`) return 0; // Only a decimal point (for onInput computation)
    if (typeof value === `string`) value = value.replace(/,/g, ``); // Remove commas
    return Number(value);
};

export const ceilNumber = (num: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.ceil(num * factor) / factor;
};

export const floorNumber = (num: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.floor(num * factor) / factor;
};

export const times100 = (input: number | string, decimals: number = 0) => {
    return (
        100 *
        parseFloat(
            typeof input === "string"
                ? input.replace(/[,]/g, "")
                : input.toString()
        )
    ).toFixed(decimals);
};

export const biToStr = (bn: bigint, decimal: number = 6): string =>
    formatUnits(bn, decimal);

export const biToNum = (bn: bigint, decimal: number = 6): number =>
    toNumber(formatUnits(bn, decimal));

// Note: if taking a string, it does not check string as a number, just that it's not empty
export const isNullish = (
    value: number | bigint | string | null | undefined
): value is null | undefined | "" => value == null || value === "";

export const getAmountInCentsFromStringNumber = (search: string) => {
    if (!/^\d*\.?\d*$/.test(search)) return null;
    const num = parseFloat(search);
    if (isNaN(num)) return null;
    return Math.round(num * 100).toString();
};
