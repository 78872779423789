import * as S from "./style";
import { useEffect, useRef, useState, Fragment, HTMLAttributes } from "react";
import { shortenAddress } from "utils/addresses";
import Button, { ButtonVariants } from "components/Button";
import Icon from "components/Icon";
import safeLogo from "assets/img/logos/safe-wallet.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useWallet } from "context/Wallet";
import useSafesConnected from "hooks/useSafesConnected";
import Badge from "components/Badge";
import Anchor from "components/Anchor";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Tooltip from "components/Tooltip";
import { NetworkObject } from "default-variables";
import Title from "components/Title";
import { Density } from "types/common-enums";

interface WalletConnectProps extends HTMLAttributes<HTMLDivElement> {
    networks?: NetworkObject[];
    safesEnabled?: boolean;
}

const WalletConnect = ({
    networks,
    safesEnabled = true,
    ...props
}: WalletConnectProps) => {
    const {
        handleConnectWallet,
        isWalletConnecting,
        walletConnected,
        isNetworkSetting,
        networkConnected,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
        isWalletModalOpen,
    } = useWallet();

    const { safesConnected } = useSafesConnected();
    const isSafeAvailable = safesEnabled && !!safesConnected;

    const [walletPanelOpen, setWalletPanelOpen] = useState(false);
    const walletSelectRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(
        walletSelectRef,
        () => setWalletPanelOpen(false),
        isWalletModalOpen
    );

    const walletPanel = useRef<HTMLDivElement>(null);

    const handleClickWallet = () => {
        setWalletPanelOpen(!walletPanelOpen);
    };

    const handleChangeAccount = (accountId: string) => {
        setPrimaryWallet(accountId);
        setWalletPanelOpen(false);
    };

    const handleClickSafeWallet = (safeWallet: any) => {
        setProxyWallet(
            walletConnected?.proxyFor?.toLowerCase() ===
                safeWallet?.toLowerCase()
                ? null
                : safeWallet
        );
    };

    useEffect(() => {
        if (!walletPanelOpen || !walletPanel.current) return;
        walletPanel.current.focus();
    });

    return (
        <S.WalletConnect {...props}>
            {walletConnected ? (
                isNetworkSetting ? (
                    <S.SettingChain>
                        <Title level="h2" size="h3">
                            Switching chains
                        </Title>
                        <span>Check your wallet...</span>
                    </S.SettingChain>
                ) : (
                    <>
                        <S.SelectNetwork networks={networks} />
                        <S.WalletSelect ref={walletSelectRef}>
                            <S.CurrentWallet
                                full
                                variant={ButtonVariants.NeutralOutlined}
                                title={
                                    walletConnected.proxyFor ||
                                    walletConnected.address
                                }
                                onClick={handleClickWallet}
                            >
                                {walletConnected.proxyFor ? (
                                    <S.WalletLogo
                                        src={safeLogo}
                                        alt="Safe (wallet)"
                                    />
                                ) : walletConnected.icon ? (
                                    <S.WalletLogo
                                        src={walletConnected.icon}
                                        alt={walletConnected.label}
                                    />
                                ) : (
                                    <></>
                                )}
                                <S.AddrSelected>
                                    {walletConnected.proxyFor
                                        ? shortenAddress(
                                              walletConnected.proxyFor
                                          )
                                        : walletConnected.ens ||
                                          shortenAddress(
                                              walletConnected.address
                                          )}
                                </S.AddrSelected>
                            </S.CurrentWallet>

                            {walletPanelOpen && (
                                <S.WalletPanel
                                    forwardedAs="article"
                                    tabIndex="0"
                                >
                                    <h1>Connected wallets</h1>
                                    <S.Wallets>
                                        {walletsAvailable.map((wallet) => (
                                            <S.Wallet key={wallet.label}>
                                                <S.WalletHeading>
                                                    {wallet.icon && (
                                                        <Icon
                                                            src={wallet.icon}
                                                            alt={`${wallet.label} logo`}
                                                            size="1.7em"
                                                            tighten
                                                        />
                                                    )}
                                                    <S.WalletName>
                                                        {wallet.label}
                                                    </S.WalletName>
                                                </S.WalletHeading>
                                                <>
                                                    {wallet.addresses.map(
                                                        (account) => (
                                                            <Fragment
                                                                key={account.id}
                                                            >
                                                                <S.WalletAccount>
                                                                    <Tooltip
                                                                        title="Switch to this wallet"
                                                                        placement="left"
                                                                        disabled={
                                                                            account.address ===
                                                                            walletConnected.address
                                                                        }
                                                                    >
                                                                        <S.Address
                                                                            disabled={
                                                                                account.address ===
                                                                                walletConnected.address
                                                                            }
                                                                            noStyles
                                                                            onClick={() => {
                                                                                if (
                                                                                    account.address ===
                                                                                    walletConnected.address
                                                                                )
                                                                                    return;
                                                                                handleChangeAccount(
                                                                                    account.id
                                                                                );
                                                                            }}
                                                                            tabIndex={
                                                                                account.address ===
                                                                                walletConnected.address
                                                                                    ? undefined
                                                                                    : 0
                                                                            }
                                                                        >
                                                                            {
                                                                                account.address
                                                                            }
                                                                        </S.Address>
                                                                    </Tooltip>
                                                                    {account.address ===
                                                                        walletConnected.address && (
                                                                        <>
                                                                            {` `}
                                                                            <S.ActiveAcct />
                                                                        </>
                                                                    )}
                                                                </S.WalletAccount>
                                                                {account.address ===
                                                                    walletConnected.address &&
                                                                    isSafeAvailable && (
                                                                        <S.SafeWallets>
                                                                            <h3>
                                                                                Available{" "}
                                                                                <Anchor
                                                                                    href="https://safe.global/"
                                                                                    underlined={
                                                                                        false
                                                                                    }
                                                                                    inheritColor={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    <S.SafeLogo
                                                                                        src={
                                                                                            safeLogo
                                                                                        }
                                                                                        alt="Safe (wallet)"
                                                                                    />
                                                                                    Safe
                                                                                </Anchor>{" "}
                                                                                wallets
                                                                                to
                                                                                proxy
                                                                            </h3>
                                                                            <ul>
                                                                                {safesConnected.map(
                                                                                    ({
                                                                                        address:
                                                                                            safeAddress,
                                                                                    }) => (
                                                                                        <S.Address
                                                                                            as={
                                                                                                "li"
                                                                                            }
                                                                                            key={
                                                                                                safeAddress
                                                                                            }
                                                                                            data-proxying={
                                                                                                walletConnected.proxyFor?.toLowerCase() ===
                                                                                                safeAddress.toLowerCase()
                                                                                            }
                                                                                            onClick={() =>
                                                                                                handleClickSafeWallet(
                                                                                                    safeAddress
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <DynamicAddressDisplay
                                                                                                shorten
                                                                                                address={
                                                                                                    safeAddress
                                                                                                }
                                                                                                networkId={
                                                                                                    networkConnected?.networkId
                                                                                                }
                                                                                                anchor={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                            {walletConnected.proxyFor?.toLowerCase() ===
                                                                                                safeAddress.toLowerCase() && (
                                                                                                <>
                                                                                                    {` `}
                                                                                                    <Badge
                                                                                                        variant="purple"
                                                                                                        density={
                                                                                                            Density.Compact
                                                                                                        }
                                                                                                    >
                                                                                                        proxied
                                                                                                    </Badge>
                                                                                                </>
                                                                                            )}
                                                                                        </S.Address>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </S.SafeWallets>
                                                                    )}
                                                            </Fragment>
                                                        )
                                                    )}
                                                </>
                                            </S.Wallet>
                                        ))}
                                    </S.Wallets>

                                    <footer>
                                        <Button
                                            title="Connect Wallet"
                                            full
                                            onClick={() => {
                                                handleConnectWallet();
                                                setWalletPanelOpen(false);
                                            }}
                                            loading={isWalletConnecting}
                                        >
                                            {isWalletConnecting
                                                ? "Connecting..."
                                                : "Manage wallets"}
                                        </Button>
                                    </footer>
                                </S.WalletPanel>
                            )}
                        </S.WalletSelect>
                    </>
                )
            ) : (
                <S.ConnectButton
                    title="Connect Wallet"
                    onClick={handleConnectWallet}
                    loading={isWalletConnecting}
                >
                    {isWalletConnecting ? "Connecting..." : "Connect wallet"}
                </S.ConnectButton>
            )}
        </S.WalletConnect>
    );
};

export default WalletConnect;
