import { useCallback } from "react";
import { JsonRpcProvider } from "ethers";
import { Connection } from "@solana/web3.js";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletManagement";
import { AvailableNetwork } from "default-variables";

const useChainProvider = (
    walletConnected: PrimaryWalletAccount | null,
    networkConnected: AvailableNetwork | null
) => {
    const { getNetworkById } = useAvailableNetworks();

    const getProviderForAnyWalletAndNetwork = useCallback(
        ({
            walletAddress,
            networkId,
        }: {
            walletAddress?: string;
            networkId?: string;
        }) => {
            // If no wallet specified, use the connected wallet
            if (!walletAddress) {
                if (!walletConnected) {
                    throw new Error(
                        `Wallet is not ready for contract interaction`
                    );
                }

                walletAddress =
                    walletConnected.proxyFor || walletConnected.address;
            }

            // If no network specified, use the connected network
            if (!networkId) {
                if (!networkConnected || !walletConnected) {
                    throw new Error(
                        `Wallet is not connected to a contract network`
                    );
                }
                // Use the current network
                networkId = networkConnected.networkId;
            }

            // Ensure the network data exists and is available
            const network = getNetworkById(networkId);
            if (!network) {
                throw new Error(`Network ${networkId} is not available`);
            }

            let chainAndProvider: Pick<
                PrimaryWalletAccount,
                "chain" | "provider"
            >;

            // If the network searched is the same as the connected wallet is on, use the existing provider
            if (
                walletConnected &&
                networkConnected &&
                networkId === networkConnected.networkId
            )
                chainAndProvider = {
                    chain: walletConnected.chain,
                    provider: walletConnected.provider,
                };
            else if (network.chain === "SOL")
                chainAndProvider = {
                    chain: "SOL",
                    provider: new Connection(network.rpcUrl, "confirmed"),
                };
            else {
                chainAndProvider = {
                    chain: "EVM",
                    provider: new JsonRpcProvider(network.rpcUrl),
                };
            }

            return { chainAndProvider, wallet: walletAddress, network };
        },
        [walletConnected, networkConnected, getNetworkById]
    );

    return { getProviderForAnyWalletAndNetwork };
};

export { useChainProvider };
